import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  teams: [],
  currentFocusedTeam: {},
  isAuthenticated: false,
  isSupervisor: null,
  status: "idle",
  errors: [],
  firstLoadStatus: "idle",
  phoneNumber: "",
}

// Comparator for sorting shops into alpha order
const compShops = (a, b) => {
  if (a.title === b.title) {
    return a.code > b.code ? 1 : a.code < b.code ? -1 : 0
  }

  return a.title > b.title ? 1 : -1
}

export const login = createAsyncThunk(
  "user/login",
  async (payload, thunkAPI) => {
    try {
      const response = await axios
        // .post("/login", payload)
        .post(`/token/`, payload, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })

      console.log(response)
      localStorage.setItem("Token", response.data.access_token)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.data.access_token

      thunkAPI.dispatch(getProfile())
      // thunkAPI.dispatch(getUtas())
      thunkAPI.dispatch(setFirstLoadStatus("loading"))
      return JSON.parse(JSON.stringify(response))
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.status)
    }
  }
)

export const register = createAsyncThunk(
  "user/register",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("/create-profile/", payload)
      localStorage.setItem("Token", response.data.access_token)
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + response.data.access_token
      thunkAPI.dispatch(getProfile())
      thunkAPI.dispatch(setFirstLoadStatus("loading"))
      return JSON.parse(JSON.stringify(response))
    } catch (err) {
      return thunkAPI.rejectWithValue(err.response.data)
    }
  }
)

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (payload, thunkAPI) => {
    const response = await axios.get("/get-profile/")
    setTimeout(
      function () {
        thunkAPI.dispatch(setFirstLoadStatus("success"))
      },
      [10]
    )
    // Serialize response data before returning
    return JSON.parse(JSON.stringify(response))
  }
)

export const getUtas = createAsyncThunk(
  "user/getUtas",
  async (payload, thunkAPI) => {
    const response = await axios.get("/tasks/utas/")
    return JSON.parse(JSON.stringify(response))
  }
)

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearErrors: (state) => {
      return { ...state, errors: [], status: "idle" }
    },
    logout: () => {
      localStorage.removeItem("Token")
      localStorage.removeItem("Team")
      delete axios.defaults.headers.common["Authorization"]
      return { ...initialState }
    },
    setAuthenticated: (state) => {
      return { ...state, isAuthenticated: true }
    },
    setErrors: (state, { payload }) => {
      return { ...state, status: "failed", errors: payload }
    },
    setFirstLoadStatus: (state, { payload }) => {
      return { ...state, firstLoadStatus: payload }
    },
    setTeam: (state, { payload }) => {
      return { ...state, currentFocusedTeam: payload }
    },
    setTeamID: (state, { payload }) => {
      const team = state.teams.find((t) => t.id === payload)
      return { ...state, currentFocusedTeam: team || null }
    },
    setTeamOrEmpty: (state, { payload }) => {
      state.teams.forEach((t) => {
        if (t.code !== payload.code)
          return { ...state, currentFocusedTeam: t.code }
      })
      return { ...state, currentFocusedTeam: "" }
    },
  },
  extraReducers: {
    [login.pending]: (state) => {
      state.status = "loading"
      state.errors = []
    },
    [login.rejected]: (state, err) => {
      if (err.payload === 400) {
        state.errors = ["Invalid credentials, please try again"]
      } else {
        state.errors = [
          "An unexpected error has occured, please reload the page and try again",
        ]
      }
      state.status = "failed"
    },
    [register.pending]: (state) => {
      state.status = "loading"
      state.errors = []
    },
    [register.rejected]: (state, { payload }) => {
      if (payload.email && payload.email[0]) {
        state.errors = [payload.email[0]]
      } else if (payload instanceof String) {
        state.errors = [payload]
      } else {
        state.errors = ["An unexpected error has occured, please try again"]
      }

      state.status = "failed"
    },
    [getProfile.pending]: (state) => {
      return { ...state, status: "loading" }
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      // If no current currentFocusedTeam, set first team in teams array as such
      let res = {
        ...state,
        ...payload.data,
        status: "success",
        isAuthenticated: true,
      }
      if (payload.data.orgs.length > 0) {
        res.teams = payload.data.orgs

        if (!state.currentFocusedTeam || !state.currentFocusedTeam.id) {
          res.currentFocusedTeam = payload.data.orgs[0]
        } else {
          const new_org = payload.data.orgs.find(
            (t) => t.id === state.currentFocusedTeam.id
          )

          if (new_org) {
            res.currentFocusedTeam = new_org
          }
        }
      }
      return res
    },
    [getProfile.rejected]: (state, response) => {
      console.log(response.error)
      localStorage.removeItem("Token")
      localStorage.removeItem("Team")
      delete axios.defaults.headers.common["Authorization"]
      return { ...initialState }
    },
    [getUtas.fulfilled]: (state, { payload }) => {
      return { ...state, utas: payload.data }
    },
    [getUtas.rejected]: (state, response) => {
      console.log(response.error)
      state.status = "failed"
      state.errors = ["An unexpected error has occured, please try again"]
    },
  },
})

export const {
  clearErrors,
  logout,
  setAuthenticated,
  setErrors,
  setFirstLoadStatus,
  setTeam,
  setTeamOrEmpty,
  setTeamID,
} = userSlice.actions

export default userSlice.reducer

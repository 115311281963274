import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"

import { useSelector, useDispatch } from "react-redux"
import { getProfile, logout } from "../../redux/userSlice"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import PhoneInput from "react-phone-number-input"

import axios from "axios"

import ReactTooltip from "react-tooltip"
const FormField = ({ title, value, type, setter }) => (
  <>
    <p>{title}</p>
    <div className="input-group mb-3">
      <input
        style={{ color: "rgba(100,100,100,0.8)" }}
        type={type}
        className="textinput textInput form-control"
        value={value}
        onChange={(v) => {
          setter(v.target.value)
        }}
      />
    </div>
  </>
)

const SupervisorProfile = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [editMode, setEditMode] = useState(false)

  // State for edit mode

  const [emailEdit, setEmailEdit] = useState(user.username)
  const [firstNameEdit, setFirstNameEdit] = useState(user.first_name)
  const [lastNameEdit, setLastNameEdit] = useState(user.last_name)

  const handleSave = () => {
    const body = {
      email: emailEdit,
      first_name: firstNameEdit,
      last_name: lastNameEdit,
    }

    axios
      .patch("/update-user/", body)
      .then(() => {
        setEditMode(false)
        dispatch(getProfile())
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  if (user.status === "loading") {
    return <LoadingDashboard />
  }

  return (
    <div className="container-fluid">
      <div>
        <button className="default-button" onClick={() => setEditMode(true)}>
          Edit Settings
        </button>
        <Link className="default-button" to="/change-password">
          Change Password
        </Link>
        <button
          onClick={() => {
            dispatch(logout())
          }}
          className="default-button default-button-gray"
          style={{ float: "right" }}
        >
          Logout
        </button>
      </div>
      <hr />

      <br />
      <div className="card shadow">
        <div className="card-body">
          {editMode ? (
            <div>
              <div>
                <h5>Account Info</h5>
                <button className="default-button" onClick={handleSave}>
                  Save
                </button>
                <button
                  className="default-button default-button-gray"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </button>
              </div>
              <FormField
                title="First Name"
                type="text"
                value={firstNameEdit}
                setter={setFirstNameEdit}
              />
              <FormField
                title="Last Name"
                type="text"
                value={lastNameEdit}
                setter={setLastNameEdit}
              />
              <FormField
                title="Email"
                type="text"
                value={emailEdit}
                setter={setEmailEdit}
              />
            </div>
          ) : (
            <div>
              <h5>Account Info</h5>
              <p style={{ margin: 0 }}>
                <b>Name: </b>
                {user.first_name} {user.last_name}
              </p>
              <p style={{ margin: 0 }}>
                <b>Username / Email: </b>
                {user.username}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SupervisorProfile

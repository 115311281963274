import React, { useEffect, useState } from "react"
import Upload from "./upload"
import { useSelector } from "react-redux"
import axios from "axios"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { v4 as uuidv4 } from "uuid"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

const FormField = ({ title, value, type, setter, placeholder }) => (
  <div className="d-flex flex-column ">
    <div className="p-1">{title}</div>
    <div className="input-group mb-3">
      <input
        style={{ color: "rgba(100,100,100,0.8)" }}
        type={type}
        className="textinput textInput form-control"
        value={value ? value : ""}
        onChange={(v) => {
          setter(v.target.value)
        }}
        placeholder={placeholder}
      />
    </div>
  </div>
)
const TaskTypeCard = ({
  taskTypeData,
  org_id,
  refresh,
  newTask = false,
  setAddNewSelected,
  categories,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [nameShort, setNameShort] = useState(taskTypeData.name_short)
  const [nameLong, setNameLong] = useState(taskTypeData.name_long)
  const [taskCat, setTaskCat] = useState(
    categories.find((obj) => obj.value === taskTypeData.category_tag_id)
  )

  const [description, setDescription] = useState(taskTypeData.description)
  const [error, setError] = useState("")
  const [edited, setEdited] = useState(false)

  // const [recurring, setRecurring] = useState("")
  // const [recurringSpacing, setRecurringSpacing] = useState(1)

  const handleNameShort = (e) => {
    setNameShort(e)
    setEdited(true)
  }

  const handleNameLong = (e) => {
    setNameLong(e)
    setEdited(true)
  }
  const handleDescription = (e) => {
    setDescription(e)
    setEdited(true)
  }
  const handleCat = (e) => {
    setTaskCat(e)
    setEdited(true)
  }

  const handleSave = () => {
    let body = {
      ...taskTypeData,
      name_short: nameShort,
      description: description,
      name_long: nameLong,
      category_tag_id: taskCat ? taskCat.value : null,
    }
    console.log(body.parent_organization_id)
    if (body.parent_organization_id === undefined) {
      body.parent_organization_id = org_id
    }

    axios
      .post(`/upsert_task_type/`, body)
      .then((res) => {
        setEdited(false)
        setIsOpen(false)
        refresh()
        if (newTask) {
          setAddNewSelected(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (typeof err.response.data.detail === "string") {
          setError(err.response.data.detail)
        } else {
          setError(err.message)
        }
      })
  }

  const handleCreateCatTag = (inputValue) => {
    setEdited(true)
    let body = {
      name: inputValue,
    }

    axios
      .post(`/create-task-cat/`, body)
      .then((res) => {
        setTaskCat({ label: res.data.name, value: res.data.id })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="card shadow ">
      <div
        className="card-body d-flex flex-row align-items-center justify-content-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4>{nameLong ? nameLong : "New Task Type"}</h4>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="sidebar-icon "
          // onClick={() => setIsOpen(!isOpen)}
          style={!isOpen ? {} : { transform: "rotate(90deg)" }}
        />
      </div>
      {(newTask || isOpen) && (
        <div className="p-3">
          <FormField
            title="Full Name"
            type="text"
            value={nameLong}
            placeholder={"Please add a Name"}
            setter={handleNameLong}
          />
          <FormField
            title="Name (acronym)"
            type="text"
            value={nameShort}
            placeholder={"Please add an Acronym"}
            setter={handleNameShort}
          />
          <FormField
            title="Description"
            type="text"
            value={description}
            placeholder={"Please add a Description"}
            setter={handleDescription}
          />
          <CreatableSelect
            options={categories}
            value={taskCat}
            onChange={handleCat}
            onCreateOption={handleCreateCatTag}
          />
          {/*<div className="form-row row">*/}
          {/*  <div className="form-group col-md-8">*/}
          {/*    <p>Recurring</p>*/}
          {/*    <select*/}
          {/*      className="custom-select"*/}
          {/*      value={recurring}*/}
          {/*      onChange={(e) => setRecurring(e.target.value)}*/}
          {/*      style={{ width: "100%" }}*/}
          {/*    >*/}
          {/*      <option value="">Does Not Repeat</option>*/}
          {/*      <option value="annual">Annual</option>*/}
          {/*      <option value="years">Years</option>*/}
          {/*      <option value="months">Months</option>*/}
          {/*      <option value="weeks">Weeks</option>*/}
          {/*      <option value="days">Days</option>*/}
          {/*    </select>*/}
          {/*  </div>*/}
          {/*  {recurring !== "" && recurring !== "annual" && (*/}
          {/*    <div className="form-group col-md-4">*/}
          {/*      <p>Repeat Every*</p>*/}
          {/*      <input*/}
          {/*        type="number"*/}
          {/*        className="textinput form-control"*/}
          {/*        value={recurringSpacing}*/}
          {/*        onChange={(e) => setRecurringSpacing(e.target.value)}*/}
          {/*        min={1}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}

          {edited && (
            <button className="default-button m-0" onClick={handleSave}>
              Save
            </button>
          )}
          {newTask && (
            <button
              className="default-button m-0"
              onClick={() => setAddNewSelected(false)}
            >
              Cancel
            </button>
          )}
          {error && <div>{error}</div>}
        </div>
      )}
    </div>
  )
}

const TaskCatCard = ({ category, handleEdit, org_id, categories }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="card">
      <div
        className="card-body d-flex flex-row align-items-center justify-content-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4>{category.label}</h4>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="sidebar-icon "
          // onClick={() => setIsOpen(!isOpen)}
          // style={!isOpen ? {} : { transform: "rotate(90deg)" }}
        />
      </div>
      {isOpen && (
        <div className="p-3">
          {category.options.map((v) => (
            <TaskTypeCard
              key={v.value}
              taskTypeData={v.object}
              org_id={org_id}
              refresh={handleEdit}
              categories={categories}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const TaskTypeViewEdit = ({ taskTypes, refresh }) => {
  const user = useSelector((state) => state.user)

  const [addNewSelected, setAddNewSelected] = useState(false)

  const handleEdit = () => {
    setAddNewSelected(false)
  }

  const categories = taskTypes.map((v) => {
    return { value: v.id, label: v.label }
  })
  return (
    <div>
      <div className="d-flex flex-row flex-wrap">
        <div className="col-6 p-2">
          {addNewSelected ? (
            <TaskTypeCard
              taskTypeData={{}}
              org_id={user.currentFocusedTeam.id}
              refresh={refresh}
              newTask={true}
              setAddNewSelected={setAddNewSelected}
              categories={categories}
            />
          ) : (
            <div className="card shadow">
              <div
                className="card-body d-flex flex-row align-items-center justify-content-between"
                onClick={() => setAddNewSelected(true)}
              >
                + Add New Task Type
              </div>
            </div>
          )}
        </div>

        {taskTypes.map((c) => (
          <div className="col-6 p-2">
            <TaskCatCard
              category={c}
              org_id={user.currentFocusedTeam.id}
              handleEdit={refresh}
              categories={categories}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default TaskTypeViewEdit

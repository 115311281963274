import { Link, NavLink } from "react-router-dom"

// Images
import Logo from "../images/logo.png"
import LogoSmall from "../images/logo-small.png"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileExcel,
  faTachometerAlt,
  faArchive,
  faCalendarAlt,
  faUsers,
  faAddressBook,
  faFileAlt,
  faChartLine,
  faLock,
} from "@fortawesome/free-solid-svg-icons"

import { useDispatch, useSelector } from "react-redux"

// Components
import TeamSelector from "./teamSelector"
import OrgChartView from "../pages/supervisor/orgChart"
import { getProfile, setTeamID } from "../redux/userSlice"
import axios from "axios"
import { useNavigate } from "react-router"

const Sidebar = ({ disableTeams }) => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const onSelect = (org) => {
    dispatch(setTeamID(org.id))
  }

  const handleNewMissionGroup = () => {
    const body = {
      type: "mission_group",
      name_long: "New Mission Group",
      name_short: "",
      name_code: "",
      parent_org_id: user.currentFocusedTeam.id,
    }
    axios
      .post(`create-organization/?org_id=${user.currentFocusedTeam.id}`, body)
      .then(async (res) => {
        await dispatch(getProfile())

        dispatch(setTeamID(res.data.id))

        navigate("/admin")
      })

      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="sidebar disable-scrollbars">
      {user.isInstitutionAdmin && (
        <NavLink className="nav-link-brand" to="/admin">
          <FontAwesomeIcon icon={faLock} className="sidebar-icon" />
          Admin Settings
        </NavLink>
      )}

      <hr className="sidebar-divider" />

      <OrgChartView
        onSelect={onSelect}
        checkboxes={false}
        selected={[user.currentFocusedTeam]}
      />

      <button className="default-button" onClick={handleNewMissionGroup}>
        Add Mission Group
      </button>

      <hr className="sidebar-divider" />
    </div>
  )
}

export default Sidebar

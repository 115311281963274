import React, { useEffect, useState } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import EventCard from "../../components/eventCard"
import LoadingDashboard from "../../components/loadingDashboard"

import SupervisorCalendar from "../../components/supervisorCalendar"
import SmallLoader from "../../components/smallLoader"
import EventReminderModal from "../../components/eventReminderModal"

import { Link } from "react-router-dom"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faCalendar } from "@fortawesome/free-solid-svg-icons"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { setErrors } from "../../redux/userSlice"

import axios from "axios"
const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export function eventTimeFormatted(event) {
  const start_datetime = new Date(event.start_datetime)
  const end_datetime = new Date(event.end_datetime)
  const eventTime = (() => {
    const startD = `${weekday[start_datetime.getDay()]}, ${start_datetime.toLocaleDateString()}`
    const startT = start_datetime.toLocaleTimeString({
      hour12: false,
    })

    const endD = `${weekday[end_datetime.getDay()]}, ${end_datetime.toLocaleDateString()}`
    const endT = end_datetime.toLocaleTimeString({
      hour12: false,
    })

    if (endD === startD) {
      if (event.all_day) {
        return `${startD} ALL DAY`
      } else {
        return `${startD} @ ${startT}`
      }
    } else {
      if (event.all_day) {
        return `${startD} to ${endD} ALL DAY`
      } else {
        return `${startD} @ ${startT} to ${endD} @ ${endT}`
      }
    }
  })()

  return eventTime
}

const EventCardList = ({ events, getEvents }) => {
  return (
    <div className="row">
      <div className="col">
        <ul className="list-group">
          <li
            className="h3 list-group-item list-group-item-secondary text-center"
            style={{
              color: "white",
              backgroundColor: "var(--blue)",
              fontWeight: "bold",
            }}
          >
            Upcoming Events
          </li>
          {events.map((event) => {
            const eventDate = new Date(event.end_datetime)
            if (eventDate > Date.now()) {
              return (
                <EventCard
                  key={event.id}
                  event={event}
                  getEvents={getEvents}
                  editButton
                />
              )
            }
          })}
          {!events ||
            (events.length === 0 && (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                }}
              >
                There are no upcoming events
              </p>
            ))}
        </ul>
      </div>
      <div className="col">
        <ul className="list-group">
          <li
            className="h3 list-group-item list-group-item-secondary text-center"
            style={{
              color: "white",
              backgroundColor: "var(--blue)",
              fontWeight: "bold",
            }}
          >
            Past Events
          </li>
          {events.map((event) => {
            const eventDate = new Date(event.end_datetime)
            if (eventDate <= Date.now()) {
              return (
                <EventCard key={event.id} event={event} getEvents={getEvents} />
              )
            }
          })}
          {!events ||
            (events.length === 0 && (
              <p
                style={{
                  textAlign: "center",
                  fontSize: "1.5rem",
                  marginTop: "1rem",
                }}
              >
                There are no past events
              </p>
            ))}
        </ul>
      </div>
    </div>
  )
}

const TraineeView = ({ trainees, traineeLoading }) => {
  const [loading, setLoading] = useState(false)
  const [currentTrainee, setCurrentTrainee] = useState("")

  const [eventsList, setEventsList] = useState([])
  const [eventsError, setEventsError] = useState("")

  useEffect(() => {
    if (currentTrainee !== "") {
      getTraineeEvents()
    }
  }, [currentTrainee])

  const getTraineeEvents = () => {
    setLoading(true)

    axios
      .get(`/get-trainee-events/${currentTrainee}`)
      .then((res) => {
        setEventsList(res.data.events)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.repsonse)
        setEventsError("An error occured, please try again")
        setLoading(false)
      })
  }

  if (traineeLoading || loading) {
    return <SmallLoader />
  }

  if (!traineeLoading && trainees.length === 0) {
    return (
      <div>
        <br />
        <h5>There are no trainees in this shop</h5>
      </div>
    )
  }

  return (
    <div className="container-fluid fade-in" style={{ margin: "1rem 0" }}>
      <select
        value={currentTrainee}
        onChange={(e) => setCurrentTrainee(e.target.value)}
        className="custom-select"
      >
        <option value="">Select Trainee</option>
        {trainees.map((t) => {
          return (
            <option key={t.id} value={t.id}>
              {t.name.toUpperCase()}
            </option>
          )
        })}
      </select>

      <br />

      {eventsError && eventsError.length > 0 ? (
        <div className="h6 text-center text-danger">{eventsError}</div>
      ) : (
        currentTrainee !== "" && (
          <EventCardList events={eventsList} getEvents={getTraineeEvents} />
        )
      )}
    </div>
  )
}

const Events = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  const [traineeLoading, setTraineeLoading] = useState(false)
  const [trainees, setTrainees] = useState([])

  // Toggle between all events from shop and all events for specifc trainee
  const [traineeView, setTraineeView] = useState(false)

  // Toggle between regular list view and calendar view
  const [listView, setListView] = useState(true)

  // Formatted events list for calendar view
  const [calendarEvents, setCalendarEvents] = useState([])

  // Search function state
  const [q, setQ] = useState("")

  //

  const filteredEvents = events.filter((e) =>
    e.title.toLowerCase().includes(q.toLowerCase())
  )

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam) {
        setLoading(true)

        getEvents()
      }
    }

    // Get list of trainees
    setTraineeLoading(true)
    axios
      .get(`/trainees-overview/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setTrainees(res.data)
        setTraineeLoading(false)
      })
      .catch((err) => {
        console.log(err)
        dispatch(
          setErrors([
            "An unexpected error has happened, please reload the page",
          ])
        )
        setTraineeLoading(false)
      })
  }, [user.currentFocusedTeam, user.status])

  const getEvents = () => {
    setLoading(true)
    setCalendarEvents([])
    axios
      .get(`/events/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        res.data.map((e) => {
          e.start_datetime = new Date(e.start_datetime)
          e.end_datetime = new Date(e.end_datetime)
        })
        setEvents(res.data)

        setLoading(false)
        // Format data to readable version for calendar component
        // res.data.map((e) => {
        //   const newEvent = {
        //     title: e.title,
        //     start: new Date(`${e.start_date}T${e.start_time}`),
        //     end: new Date(`${e.end_date}T${e.end_time}`),
        //     id: e.id,
        //     description: e.description,
        //     location: e.location,
        //     start_datetime: e.start_datetime,
        //     end_datetime: e.end_datetime,
        //     color: e.color,
        //     start_date: e.start_date,
        //     end_date: e.end_date,
        //     trainees: e.trainees,
        //     cap: e.cap,
        //     all_day: e.all_day,
        //   }
        //   setCalendarEvents((prev) => [...prev, newEvent])
        // })
      })
      .catch((err) => {
        console.log(err)
        dispatch(
          setErrors([
            "An unexpected error has happened, please reload the page",
          ])
        )
        setLoading(false)
      })
  }

  if (loading) {
    return <LoadingDashboard />
  }

  return (
    <div>
      <div className="">
        {/* {events && events.length > 0 && (
          <> */}
      </div>
      {/* </>
        )} */}
      {listView ? (
        <div className="wrapper">
          <div className="container-fluid fade-in">
            <div className="d-flex" style={{ margin: ".5rem 0rem 0rem 0rem" }}>
              <Link
                className={"default-button"}
                to={"/events/new"}
                style={{ fontSize: "1rem" }}
              >
                <FontAwesomeIcon icon={faPlus} /> Add Event
              </Link>

              <button
                className="default-button"
                onClick={() => setListView(false)}
                style={{ fontSize: "1rem" }}
              >
                <FontAwesomeIcon icon={faCalendar} /> Calendar View
              </button>
              <EventReminderModal events={events} />
            </div>
            <div className="search-wrapper">
              <label htmlFor="search-form">
                <input
                  style={{ margin: "1rem 0" }}
                  type="search"
                  name="search-form"
                  id="search-form"
                  className="search-input textInput form-control"
                  placeholder="Search for..."
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
                <span className="sr-only">Search names here</span>
              </label>
            </div>
            <EventCardList events={filteredEvents} getEvents={getEvents} />
          </div>
        </div>
      ) : (
        <SupervisorCalendar
          setListView={setListView}
          events={events}
          getEvents={getEvents}
        />
      )}
    </div>
  )
}

export default Events

import React, { useEffect, useState } from "react"
import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import SmallLoader from "../../components/smallLoader"
import ProgressBar from "../../components/progressBar"
import SingleChat from "../../components/singleChat"

import Modal from "react-responsive-modal"
import { modalStylesDanger, modalStyles } from "../../styles/constanStyles"

import { useSelector } from "react-redux"

import PhoneInput from "react-phone-number-input"

import axios from "axios"

import { useNavigate, useParams } from "react-router"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBell,
  faCheck,
  faCheckCircle,
  faEdit,
  faTasks,
  faTimes,
  faTimesCircle,
  faUserFriends,
  faUserSlash,
  faPlus,
  faTrash,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons"

const verboseStatusFromCode = {
  d: "Due",
  cd: "Coming Due",
  od: "Overdue",
  f: "Satisfied",
  ff: "Satisfied",
  nk: "Not Known",
  yes: "Satisfied",
  no: "Due",
}

const ComplianceTab = ({
  dueTasks,
  overdueTasks,
  comingDueTasks,
  completedTasks,
  getInfo,
}) => {
  // Modals
  const [completeTaskModal, setCompleteTaskModal] = useState(false)
  const [undoTaskModal, setUndoTaskModal] = useState(false)
  const [modalError, setModalError] = useState("")
  const [modalLoading, setModalLoading] = useState(false)
  const [taskToBeCompleted, setTaskToBeCompleted] = useState({})

  const handleComplete = () => {
    setModalLoading(true)
    setModalError("")

    axios
      .patch(`/complete-task/?task_id=${taskToBeCompleted.id}`)
      .then(() => {
        getInfo()
      })
      .catch((err) => {
        console.log(err)
        setModalError("An error has occured, please try again")
        setModalLoading(false)
      })
  }

  const handleUndo = () => {
    setModalLoading(true)
    setModalError("")

    axios
      .patch(`/undo-complete-task/?task_id=${taskToBeCompleted.id}`)
      .then(() => {
        getInfo()
      })
      .catch((err) => {
        console.log(err)
        setModalError("An error has occured, please try again")
        setModalLoading(false)
      })
  }

  return (
    <>
      <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
        <div className="row">
          <div className="col-sm-3">
            <ul className="list-group overdue-list">
              <li className="list-group-item list-group-item-danger text-center">
                <FontAwesomeIcon icon={faTasks} /> Overdue Tasks
              </li>
              {overdueTasks.map((task) => {
                return (
                  <li
                    className="list-group-item text-center"
                    style={{ padding: "1rem 0" }}
                    key={task.id}
                  >
                    {task.task_type.name_long}
                    <br />
                    <span style={{ color: "gray", fontSize: "0.75rem" }}>
                      Due Date:{" "}
                      {new Date(task.due_date).toLocaleDateString("en-US", {
                        hour12: false,
                      })}
                    </span>
                    {task.description && (
                      <div>
                        <br />
                        <span style={{ color: "gray", fontSize: "0.75rem" }}>
                          Description: {task.description}
                        </span>
                      </div>
                    )}
                    <br />
                    <button
                      className="default-button default-button-green"
                      onClick={() => {
                        setModalLoading(false)
                        setModalError("")
                        setTaskToBeCompleted(task)
                        setCompleteTaskModal(true)
                      }}
                    >
                      Mark as Complete
                    </button>
                  </li>
                )
              })}
              {overdueTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any overdue tasks.
                </div>
              )}
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-group due-list">
              <li className="list-group-item list-group-item-warning text-center">
                <FontAwesomeIcon icon={faTasks} /> Due Tasks
              </li>
              {dueTasks.map((task) => {
                return (
                  <li
                    className="list-group-item text-center"
                    style={{ padding: "1rem 0" }}
                    key={task.id}
                  >
                    {task.task_type.name_long}
                    <br />
                    <span style={{ color: "gray", fontSize: "0.75rem" }}>
                      Due Date:{" "}
                      {new Date(task.due_date).toLocaleDateString("en-US", {
                        hour12: false,
                      })}
                    </span>
                    {task.description && (
                      <div>
                        <br />
                        <span style={{ color: "gray", fontSize: "0.75rem" }}>
                          Description: {task.description}
                        </span>
                      </div>
                    )}
                    <br />
                    <button
                      className="default-button default-button-green"
                      onClick={() => {
                        setModalLoading(false)
                        setModalError("")
                        setTaskToBeCompleted(task)
                        setCompleteTaskModal(true)
                      }}
                    >
                      Mark as Complete
                    </button>
                  </li>
                )
              })}
              {dueTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any due tasks.
                </div>
              )}
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-group coming-due-list">
              <li
                className="list-group-item text-center "
                style={{ backgroundColor: "#CAE9F5" }}
              >
                <FontAwesomeIcon icon={faTasks} /> Coming Due Tasks
              </li>
              {comingDueTasks.map((task) => {
                return (
                  <li
                    className="list-group-item text-center"
                    style={{ padding: "1rem 0" }}
                    key={task.id}
                  >
                    {task.task_type.name_long}
                    <br />
                    <span style={{ color: "gray", fontSize: "0.75rem" }}>
                      Due Date:{" "}
                      {new Date(task.due_date).toLocaleDateString("en-US", {
                        hour12: false,
                      })}
                    </span>
                    {task.description && (
                      <div>
                        <br />
                        <span style={{ color: "gray", fontSize: "0.75rem" }}>
                          Description: {task.description}
                        </span>
                      </div>
                    )}
                    <br />
                    <button
                      className="default-button default-button-green"
                      onClick={() => {
                        setModalLoading(false)
                        setModalError("")
                        setTaskToBeCompleted(task)
                        setCompleteTaskModal(true)
                      }}
                    >
                      Mark as Complete
                    </button>
                  </li>
                )
              })}
              {comingDueTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any coming due tasks.
                </div>
              )}
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-group coming-due-list">
              <li
                className="list-group-item text-center "
                style={{ backgroundColor: "var(--green)" }}
              >
                <FontAwesomeIcon icon={faTasks} /> Tasks Completed
              </li>
              {completedTasks.map((task) => {
                return (
                  <li
                    className="list-group-item text-center"
                    style={{ padding: "1rem 0" }}
                    key={task.task}
                  >
                    {task.task_type.name_long}
                    <br />
                    <span style={{ color: "gray", fontSize: "0.75rem" }}>
                      Date Completed:{" "}
                      {new Date(task.completion_date).toLocaleDateString(
                        "en-US",
                        {
                          hour12: false,
                        }
                      )}
                    </span>
                    {task.description && (
                      <div>
                        <br />
                        <span style={{ color: "gray", fontSize: "0.75rem" }}>
                          Description: {task.description}
                        </span>
                      </div>
                    )}
                    <br />

                    <button
                      className="default-button default-button-red"
                      onClick={() => {
                        setModalLoading(false)
                        setModalError("")
                        setTaskToBeCompleted(task)
                        setUndoTaskModal(true)
                      }}
                    >
                      Undo
                    </button>
                  </li>
                )
              })}
              {completedTasks.length === 0 && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "1rem",
                  }}
                >
                  This trainee doesn't have any tasks completed days.
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* Complete Task Modal */}
      <Modal
        styles={modalStyles}
        open={completeTaskModal}
        onClose={() => setCompleteTaskModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Complete Task</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to complete this task:{" "}
          <b>{taskToBeCompleted.title}</b>? This action can be undone in the
          finished task log.
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <button
          className="default-button float-right"
          style={{ marginLeft: "0.5rem" }}
          disabled={modalLoading}
          onClick={handleComplete}
        >
          Complete Task
        </button>
        <button
          className="default-button default-button-gray float-right"
          onClick={() => setCompleteTaskModal(false)}
          disabled={modalLoading}
        >
          Cancel
        </button>
      </Modal>
      {/* Undo Task Modal */}
      <Modal
        styles={modalStylesDanger}
        open={undoTaskModal}
        onClose={() => setUndoTaskModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Undo Task</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to undo this task:{" "}
          <b>{taskToBeCompleted.finishedAction}</b>?
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <button
          className="default-button float-right default-button-red"
          style={{ marginLeft: "0.5rem" }}
          disabled={modalLoading}
          onClick={handleUndo}
        >
          Undo Task
        </button>
        <button
          className="default-button float-right"
          onClick={() => setUndoTaskModal(false)}
          disabled={modalLoading}
        >
          Cancel
        </button>
      </Modal>
    </>
  )
}

const DocumentsTab = ({ documents, documentLoading, getTraineeDocuments }) => {
  return (
    <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
      {documentLoading ? (
        <SmallLoader />
      ) : !documents || documents.length === 0 ? (
        <div>This trainee does not have any documents</div>
      ) : (
        // <DocumentsView
        //   documents={documents}
        //   getTraineeDocuments={getTraineeDocuments}
        // />
        <></>
      )}
    </div>
  )
}

const DataRow = ({ label, value }) => (
  <div className="row">
    <div className="col-lg-5">
      <h6>
        <strong>{label}</strong>
      </h6>
    </div>
    <div className="col-lg-5">{value ? value : value === 0 ? 0 : "None"}</div>
  </div>
)

const FormField = ({ title, value, type, setter }) => (
  <>
    <p>{title}</p>
    <div className="input-group mb-3">
      <input
        style={{ color: "rgba(100,100,100,0.8)" }}
        type={type}
        className="textinput textInput form-control"
        value={value}
        onChange={(v) => {
          setter(v.target.value)
        }}
      />
    </div>
  </>
)

const InformationTab = ({ trainee, getInfo }) => {
  const [editView, setEditView] = useState(false)

  const [loading, setLoading] = useState(false)
  // Form states
  const [firstName, setFirstName] = useState(trainee.first_name)
  const [middleName, setMiddleName] = useState(trainee.middle_name)
  const [lastName, setLastName] = useState(trainee.last_name)
  const [phoneNumber, setPhoneNumber] = useState(trainee.phone_number)
  const [email, setEmail] = useState(trainee.email)
  const [idNumber, setIdNumber] = useState(trainee.id_number)

  const updateTraineeInfo = (e) => {
    e.preventDefault()
    setLoading(true)

    const body = {
      phone_number: phoneNumber,
      id: trainee.id,
      email: email,
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      id_number: idNumber,
    }

    axios
      .patch("/edit-trainee", body)
      .then((res) => {
        getInfo()

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
      })
  }

  return (
    <div
      className="container-fluid fade-in col-md"
      style={{ padding: "1rem 0" }}
    >
      {!editView ? (
        <div className="col-md-10" id="trainee-information">
          <button className="default-button" onClick={() => setEditView(true)}>
            <FontAwesomeIcon icon={faEdit} /> Edit Information
          </button>
          <h3>Personal Details</h3>
          <DataRow label="First Name" value={firstName} />
          <DataRow label="Middle Name" value={middleName} />
          <DataRow label="Last Name" value={lastName} />
          <DataRow label="Phone Number" value={phoneNumber} />
          <DataRow label="Email" value={email} />
          <DataRow label="EMP #" value={idNumber} />
        </div>
      ) : (
        <div className="row edit-info-form-container col-md-6">
          <form onSubmit={updateTraineeInfo}>
            <button
              type="button"
              className="btn btn-outline-danger mb-4  mr-4"
              onClick={() => setEditView(false)}
              style={{ marginRight: 20 }}
            >
              <FontAwesomeIcon icon={faTimes} className="fas fa-times" /> Cancel
            </button>
            <button type="submit" className="btn btn-outline-success mb-4">
              <FontAwesomeIcon
                icon={faCheck}
                className="fas fa-check"
                disabled={loading}
              />{" "}
              Save
            </button>
            <FormField
              title="First Name"
              type="text"
              value={firstName}
              setter={setFirstName}
            />
            <FormField
              title="Middle Name"
              type="text"
              value={middleName}
              setter={setMiddleName}
            />
            <FormField
              title="Last Name"
              type="text"
              value={lastName}
              setter={setLastName}
            />
            <div>
              <p>Phone Number</p>
              <div className="input-group mb-3">
                <PhoneInput
                  style={{}}
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  defaultCountry="US"
                />
              </div>
            </div>
            <FormField
              title="Email"
              type="text"
              value={email}
              setter={setEmail}
            />
            <FormField
              title="DOD ID"
              type="number"
              value={idNumber}
              setter={setIdNumber}
            />
          </form>
        </div>
      )}
    </div>
  )
}

const MessagingTab = ({ messaging, trainee }) => {
  return (
    <div
      className="col-md"
      style={{
        alignItems: "center",
        // backgroundColor: "rgb(217,242,196)",
        width: "100%",
        maxHeight: 500,
        border: "2px",
        borderStyle: "solid",
        // borderRadius: "5%",
        borderWidth: "1px",
        display: "flex",
        flexDirection: "column-reverse",
        overflowY: "auto",
      }}
    >
      <SingleChat messaging={messaging} trainee={trainee} />
    </div>
  )
}

const NotesTabRow = ({
  notes,
  note,
  setNotes,
  formatDate,
  removeNote,
  setDeleteModalOpen,
  handleDeleteNote,
  setDeleteNote,
  setDeleteError,
  setEditModalOpen,
  setNoteToEdit,
}) => {
  return (
    <tr key={note.id}>
      <td>{formatDate(note.created)}</td>
      <td style={{ "white-space": "pre-line" }}>{note.text}</td>
      <td>
        <button
          onClick={() => {
            setDeleteModalOpen(true)
            setDeleteNote(note.id)
            setDeleteError("")
          }}
          className="btn"
        >
          <FontAwesomeIcon
            icon={faTrash}
            style={{
              color: "var(--red)",
              marginLeft: "0.5rem",
              cursor: "pointer",
            }}
          />
          {""}
        </button>
      </td>
    </tr>
  )
}

const NotesTab = ({ notes, setNotes, trainee }) => {
  const [note, setNote] = useState("")

  //States for deletion of note
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteNote, setDeleteNote] = useState()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [deleteError, setDeleteError] = useState("")

  function formatDate(date) {
    if (date) {
      const newDate = new Date(date)
      return newDate.toDateString()
    }
    return "N/A"
  }

  function submitHandler(event) {
    event.preventDefault()

    const body = {
      text: note,
      trainee: trainee.id,
    }

    axios
      .post(`/notes/`, body)
      .then((res) => {
        setNotes((prevState) => [res.data, ...prevState])
      })
      .catch((err) => {
        console.log(err)
      })
    setNote("")
  }

  function changeHandler(event) {
    event.preventDefault()
    setNote(event.target.value)
  }

  const handleDeleteNote = (e) => {
    e.preventDefault()

    setDeleteError("")
    setDeleteLoading(true)

    axios
      .delete(`/notes/${deleteNote}`)
      .then(() => {
        setDeleteModalOpen(false)
        setDeleteLoading(false)
        setDeleteError("")
        // Filter out deleted note
        setNotes((prev) => prev.filter((note) => note.id !== deleteNote))
      })
      .catch((err) => {
        setDeleteError("An unexpected error has occured, please try again!")
        setDeleteLoading(false)
        console.log(err.response)
      })
  }

  return (
    <>
      <div className="container-fluid fade-in" style={{ padding: "1rem 0" }}>
        <div className="px-5 mx-5">
          <table
            className="table table-hover table-responsive"
            style={{ width: "100%", backgroundColor: "#eaecf4" }}
          >
            <col style={{ width: "10%" }}></col>
            <col style={{ width: "85%" }}></col>
            <col style={{ width: "5%" }}></col>
            <thead className="thead-light">
              <tr>
                <th>Date Created</th>
                <th>Note</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{formatDate(new Date())}</td>
                <td>
                  <div className="form-group">
                    <textarea
                      id="notes-title"
                      type="text"
                      name="note"
                      value={note}
                      className="form-control"
                      placeholder="Write your note here..."
                      onChange={changeHandler}
                    />
                  </div>
                </td>
                <td>
                  <button className="default-button" onClick={submitHandler}>
                    <FontAwesomeIcon icon={faPlus} />
                    {""}
                  </button>
                </td>
              </tr>
              {notes.map((note) => {
                return (
                  <NotesTabRow
                    key={note.id}
                    note={note}
                    notes={notes}
                    setNotes={setNotes}
                    formatDate={formatDate}
                    setDeleteModalOpen={setDeleteModalOpen}
                    handleDeleteNote={handleDeleteNote}
                    setDeleteNote={setDeleteNote}
                    setDeleteError={setDeleteError}
                  />
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        styles={modalStylesDanger}
        open={deleteModalOpen}
        center
        focusTrapped={false}
        onClose={() => setDeleteModalOpen(false)}
      >
        <h5 className="modal-title">Delete Note Record</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to delete this note record? This action cannot
          be undone.
        </p>
        <br />
        {deleteError && (
          <div
            className="h6 text-center text-danger"
            style={{ flexBasis: "100%" }}
          >
            {deleteError}
          </div>
        )}
        <button
          className="default-button default-button-red float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleDeleteNote}
          disabled={deleteLoading}
        >
          Delete
        </button>
        <button
          className="default-button float-right"
          type="reset"
          onClick={(e) => setDeleteModalOpen(false)}
          disabled={deleteLoading}
        >
          Cancel
        </button>
      </Modal>
    </>
  )
}

const TabTitle = ({ setCurrentTab, tabName, currentTab, tabTitleOverride }) => {
  return (
    <h4
      className={
        currentTab === tabName
          ? "tab-link header-text tab-link-active"
          : "tab-link header-text"
      }
      onClick={() => setCurrentTab(tabName)}
    >
      {tabTitleOverride ? tabTitleOverride : tabName}
    </h4>
  )
}

const TraineeOverview = ({ trainee, messaging, getInfo }) => {
  // States for all task types
  const [dueTasks, setDueTasks] = useState([])
  const [overdueTasks, setOverdueTasks] = useState([])
  const [comingDueTasks, setComingDueTasks] = useState([])
  const [completedTasks, setCompletedTasks] = useState([])

  // Filter into tasks types
  useEffect(() => {
    setDueTasks([])
    setOverdueTasks([])
    setComingDueTasks([])
    setCompletedTasks([])

    if (trainee.tasks) {
      trainee.tasks.forEach((task) => {
        const tstatus = task.status
        const taskType = task.action_type
        // Compliance statuses
        if (tstatus === "d") {
          setDueTasks((prev) => [...prev, task])
        } else if (tstatus === "od") {
          setOverdueTasks((prev) => [...prev, task])
        } else if (tstatus === "cd") {
          setComingDueTasks((prev) => [...prev, task])
        } else if (tstatus === "f") {
          setCompletedTasks((prev) => [...prev, task])
        }
      })
    }
  }, [trainee.tasks])

  return (
    <div style={{ padding: "1rem" }}>
      {/*<div className="nav">*/}
      {/*  <TabTitle*/}
      {/*    setCurrentTab={setCurrentTab}*/}
      {/*    currentTab={currentTab}*/}
      {/*    tabName="Compliance"*/}
      {/*  />*/}
      {/*  /!* <TabTitle*/}
      {/*    setCurrentTab={setCurrentTab}*/}
      {/*    currentTab={currentTab}*/}
      {/*    tabName="Documents"*/}
      {/*  /> *!/*/}
      {/*  <TabTitle*/}
      {/*    setCurrentTab={setCurrentTab}*/}
      {/*    currentTab={currentTab}*/}
      {/*    tabName="Information"*/}
      {/*  />*/}
      {/*  {*/}
      {/*    <TabTitle*/}
      {/*      setCurrentTab={setCurrentTab}*/}
      {/*      currentTab={currentTab}*/}
      {/*      tabName="Messaging"*/}
      {/*    />*/}
      {/*  }*/}
      {/*  {*/}
      {/*    <TabTitle*/}
      {/*      setCurrentTab={setCurrentTab}*/}
      {/*      currentTab={currentTab}*/}
      {/*      tabName="Notes"*/}
      {/*    />*/}
      {/*  }*/}
      {/*  /!* <TabTitle*/}
      {/*    setCurrentTab={setCurrentTab}*/}
      {/*    currentTab={currentTab}*/}
      {/*    tabName="Availability"*/}
      {/*  /> *!/*/}
      {/*</div>*/}
      {/*{currentTab === "Compliance" && (*/}
      <div className="row">
        <InformationTab trainee={trainee} getInfo={getInfo} />
        <MessagingTab messaging={messaging} trainee={trainee} />
      </div>

      <ComplianceTab
        dueTasks={dueTasks}
        overdueTasks={overdueTasks}
        comingDueTasks={comingDueTasks}
        trainee={trainee}
        completedTasks={completedTasks}
        getInfo={getInfo}
      />
      {/*)}*/}
      {/* {currentTab === "Documents" && (
        <DocumentsTab
          documents={documents}
          documentLoading={documentLoading}
          getTraineeDocuments={getTraineeDocuments}
        />
      )} */}
      {/*{currentTab === "Information" && (*/}

      {/*)}*/}
      {/*{currentTab === "Messaging" && (*/}

      {/*)}*/}
      {/*{currentTab === "Notes" && (*/}
      {/*  <NotesTab notes={notes} setNotes={setNotes} trainee={trainee} />*/}
      {/*)}*/}
      {/*{currentTab === "Availability" && (*/}
      {/*  <AvailabilityTab*/}
      {/*    availabilityLoading={availabilityLoading}*/}
      {/*    availability={availability}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  )
}

// Main Component
const Profile = () => {
  const user = useSelector((state) => state.user)

  const { userID } = useParams()
  const { teams } = useSelector((state) => state.user)

  const [loading, setLoading] = useState(true)
  const [trainee, setTrainee] = useState({})
  const [error, setError] = useState("")

  const navigate = useNavigate()

  const [documents, setDocuments] = useState([])
  const [documentLoading, setDocumentLoading] = useState(false)
  const [availabilityLoading, setAvailabilityLoading] = useState(true)
  const [availability, setAvailability] = useState([])
  const [notes, setNotes] = useState([])
  const [messaging, setMessaging] = useState([])

  // Modals
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [mergeModalOpen, setMergeModalOpen] = useState(false)
  const [mergeTrainee, setMergeTrainee] = useState("")
  const [allTrainees, setAllTrainees] = useState([])

  const [moveModalOpen, setMoveModalOpen] = useState(false)
  const [moveShop, setMoveShop] = useState("")
  const [allTeams, setAllTeams] = useState([])
  const [moveSuccessModalOpen, setMoveSuccessModalOpen] = useState(false)

  const [smsModalOpen, setSmsModalOpen] = useState(false)
  const [customModalOpen, setCustomModalOpen] = useState(false)
  const [customMessage, setCustomMessage] = useState("")

  const [modalError, setModalError] = useState("")
  const [modalLoading, setModalLoading] = useState(false)

  // State for all tasks completed this UTA
  const [completedTasks, setCompletedTasks] = useState([])
  const [loadingCompeltedTasks, setLoadingCompletedTasks] = useState(false)

  const [fetchError, setFetchError] = useState(false)

  // Sort all teams into alphabetical order for move trainee dropdown
  useEffect(() => {
    if (Array.isArray(teams)) {
      setAllTeams([...teams].sort())
    }
  }, [teams])

  const getInfo = () => {
    setLoading(true)

    axios
      .get(`/get-trainee/?trainee_id=${userID}`)
      .then((res) => {
        setTrainee(res.data)
        setLoading(false)
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setError("The profile you are looking for does not exist")
        } else if (err.response.status === 403) {
          setError("You are not authorized to view this profile")
        } else {
          setError("An unexpected error has occured, please try again")
        }
        setLoading(false)
        setFetchError(true)
      })
  }

  const getTraineeDocuments = () => {
    setDocumentLoading(true)
    axios
      .get(`/get-trainee-documents/${userID}`)
      .then((res) => {
        if (res.data) {
          setDocuments(res.data)
          setDocumentLoading(false)
        }
      })
      .catch((err) => {
        setError("An unexpected error has occured, please try again")
      })
  }

  const getTraineeMessages = () => {
    axios
      .get(`/get-trainee-messages/?trainee_id=${userID}`)
      .then((res) => {
        setMessaging(res.data)
      })
      .catch((err) => {
        console.log(err)
        setError("An unexpected error has occured, please try again")
      })
  }

  useEffect(() => {
    if (userID) {
      // Fetch trainee info on load
      getInfo()
      getTraineeMessages()
      // getTraineeDocuments()

      // Fetch availability on load (Availability currently deprecated)
      // axios
      //   .get(`/get-availability/${userID}`)
      //   .then((res) => {
      //     setAvailability(res.data.availability)
      //     setAvailabilityLoading(false)
      //   })
      //   .catch((err) => {
      //     setError("An unexpected error has occured, please try again")
      //   })

      // Fetch notes on load
      // axios
      //   .get(`/notes/?trainee=${userID}`)
      //   .then((res) => {
      //     setNotes(res.data)
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    }
  }, [userID])

  // Make list for merge trainees
  // useEffect(() => {
  //   if (trainee && trainee.id) {
  //     setAllTrainees([])
  //     axios.get("/get-trainees").then((res) => {
  //       res.data.trainees.forEach((t) => {
  //         if (t.id !== trainee.id) {
  //           setAllTrainees((prev) => [...prev, t])
  //         }
  //       })
  //     })
  //   }
  // }, [trainee])

  const handleDelete = (e) => {
    e.preventDefault()
    setModalLoading(true)

    axios
      .delete(`/trainee/?trainee_id=${trainee.id}`)
      .then(() => {
        navigate("/home")
      })
      .catch((err) => {
        console.log(err.response)
        setModalLoading(false)
        setModalError("An unexpected error has occured, please try again")
      })
  }

  const handleSMS = (e) => {
    e.preventDefault()

    setModalLoading(false)
    setModalError("")

    const body = {
      notify_type: "action",
      trainees: [trainee.id],
    }

    axios
      .post("/notify-trainees/", body)
      .then(() => {
        setSmsModalOpen(false)
        setModalError("")
        setModalLoading(false)
        getTraineeMessages()
      })
      .catch((err) => {
        setModalError("An unexpected error has occured, please try again")
        setModalLoading(false)
      })
  }
  const handleSendCustomMessage = (e) => {
    e.preventDefault()

    if (customMessage.length > 450) {
      setModalError("Please enter a message less than 450 characters")
      return
    }

    if (customMessage.length <= 0) {
      setModalError("Please enter a message")
      return
    }

    setModalLoading(false)
    setModalError("")

    const body = {
      notify_type: "custom",
      trainees: [trainee.id],
      message: customMessage,
    }

    axios
      .post("/notify-trainees/", body)
      .then(() => {
        setCustomModalOpen(false)
        setModalError("")
        setModalLoading(false)
        getTraineeMessages()
      })
      .catch((err) => {
        setModalError("An unexpected error has occured, please try again")
        setModalLoading(false)
      })
  }

  // const handleMoveTrainee = (e) => {
  //   e.preventDefault()
  //
  //   if (!moveShop || moveShop.length === 0) {
  //     setModalError("Please select a shop")
  //   } else {
  //     setModalLoading(true)
  //     setModalError("")
  //
  //     const body = {
  //       to: moveShop,
  //       id: trainee.id,
  //     }
  //
  //     axios
  //       .post("/move-trainee", body)
  //       .then(() => {
  //         setModalLoading(false)
  //         setMoveModalOpen(false)
  //         setMoveShop("")
  //         setMoveSuccessModalOpen(true)
  //       })
  //       .catch((err) => {
  //         setModalError("An unexpected error has happened, please try again")
  //         setModalLoading(false)
  //         console.log(err.response.data)
  //       })
  //   }
  // }

  if (!fetchError && (loading || documentLoading || loadingCompeltedTasks)) {
    return <LoadingDashboard />
  }

  if (error && error.length > 0) {
    return (
      <DefaultLayout disableTeams>
        <h2>{error}</h2>
      </DefaultLayout>
    )
  }

  const progressBarHeader = (trainee) => {
    return (
      <div className="col-xl-3 col-md-6 mb-4">
        <div className="card h-100">
          <div className="card-body">
            <ProgressBar
              percent={trainee.percentComplete}
              color={
                trainee.percentComplete === 100
                  ? "var(--green)"
                  : trainee.percentComplete < 87
                    ? "var(--red)"
                    : "var(--yellow)"
              }
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {/* Delete Modal */}
      <Modal
        styles={modalStyles}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">
          Are you sure you want to delete <b>{trainee.name}</b>
        </h5>
        <hr />
        <p className="modal-subtitle">
          Removing this trainee will also delete all of their associated data.
          This action cannot be undone.
        </p>
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}
        <button
          className="default-button default-button-red float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={handleDelete}
          disabled={modalLoading}
        >
          Delete Trainee
        </button>
        <button
          className="default-button float-right"
          onClick={() => setDeleteModalOpen(false)}
          disabled={modalLoading}
        >
          Cancel
        </button>
      </Modal>

      {/* SMS Modal */}
      <Modal
        styles={modalStyles}
        open={smsModalOpen}
        onClose={() => setSmsModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">
          Send action reminder to <b>{trainee.name}</b> via text SMS?
        </h5>
        <hr />
        {trainee.phone_number && trainee.phone_number.length > 0 ? (
          <>
            <p className="modal-subtitle">
              EzTrain will send a SMS text message to {trainee.name} of all
              incomplete tasks
            </p>
            {modalError && (
              <div className="h6 text-center text-danger">{modalError}</div>
            )}
            <button
              className="default-button float-right"
              style={{ marginLeft: "0.5rem" }}
              onClick={handleSMS}
              disabled={modalLoading}
            >
              Send
            </button>
            <button
              className="default-button default-button-gray float-right"
              onClick={() => setSmsModalOpen(false)}
              disabled={modalLoading}
            >
              Cancel
            </button>
          </>
        ) : (
          <p className="modal-subtitle">
            Please add a phone number to {trainee.name}
            before sending an SMS action reminder
          </p>
        )}
      </Modal>
      {/* Custom Message Modal */}
      <Modal
        styles={modalStyles}
        open={customModalOpen}
        onClose={() => setCustomModalOpen(false)}
        center
        focusTrapped={true}
      >
        <h5 className="modal-title">
          Send a custom message to <b>{trainee.name}</b>{" "}
        </h5>
        <hr />
        {trainee.phone_number && trainee.phone_number.length > 0 ? (
          <>
            <div style={{ marginBottom: "1rem" }}>
              <textarea
                rows={4}
                style={{ width: "100%", padding: "0.5rem" }}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
                maxLength={450}
              />
              <p
                style={{
                  float: "right",
                  marginTop: "-5px",
                  fontSize: "0.70rem",
                  color: "gray",
                  marginBottom: 0,
                }}
              >
                {customMessage.length}/450
              </p>
            </div>
            {modalError && (
              <div className="h6 text-center text-danger">{modalError}</div>
            )}
            <button
              className="default-button float-right"
              style={{ marginLeft: "0.5rem" }}
              onClick={handleSendCustomMessage}
              disabled={modalLoading}
            >
              Send
            </button>
            <button
              className="default-button default-button-gray float-right"
              onClick={() => setCustomModalOpen(false)}
              disabled={modalLoading}
            >
              Cancel
            </button>
          </>
        ) : (
          <p className="modal-subtitle">
            Please add a phone number to {trainee.name}
            before sending a custom message
          </p>
        )}
      </Modal>
      {/* Move Trainee Modal */}
      {/*<Modal*/}
      {/*  styles={modalStyles}*/}
      {/*  open={moveModalOpen}*/}
      {/*  onClose={() => setMoveModalOpen(false)}*/}
      {/*  center*/}
      {/*  focusTrapped={false}*/}
      {/*>*/}
      {/*  <h5 className="modal-title">Move Trainee</h5>*/}
      {/*  <hr />*/}
      {/*  <p className="modal-subtitle">*/}
      {/*    Move <b>{trainee.name}</b> into*/}
      {/*  </p>*/}
      {/*  <form onSubmit={handleMoveTrainee}>*/}
      {/*    <select*/}
      {/*      value={moveShop}*/}
      {/*      required*/}
      {/*      onChange={(e) => setMoveShop(e.target.value)}*/}
      {/*      className="custom-select"*/}
      {/*    >*/}
      {/*      <option value="">Choose...</option>*/}
      {/*      {allTeams.map((t) => {*/}
      {/*        if (t.code !== trainee.shop) {*/}
      {/*          return (*/}
      {/*            <option value={t.supervisorCode} key={t.supervisorCode}>*/}
      {/*              {t.title}*/}
      {/*            </option>*/}
      {/*          )*/}
      {/*        }*/}
      {/*        return <></>*/}
      {/*      })}*/}
      {/*    </select>*/}
      {/*    <br />*/}
      {/*    {modalError && (*/}
      {/*      <div className="h6 text-center text-danger">{modalError}</div>*/}
      {/*    )}*/}
      {/*    <button*/}
      {/*      className="default-button float-right"*/}
      {/*      style={{ marginLeft: "0.5rem" }}*/}
      {/*      disabled={modalLoading}*/}
      {/*    >*/}
      {/*      Move Trainee*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      className="default-button default-button-gray float-right"*/}
      {/*      onClick={() => setMoveModalOpen(false)}*/}
      {/*      disabled={modalLoading}*/}
      {/*      type="reset"*/}
      {/*    >*/}
      {/*      Cancel*/}
      {/*    </button>*/}
      {/*  </form>*/}
      {/*</Modal>*/}
      {/* Move Trainee Success Modal */}
      {/*<Modal*/}
      {/*  styles={modalStyles}*/}
      {/*  open={moveSuccessModalOpen}*/}
      {/*  onClose={() => setMoveSuccessModalOpen(false)}*/}
      {/*  center*/}
      {/*  focusTrapped={false}*/}
      {/*>*/}
      {/*  <h5 className="modal-title">Move Trainee Success</h5>*/}
      {/*  <hr />*/}
      {/*  <p className="modal-subtitle">*/}
      {/*    You have successfully moved <b>{trainee.name}</b>*/}
      {/*  </p>*/}
      {/*</Modal>*/}
      <div>
        <div className="card">
          <div
            className="card-header"
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div>
              <button
                className={"default-button"}
                onClick={() => {
                  setModalLoading(false)
                  setModalError("")
                  setSmsModalOpen(true)
                }}
              >
                <FontAwesomeIcon icon={faBell} /> Send Action Reminder
              </button>
              <button
                className={"default-button"}
                onClick={() => {
                  setModalLoading(false)
                  setModalError("")
                  setCustomModalOpen(true)
                  setCustomMessage("")
                }}
              >
                <FontAwesomeIcon icon={faCommentDots} /> Send Custom Message
              </button>
            </div>
            <div>
              {/*<button*/}
              {/*  className="default-button"*/}
              {/*  onClick={() => {*/}
              {/*    setModalLoading(false)*/}
              {/*    setModalError("")*/}
              {/*    setMoveShop("")*/}
              {/*    setMoveModalOpen(true)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <FontAwesomeIcon icon={faUserFriends} /> Move Trainee*/}
              {/*</button>*/}
              {/*<button*/}
              {/*  className="default-button"*/}
              {/*  onClick={() => {*/}
              {/*    setModalLoading(false)*/}
              {/*    setModalError("")*/}
              {/*    setMergeTrainee("")*/}
              {/*    setMergeModalOpen(true)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <FontAwesomeIcon icon={faUserFriends} /> Merge Trainee*/}
              {/*</button>*/}
              <button
                className="default-button default-button-red"
                onClick={() => {
                  setDeleteModalOpen(true)
                  setModalError("")
                  setModalLoading(false)
                }}
              >
                <FontAwesomeIcon icon={faUserSlash} /> Delete Trainee
              </button>
            </div>
          </div>
          <TraineeOverview
            trainee={trainee}
            getInfo={getInfo}
            // availability={availability}
            // availabilityLoading={availabilityLoading}
            // documents={documents}
            // documentLoading={documentLoading}
            // getTraineeDocuments={getTraineeDocuments}
            // notes={notes}
            // setNotes={setNotes}
            messaging={messaging}
            // completedTasks={completedTasks}
            // setCompletedTasks={setCompletedTasks}
          />
        </div>
      </div>
    </div>
  )
}

export default Profile

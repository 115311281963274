import React from "react"

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <footer className="footer">
      <div>
        <span>© {year} Paratus Inc</span>
      </div>
    </footer>
  )
}

export default Footer

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import NotifyTraineesCard from "../../components/notifyTraineesCard"

import axios from "axios"

// Phone number input
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhone,
  faCheck,
  faTimes,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"

// Redux
import { useSelector } from "react-redux"

// Helper Component
const CommunicationsTableEntry = ({ trainee, phoneEditState, getList }) => {
  const [phoneNumber, setPhoneNumber] = useState(trainee.phone_number)

  useEffect(() => {
    // Patch phone number changes if changes detected from original and submit button pushed
    if (phoneEditState === "submit") {
      // TODO: For future - phone number checking and error catching
      // Changes detected
      if (phoneNumber !== trainee.phone_number) {
        if (phoneNumber) {
          const body = {
            id: trainee.id,
            phone_number: phoneNumber,
            is_verified: false,
          }
          axios
            .patch("/edit-trainee/", body)
            .then(() => {
              getList()
            })
            .catch((err) => {
              console.log(err)
            })
        }

        // Change to blank phone number if empty
        else {
          const body = {
            id: trainee.id,
            phone_number: "",
            is_verified: false,
          }

          axios
            .patch("/edit-trainee", body)
            .then((res) => {
              getList()
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }

    // Reset input values if cancel is pressed
    if (phoneEditState === "cancel") {
      setPhoneNumber(trainee.phone_number)
    }
  }, [phoneEditState])

  const color =
    trainee.compliance_perc == 100
      ? "var(--green)"
      : trainee.compliance_perc < 75
        ? "var(--red)"
        : "var(--yellow)"

  return (
    <tr>
      <td>{trainee.id_number}</td>
      <td>{trainee.name}</td>

      <td style={{ color: color }}>
        <b>{trainee.compliance_perc.toFixed()}%</b>
      </td>

      {phoneEditState === "edit" ? (
        <td>
          <PhoneInput
            style={{}}
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="US"
          />
        </td>
      ) : (
        <td>{trainee.phone_number}</td>
      )}
      <td>
        {trainee.is_verified && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: "var(--green)" }}
          />
        )}
        {trainee.phone_number && !trainee.is_verified && (
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ color: "var(--red)" }}
          />
        )}
      </td>
    </tr>
  )
}

// Main Component
const Communications = () => {
  const [loadingList, setLoadingList] = useState(false)
  const [loadingLog, setLoadingLog] = useState(false)
  const [notificationsList, setNotificationsList] = useState([])
  const [notificationsLog, setNotificationsLog] = useState([])

  // idle, edit, cancel, or save
  const [phoneEditState, setPhoneEditState] = useState("idle")

  //search states
  const [q, setQ] = useState("")
  const searchParam = [
    "id",
    "name",
    "num_tasks_overdue",
    "num_tasks_due",
    "num_tasks_completed",
    "id_number",
    "org",
    "phone_number",
    "is_verified",
  ]
  const user = useSelector((state) => state.user)

  const getList = () => {
    setLoadingList(true)
    axios
      .get(`/trainees-overview/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setNotificationsList(res.data.sort(compTrainee))
        setLoadingList(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // const getNotificationsLog = () => {
  //   axios
  //     .get(`/get-notificationsLog/${user.currentFocusedTeam}`)
  //     .then((res) => {
  //       setNotificationsLog(res.data)
  //       setLoadingLog(false)
  //     })
  //     .catch((err) => {
  //       setLoadingLog(false)
  //       console.log(err)
  //     })
  // }

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam) {
        setLoadingList(true)
        setLoadingLog(true)
        // Get Log
        // getNotificationsLog()

        // Get List
        getList()
      }
    }
  }, [user.currentFocusedTeam, user.status])

  // Get list function to be called once phone numbers are saved

  const compTrainee = (a, b) => {
    return a.name > b.name ? 1 : -1
  }

  // Get notifications log function for when a new SMS is sent

  if (
    loadingList
    // || loadingLog
  ) {
    return <LoadingDashboard />
  }

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((searchP) => {
        if (item[searchP]) {
          return item[searchP]
            .toString()
            .toLowerCase()
            .includes(q.toLowerCase())
        }
        return false
      })
    })
  }

  return (
    <div>
      <div style={{ margin: ".25rem 0" }}>
        <div className="row">
          <NotifyTraineesCard
            notificationsList={notificationsList}
            // getNotificationsLog={getNotificationsLog}
          />
        </div>
      </div>

      {phoneEditState !== "edit" ? (
        <button
          className="default-button"
          onClick={() => setPhoneEditState("edit")}
        >
          <FontAwesomeIcon icon={faPhone} /> Edit Phone Numbers
        </button>
      ) : (
        <>
          <button
            className="default-button default-button-red"
            onClick={() => setPhoneEditState("cancel")}
            style={{ marginRight: "0.5rem" }}
          >
            <FontAwesomeIcon icon={faTimes} /> Cancel
          </button>
          <button
            className="default-button"
            onClick={() => setPhoneEditState("submit")}
          >
            <FontAwesomeIcon icon={faCheck} /> Save
          </button>
        </>
      )}

      <div className="search-wrapper" style={{ display: "inline-block" }}>
        <label htmlFor="search-form">
          <input
            style={{ marginBottom: "16px" }}
            type="search"
            name="search-form"
            id="search-form"
            className="search-input textInput form-control"
            placeholder="Search for..."
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
          <span className="sr-only">Search names here</span>
        </label>
      </div>

      {/* Notifications list of trianees */}
      <table className="table table-hover table-responsive text-center">
        <thead className="thead-light">
          <tr>
            <th scope="col">EMP #</th>
            <th scope="col">Full Name</th>
            <th scope="col">Readiness %</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Verified?</th>
          </tr>
        </thead>
        <tbody>
          {search(notificationsList).map((trainee, ti) => {
            return (
              <CommunicationsTableEntry
                key={ti}
                trainee={trainee}
                phoneEditState={phoneEditState}
                getList={getList}
              />
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default Communications

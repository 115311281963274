import React, { useEffect, useState } from "react"

import { Link, NavLink } from "react-router-dom"

import axios from "axios"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { getProfile } from "../redux/userSlice"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAddressBook,
  faArchive,
  faCalendarAlt,
  faFileExcel,
  faLock,
  faTachometerAlt,
} from "@fortawesome/free-solid-svg-icons"
import Logo from "../images/logo.png"
import LogoSmall from "../images/logo-small.png"

const Header = (props) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)

  return (
    <header className="header  d-flex justify-content-between align-items-center w-100">
      <div className="d-flex h-100 align-items-center">
        <div style={{ padding: "0.4rem", height: "90%" }}>
          <Link to="/home">
            <img src={Logo} className="logo" alt="EzTrain Logo" />
          </Link>
          <Link to="/home">
            <img
              src={LogoSmall}
              className="logo logo-small"
              alt="EzTrain Logo"
            />
          </Link>
        </div>

        <NavLink className="nav-link-brand" to="/home">
          {/*<FontAwesomeIcon icon={faTachometerAlt} className="sidebar-icon" />*/}
          Home
        </NavLink>

        <NavLink className="nav-link-brand" to="/communications">
          {/*<FontAwesomeIcon icon={faAddressBook} className="sidebar-icon" />*/}
          Chat
        </NavLink>

        <NavLink className="nav-link-brand" to="/tasks">
          {/*<FontAwesomeIcon icon={faArchive} className="sidebar-icon" />*/}
          Analyze
        </NavLink>

        <NavLink className="nav-link-brand" to="/events">
          {/*<FontAwesomeIcon icon={faCalendarAlt} className="sidebar-icon" />*/}
          Schedule
        </NavLink>

        <NavLink className="nav-link-brand " to="/mission-requirements">
          {/*<FontAwesomeIcon icon={faFileExcel} className="sidebar-icon" />*/}+
          Create
        </NavLink>
      </div>

      <Link
        to="/profile"
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <div>
          <p style={{ margin: 0 }}>{user.username}</p>
        </div>
      </Link>
    </header>
  )
}

export default Header

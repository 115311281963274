import React, { useCallback, useEffect, useRef, useState } from "react"

import { json, Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import useIsomorphicLayoutEffect from "use-isomorphic-layout-effect"

import { renderToString } from "react-dom/server"
import ProgressBar from "../../components/progressBar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBell,
  faPlaneDeparture,
  faExternalLinkAlt,
  faCommentDots,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { select } from "d3"
import { setErrors, setTeam, setTeamID } from "../../redux/userSlice"

import axios from "axios"
import LoadingDashboard from "../../components/loadingDashboard"
import DefaultLayout from "../../layouts/defaultLayout"
import { navigate } from "react-big-calendar/lib/utils/constants"

function TreeNode({ node, setOrg, selected, setParentExpand, checkboxes }) {
  const {
    children,
    name,
    id,
    accessible,
    // mission_groups
  } = node

  const [showChildren, setShowChildren] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const handleExpandClick = () => {
    setShowChildren(!showChildren)
  }

  const handleNameClick = () => {
    const copyNode = { ...node }
    delete copyNode["children"]
    setOrg(node)
  }

  useEffect(() => {
    if (selected.map((o) => o.id).includes(id)) {
      if (setParentExpand) {
        setParentExpand(true)
      }

      setIsSelected(true)
      if (!showChildren) {
        setShowChildren(true)
      }
    } else {
      setIsSelected(false)
    }
  }, [selected, id, name, setParentExpand])

  return (
    <>
      <div className="d-flex flex-row align-items-center p-1">
        {children ? (
          <FontAwesomeIcon
            icon={faChevronRight}
            className="sidebar-icon "
            onClick={handleExpandClick}
            style={!showChildren ? {} : { transform: "rotate(90deg)" }}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            className="sidebar-icon"
            style={{ visibility: "hidden" }}
          />
        )}
        {accessible ? (
          <div onClick={handleNameClick}>
            {checkboxes && (
              <input
                type="checkbox"
                checked={isSelected}
                onChange={handleNameClick}
              />
            )}

            <span style={isSelected ? { color: "var(--green)" } : {}}>
              {name}
            </span>
          </div>
        ) : (
          <div>
            <span style={{ color: "var(--gray-600" }}>{name}</span>
          </div>
        )}
      </div>

      {children && (
        <ul
          style={{
            paddingLeft: "1rem",
            borderLeft: "1px solid var(--gray-600)",
            display: showChildren ? "inherit" : "none",
          }}
        >
          <Tree
            treeData={children}
            setOrg={setOrg}
            selected={selected}
            setParentExpand={setShowChildren}
            checkboxes={checkboxes}
          />
        </ul>
      )}

      {/*{mission_groups && mission_groups.length > 0 && (*/}
      {/*  <div>*/}
      {/*    <hr />*/}
      {/*    <h5>Mission Groups</h5>*/}
      {/*    {mission_groups.map((mg) => (*/}
      {/*      <TreeNode*/}
      {/*        node={mg}*/}
      {/*        setOrg={setOrg}*/}
      {/*        key={mg.name}*/}
      {/*        selected={selected}*/}
      {/*        setParentExpand={setShowChildren}*/}
      {/*        checkboxes={checkboxes}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  )
}

function Tree({ treeData, setOrg, selected, setParentExpand, checkboxes }) {
  return (
    <ul
      style={{
        padding: "0",
        margin: "0",
      }}
    >
      {treeData.map((node) => (
        <TreeNode
          node={node}
          setOrg={setOrg}
          key={node.name}
          selected={selected}
          setParentExpand={setParentExpand}
          checkboxes={checkboxes}
        />
      ))}
    </ul>
  )
}

const flattenMissionGroups = (nodes) => {
  const compiledMGS = nodes.reduce(
    (prev, v) => [
      ...prev,
      ...(v.children ? flattenMissionGroups(v.children) : []),
      ...(v.mission_groups ? v.mission_groups : []),
    ],
    []
  )
  return compiledMGS
}

const OrgChartView = ({ onSelect, checkboxes, selected }) => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [orgChartData, setOrgChartData] = useState([])
  const [missionGroups, setMissionGroups] = useState([])
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  useEffect(() => {
    if (user.status === "success") {
      setLoading(true)

      axios
        .get(`/org-chart/`)
        // .get(`/org-chart`, { baseURL: "http://localhost:8000" })
        .then((res) => {
          setOrgChartData(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
          dispatch(
            setErrors([
              "An unexpected error has occurred, please reload the page",
            ])
          )
          // setLoading(StatusEnum.error)
        })
    }
  }, [dispatch, user.status])

  useEffect(() => {
    if (orgChartData.length > 0) {
      setMissionGroups(flattenMissionGroups(orgChartData))
    }
  }, [orgChartData])

  if (loading) {
    return <div />
  }

  return (
    <div
      className="d-flex flex-column h-100 overflow-scroll disable-scrollbars"
      style={{
        padding: "1.25rem",
        margin: "0",
      }}
    >
      <div className="overflow-auto">
        <Tree
          treeData={orgChartData}
          setOrg={onSelect}
          selected={selected}
          checkboxes={checkboxes}
        />
      </div>

      {missionGroups.length > 0 && (
        <>
          <hr />

          <h5>Mission Groups</h5>
          {missionGroups.map((v) => (
            <TreeNode
              node={v}
              setOrg={onSelect}
              key={v.name}
              selected={selected}
              checkboxes={checkboxes}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default OrgChartView

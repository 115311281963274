import React, { useCallback, useEffect, useState } from "react"
import Select from "react-select"
import DefaultLayout from "../../layouts/defaultLayout"
import SmallLoader from "../../components/smallLoader"
import ProgressBar from "../../components/progressBar"
import LoadingDashboard from "../../components/loadingDashboard"

import Modal from "react-responsive-modal"
import { modalStyles, modalStylesWarning } from "../../styles/constanStyles"
// Removed confetti due to user complaints that it lags the site
// import Confetti from "react-confetti"
import axios from "axios"

// REDUX
import { useSelector } from "react-redux"

// ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowRight,
  faCheck,
  faCheckCircle,
  faChevronRight,
  faCog,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons"
import { NONE } from "react-big-calendar/lib/utils/Resources"

import TabbedGroupedSelect from "../../components/groupedSelect"
import { useNavigate } from "react-router"
import { Label } from "recharts"
import StatusEnum from "../../util/StatusEnum"
import { ScaleLoader } from "react-spinners"


// Dropdown Selector
// Used in a generic manner

const DisplayBread = ({ breadCrumbs }) => {
  return (
    <div className="d-flex flex-row gap-2 align-items-center">
      {breadCrumbs}
    </div>
  )
}
const FormField = ({ title, value, type, setter }) => (
  <>
    {title}
    <div className="input-group ">
      <input
        style={{ color: "rgba(100,100,100,0.8)" }}
        type={type}
        className="textinput textInput form-control"
        value={value}
        onChange={(v) => {
          setter(v.target.value)
        }}
      />
    </div>
  </>
)

const arrow = <FontAwesomeIcon icon={faArrowRight} />

const TaskTable = ({ title, data, color }) => {
  return (
    <>
      <h3>{title}</h3>
      <div
        style={{
          maxHeight: "55rem",
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <table
          className="table table-hover table-responsive text-center"
          // style={{ tableLayout: "fixed" }}
        >
          <thead className="thead-light">
            <tr>
              {Object.keys(data[0]).map((v) => (
                <th scope="col">{v}</th>
              ))}
            </tr>
          </thead>

          <tbody style={{ color: color }}>
            {data.map((row) => {
              return (
                <tr>
                  {Object.values(row).map((v) => (
                    <td
                    // style={{ maxWidth: "2.5rem", minWidth: "2rem" }}
                    >
                      {v}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

const TaskTableDispl = ({ task }) => {
  return (
    <>
      {Object.entries(task).map(([key, value]) => {
        return value ? (
          <div className="d-flex flex-row gap-2">
            <label>{key}</label> : <label>{value}</label>
          </div>
        ) : (
          <></>
        )
      })}
    </>
  )
}

const TaskPreview = ({ tasks, org_id, parentBreadCrumbs }) => {
  const navigate = useNavigate()

  const newT = tasks.new ? tasks.new : []
  const failsT = tasks.fails ? tasks.fails : []
  const matchingT = tasks.matching ? tasks.matching : []
  const missingT = tasks.missing ? tasks.missing : []
  const conflictT = tasks.conflict ? tasks.conflict : []

  const options = []

  if (failsT.length > 0) {
    options.push("Failed")
  }
  if (newT.length > 0) {
    options.push("New")
  }
  if (matchingT.length > 0) {
    options.push("Existing")
  }
  if (missingT.length > 0) {
    options.push("Completed")
  }
  if (conflictT.length > 0) {
    options.push("Conflicting")
  }

  const [currentView, setCurrentView] = useState(options[0])

  const [failedAction, setFailedAction] = useState(true)
  const [newAction, setNewAction] = useState(newT.length === 0)
  const [matchedAction, setMatchedAction] = useState(matchingT.length === 0)
  const [missingAction, setMissingAction] = useState(missingT.length === 0)
  const [conflictAction, setConflictAction] = useState(conflictT.length === 0)
  const [showDoneModal, setShowDoneModal] = useState(false)

  useEffect(() => {
    if (
      failedAction &&
      newAction &&
      matchedAction &&
      missingAction &&
      conflictAction
    ) {
      setShowDoneModal(true)
    }
  }, [
    conflictAction,
    failedAction,
    matchedAction,
    missingAction,
    navigate,
    newAction,
  ])

  const breadCrumbs = [
    ...parentBreadCrumbs,
    arrow,
    <div
      className="breadCrumb"
      // onClick={() => setTaskPreview({})}
    >
      Preview
    </div>,
  ]

  const [createdTasksNum, setCreatedTasksNum] = useState(0)
  const [completedTasksNum, setcompletedTasksNum] = useState(0)
  const [conflictTasksNum, setConflicTasksNum] = useState(0)

  const handleNewSubmit = () => {
    const body = newT.map((v) => v.task_obj)
    axios
      .post(`/create-tasks/?org_id=${org_id}`, body)
      .then((res) => {
        setNewAction(true)
        setCreatedTasksNum(createdTasksNum + body.length)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleCreateExisting = () => {
    const body = matchingT.map((v) => {
      const tO = v.task_obj
      delete tO["id"]
      return tO
    })

    axios
      .post(`/create-tasks/?org_id=${org_id}`, body)
      .then((res) => {
        setMatchedAction(true)
        setCreatedTasksNum(createdTasksNum + body.length)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleComplete = () => {
    const body = missingT.map((v) => v.task_obj.id)

    axios
      .post(`/complete-tasks/?org_id=${org_id}`, body)
      .then((res) => {
        setMissingAction(true)
        setcompletedTasksNum(completedTasksNum + body.length)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleUpdate = () => {
    const body = conflictT.map((v) => v.task_obj)

    axios
      .post(`/update-tasks/?org_id=${org_id}`, body)
      .then((res) => {
        setConflictAction(true)
        setCreatedTasksNum(conflictTasksNum + body.length)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <DisplayBread breadCrumbs={breadCrumbs} />

      <Modal
        styles={modalStyles}
        open={showDoneModal}
        onClose={() => setShowDoneModal(false)}
        showCloseIcon={true}
        center
        focusTrapped={false}
      >
        <h3>Done Parsing Items</h3>
        <h4> Created {createdTasksNum} tasks </h4>

        <h4>Marked {completedTasksNum} tasks complete </h4>

        <h4>Modified {conflictTasksNum} conflicting tasks</h4>
        <button className="default-button" onClick={() => navigate("/home")}>
          Navigate Home
        </button>
      </Modal>

      <div className="d-flex" style={{ padding: ".4rem" }}>
        <button
          className={
            !options.includes("Failed")
              ? "default-button on-bar default-button-disabled"
              : currentView === "Failed"
                ? "default-button on-bar default-button-active"
                : "default-button on-bar"
          }
          style={{
            borderRadius: "0.3rem 0 0 0.3rem ",
            // borderRight: "none",
          }}
          disabled={!options.includes("Failed")}
          onClick={() => setCurrentView("Failed")}
        >
          Failed{" "}
          {!failedAction ? (
            <FontAwesomeIcon icon={faCog} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
        <button
          className={
            !options.includes("New")
              ? "default-button on-bar default-button-disabled"
              : currentView === "New"
                ? "default-button on-bar default-button-active"
                : "default-button on-bar"
          }
          // style={{ borderRight: "none" }}
          disabled={!options.includes("New")}
          onClick={() => setCurrentView("New")}
        >
          New{" "}
          {!newAction ? (
            <FontAwesomeIcon icon={faCog} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
        <button
          className={
            !options.includes("Existing")
              ? "default-button on-bar default-button-disabled"
              : currentView === "Existing"
                ? "default-button on-bar default-button-active"
                : "default-button on-bar"
          }
          // style={{ borderRight: "none" }}
          disabled={!options.includes("Existing")}
          onClick={() => setCurrentView("Existing")}
        >
          Existing{" "}
          {!matchedAction ? (
            <FontAwesomeIcon icon={faCog} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
        <button
          className={
            !options.includes("Completed")
              ? "default-button on-bar default-button-disabled"
              : currentView === "Completed"
                ? "default-button on-bar default-button-active"
                : "default-button on-bar"
          }
          // style={{ borderRight: "none" }}
          disabled={!options.includes("Completed")}
          onClick={() => setCurrentView("Completed")}
        >
          Completed{" "}
          {!missingAction ? (
            <FontAwesomeIcon icon={faCog} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>

        <button
          className={
            !options.includes("Conflicting")
              ? "default-button on-bar default-button-disabled"
              : currentView === "Conflicting"
                ? "default-button on-bar default-button-active"
                : "default-button on-bar"
          }
          disabled={!options.includes("Conflicting")}
          style={{ borderRadius: "0 0.3rem 0.3rem 0" }}
          onClick={() => setCurrentView("Conflicting")}
        >
          Conflicting{" "}
          {!conflictAction ? (
            <FontAwesomeIcon icon={faCog} />
          ) : (
            <FontAwesomeIcon icon={faCheck} />
          )}
        </button>
      </div>

      {currentView === "New" && (
        <div className="d-flex flex-row gap-5">
          <div className="col-8">
            <TaskTable
              title={"Successfully Parsed"}
              data={newT.map((v) => {
                return {
                  ...v.row,
                  Task: <TaskTableDispl task={v.task_display} />,
                }
              })}
              color={"green"}
            />
          </div>
          <div className="align-content-center">
            <div className="vertical-divider h-75"></div>
          </div>
          <div className="align-content-center">
            <h5>
              Create new tasks, make sure to check name, date, and task type
              parsing
            </h5>
            <div className="d-flex flex-row">
              <button
                className={
                  newAction
                    ? "default-button default-button-disabled"
                    : "default-button"
                }
                onClick={handleNewSubmit}
                disabled={newAction}
              >
                Approve Tasks
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "Existing" && (
        <div className="d-flex flex-row gap-5">
          <div className="col-8">
            <TaskTable
              title={"Found Match"}
              data={matchingT.map((v) => {
                return {
                  ...v.row,
                  Task: <TaskTableDispl task={v.task_display} />,
                }
              })}
              color={"green"}
            />
          </div>
          <div className="align-content-center">
            <div className="vertical-divider h-75"></div>
          </div>
          <div className="align-content-center">
            <h5>Create potentially duplicate tasks or ignore</h5>
            <div className="d-flex flex-row">
              <button
                className={
                  matchedAction
                    ? "default-button default-button-disabled"
                    : "default-button"
                }
                onClick={handleCreateExisting}
                disabled={matchedAction}
              >
                Create Tasks
              </button>
              <button
                className={
                  matchedAction
                    ? "default-button default-button-disabled"
                    : "default-button"
                }
                onClick={() => setMatchedAction(true)}
                disabled={matchedAction}
              >
                Ignore
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "Conflicting" && (
        <div className="d-flex flex-row gap-5">
          <div className="col-8">
            <TaskTable
              title={"Found Conflict"}
              data={conflictT.map((v) => {
                return {
                  ...v.row,
                  Task: <TaskTableDispl task={v.task_display} />,
                  Reason: v.reason,
                }
              })}
              color={"green"}
            />
          </div>
          <div className="align-content-center">
            <div className="vertical-divider h-75"></div>
          </div>
          <div className="align-content-center">
            <h5>Change to new sheet values or ignore</h5>
            <div className="d-flex flex-row">
              <button
                className={
                  conflictAction
                    ? "default-button default-button-disabled"
                    : "default-button"
                }
                onClick={handleUpdate}
                disabled={conflictAction}
              >
                Approve Changes
              </button>
              <button
                className={
                  conflictAction
                    ? "default-button default-button-disabled"
                    : "default-button"
                }
                onClick={() => setConflictAction(true)}
                disabled={conflictAction}
              >
                Ignore
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "Completed" && (
        <div className="d-flex flex-row gap-5">
          <div className="col-8">
            <TaskTable
              title={"Tasks to Delete"}
              data={missingT.map((v) => {
                return { ...v.task_display }
              })}
              color={"green"}
            />
          </div>
          <div className="align-content-center">
            <div className="vertical-divider h-75"></div>
          </div>
          <div className="align-content-center">
            <h5>Approve marking tasks completed or ignore</h5>
            <div className="d-flex flex-row">
              <button
                className={
                  missingAction
                    ? "default-button default-button-disabled"
                    : "default-button"
                }
                onClick={handleComplete}
                disabled={missingAction}
              >
                Approve
              </button>
              <button
                className={
                  missingAction
                    ? "default-button default-button-disabled"
                    : "default-button"
                }
                onClick={() => setMissingAction(true)}
                disabled={missingAction}
              >
                Ignore
              </button>
            </div>
          </div>
        </div>
      )}
      {currentView === "Failed" && (
        <div className="d-flex flex-row gap-5">
          <div className="col-8">
            <TaskTable
              title={"Failed To Parsed"}
              data={failsT.map((v) => {
                return { ...v }
              })}
              color={"red"}
            />
          </div>
          <div className="align-content-center">
            <div className="vertical-divider h-75"></div>
          </div>
          <div className="align-content-center">
            <h5 className="align-content-center">
              Update excel sheet for remediation or create tasks manually.
              In-app remediation planned for future product release
            </h5>
            {/*<button*/}
            {/*  className={*/}
            {/*    failedAction*/}
            {/*      ? "default-button default-button-disabled"*/}
            {/*      : "default-button"*/}
            {/*  }*/}
            {/*  onClick={() => setFailedAction(true)}*/}
            {/*  disabled={failedAction}*/}
            {/*>*/}
            {/*  Acknowledge*/}
            {/*</button>*/}
          </div>
        </div>
      )}
    </div>
  )
}

const Parser = ({ selectedSheet, org_id, parentBreadCrumbs }) => {
  const [selectedSheetData, setSelectedSheetData] = useState({})
  const [headerRows, setHeaderRows] = useState(0)
  const [taskTypeOptions, setTaskTypeOptions] = useState([])

  const [joinHeaders, setJoinHeaders] = useState({})
  const [dueDateHeader, setDueDateHeader] = useState(null)
  const [descriptionHeader, setDescriptionHeader] = useState(null)
  const [dateOffset, setDateOffset] = useState(0)
  const [idOptions, setIdOptions] = useState([])
  const [imr, setImr] = useState(false)

  const [taskDropdown, setTaskDropdown] = useState(null)
  const [taskTypeCol, setTaskTypeCol] = useState(null)
  const [taskTypeId, setTaskTypeId] = useState(null)
  const [taskTypeMappings, setTaskTypeMappings] = useState(null)

  const [completeDateHeader, setCompleteDateHeader] = useState(null)

  const [taskPreview, setTaskPreview] = useState({})
  const [error, setError] = useState("")
  const [openControl, setOpenControl] = useState("setup_table")

  const [completedTaskType, setCompletedTaskType] = useState(false)
  const [completedDates, setCompletedDates] = useState(false)
  const [completedLinkT, setCompletedLinkT] = useState(false)

  const breadCrumbs = [
    ...parentBreadCrumbs,
    arrow,
    <div className="breadCrumb" onClick={() => setTaskPreview({})}>
      Sheet Settings
    </div>,
  ]

  const handleTaskTypeSelect = (val) => {
    setTaskDropdown(val)
    if (val.type === "task_type") {
      setTaskTypeId(val.value)
      setTaskTypeMappings(null)
      setTaskTypeCol(null)
    } else {
      setTaskTypeId(null)
    }
  }

  const handleTaskCatSelect = (val) => {
    if (val.id) {
      setTaskDropdown(val)
      setTaskTypeId(null)
    }
  }
  const handleCreateNewTask = (v) => {
    let body = {
      name_short: v.row_value,
      description: v.row_value,
      name_long: v.row_value,
      category_tag_id: taskDropdown.id,
      parent_organization_id: org_id,
    }
    axios
      .post(`/create_task_type/`, body)
      .then((res) => {
        handleTaskTypeMappingChange(v, {
          label: res.data.name_short,
          value: res.data.id,
        })
      })
      .catch((err) => {
        console.log(err)
        setError(JSON.stringify(err.response.data))
      })
  }

  useEffect(() => {
    if (taskDropdown && taskDropdown.type === "category" && taskTypeCol) {
      const body = {
        task_cat_id: taskDropdown.id,
        task_type_header: taskTypeCol.value,
        file_id: selectedSheet.id,
        org_id: org_id,
        skip_rows: headerRows,
      }
      axios
        .post(`/get-task-type-mappings/`, body)
        .then((res) => {
          setTaskTypeMappings(res.data)
        })
        .catch((err) => {
          console.log(err)
          setError(JSON.stringify(err.response.data))
        })
    }
  }, [headerRows, org_id, selectedSheet.id, taskDropdown, taskTypeCol])

  const handleHeaderSelectionChange = (selectedOptions) => {
    const selectedHeadersSet = new Set(
      selectedOptions?.map((opt) => opt.value) || []
    )
    const updatedHeaders = { ...joinHeaders }

    // Remove mappings for any headers that were deselected
    for (const key of Object.keys(updatedHeaders)) {
      if (!selectedHeadersSet.has(key)) {
        delete updatedHeaders[key]
      }
    }

    // Add mappings for newly selected headers if not already mapped
    selectedOptions?.forEach((option) => {
      if (!(option.value in updatedHeaders)) {
        updatedHeaders[option.value] = { column: option, mapping: null } // Initialize with null or default
      }
    })

    setJoinHeaders(updatedHeaders)
  }

  // Handle individual header mapping change
  const handleMappingChange = (header, selectedOption) => {
    const updatedHeaders = { ...joinHeaders }
    updatedHeaders[header].mapping = selectedOption
    setJoinHeaders(updatedHeaders)
  }
  const handleTaskTypeMappingChange = (v, selected) => {
    const updatedOptions = [...taskTypeMappings]
    updatedOptions.find((obj) => obj.row_value === v.row_value).task_type =
      selected
    setTaskTypeMappings(updatedOptions)
  }

  const getSheetData = useCallback(() => {
    axios
      .get(
        `/get-upload-table-preview/?file_id=${selectedSheet.id}&header_offset=${headerRows}`
      )
      .then((res) => {
        setSelectedSheetData(JSON.parse(res.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [headerRows, selectedSheet])

  useEffect(() => {
    if (selectedSheet) {
      getSheetData()
    }
  }, [selectedSheet, headerRows, getSheetData])

  const getTaskTypes = useCallback(() => {
    axios
      .get(`/get-task-types/?org_id=${org_id}`)
      .then((res) => {
        setTaskTypeOptions(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [org_id])
  useEffect(() => {
    if (selectedSheet) {
      getTaskTypes()
    }
  }, [getTaskTypes, selectedSheet])

  const getIdOptions = useCallback(() => {
    axios
      .get(`/get-join-column-options/`)
      .then((res) => {
        setIdOptions(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    if (selectedSheet) {
      getIdOptions()
    }
  }, [getIdOptions, selectedSheet])

  const uploadTasks = useCallback(() => {
    if (selectedSheet) {
      const body = {
        task_type_id: taskTypeId,
        task_type_header: taskTypeCol ? taskTypeCol.value : null,
        task_type_mappings: taskTypeMappings,
        task_cat_id:
          taskDropdown && taskDropdown.type === "category"
            ? taskDropdown.id
            : null,
        file_id: selectedSheet.id,
        org_id: org_id,
        join_headers: Object.values(joinHeaders).map((v) => {
          return {
            column: v.column.value,
            schema_value: v.mapping ? v.mapping.value : null,
          }
        }),
        due_date_header: dueDateHeader ? dueDateHeader.value : null,
        skip_rows: headerRows,

        days_offset: dateOffset,
        completion_date_header: completeDateHeader
          ? completeDateHeader.value
          : null,
        description_header: descriptionHeader ? descriptionHeader.value : null,
        imr: imr,
        timezone_offset: -new Date().getTimezoneOffset(),
      }
      axios
        .post(`/get-tasks-from-file/`, body)
        .then((res) => {
          setTaskPreview(res.data)
          setError("")
        })
        .catch((err) => {
          console.log(err)
          setError(JSON.stringify(err.response.data))
        })
    }
  }, [
    completeDateHeader,
    dateOffset,
    descriptionHeader,
    dueDateHeader,
    headerRows,
    imr,
    joinHeaders,
    org_id,
    selectedSheet,
    taskDropdown,
    taskTypeCol,
    taskTypeId,
    taskTypeMappings,
  ])

  const handleAutoParse = () => {
    if (selectedSheet) {
      const body = {
        file_id: selectedSheet.id,
        org_id: org_id,
        skip_rows: headerRows,
        timezone_offset: -new Date().getTimezoneOffset(),
      }
      axios
        .post(`/auto-parse-params/`, body)
        .then((res) => {
          if (Object.values(res.data).length > 0) {
            setTaskTypeId(res.data.task_type_id)
            setTaskTypeCol(
              res.data.task_type_header
                ? {
                    label: res.data.task_type_header,
                    value: res.data.task_type_header,
                  }
                : null
            )
            setTaskTypeMappings(res.data.task_type_mappings)
            if (res.data.task_cat_id) {
              setTaskDropdown(
                taskTypeOptions.find((obj) => obj.id === res.data.task_cat_id)
              )
            } else {
              setTaskDropdown(
                taskTypeOptions
                  .reduce((prev, current) => [...prev, ...current.options], [])
                  .find((obj) => obj.value === res.data.task_type_id)
              )
            }

            // setTaskTypeId(
            //   taskTypeOptions.find((obj) => obj.value === res.data.task_type_id)
            // )
            setJoinHeaders(
              res.data.join_headers.map((v) => {
                return {
                  column: { label: v.column, value: v.column },
                  mapping: idOptions.find(
                    (obj) => obj.value === v.schema_value
                  ),
                }
              })
            )

            setDueDateHeader({
              label: res.data.due_date_header,
              value: res.data.due_date_header,
            })
            setDateOffset(res.data.days_offset)

            setCompleteDateHeader({
              label: res.data.completion_date_header,
              value: res.data.completion_date_header,
            })

            setDescriptionHeader({
              label: res.data.description_header,
              value: res.data.description_header,
            })

            setImr(res.data.imr)

            // task_type_header: taskTypeCol ? taskTypeCol.value : null,
          }
        })
        .catch((err) => {
          console.log(err)
          setError(JSON.stringify(err.response.data))
        })
    }
  }

  useEffect(() => {
    if (
      taskTypeId ||
      (taskTypeCol &&
        taskTypeMappings &&
        taskTypeMappings.length > 0 &&
        taskTypeMappings.every((value) => value.task_type))
    ) {
      setCompletedTaskType(true)
    } else {
      setCompletedTaskType(false)
    }
  }, [taskTypeCol, taskTypeId, taskTypeMappings, taskDropdown])

  useEffect(() => {
    if (dueDateHeader) {
      setCompletedDates(true)
    } else {
      setCompletedDates(false)
    }
  }, [dueDateHeader])

  useEffect(() => {
    if (
      joinHeaders &&
      Object.values(joinHeaders).length > 0 &&
      Object.values(joinHeaders).every((v) => v.mapping)
    ) {
      setCompletedLinkT(true)
    } else {
      setCompletedLinkT(false)
    }
  }, [joinHeaders])

  return (
    <div>
      {/*{selectedSheet*/}
      {/*  ? `Viewing Selected Sheet: ${selectedSheet.display_name}`*/}
      {/*  : " Pick a sheet from the uploaded sheet table"}*/}

      {Object.keys(taskPreview).length > 0 ? (
        <TaskPreview
          tasks={taskPreview}
          org_id={org_id}
          parentBreadCrumbs={breadCrumbs}
        />
      ) : (
        <div>
          <DisplayBread breadCrumbs={breadCrumbs} />
          <br />
          <h3>{selectedSheet.display_name}</h3>
          {error && <div style={{ color: "red" }}>{error}</div>}
          {Object.keys(selectedSheetData).length > 0 && (
            <div className="flex-row d-flex gap-5">
              <div
                style={{ maxHeight: "60rem", overflow: "scroll" }}
                className="col-7"
              >
                <table className="table table-hover table-responsive text-center ">
                  <thead className="thead-light sticky">
                    <tr>
                      {selectedSheetData.schema.fields.map((v) => (
                        <th scope="col">{v.name}</th>
                      ))}
                      {/*<th scope="col">Sheet Name</th>*/}
                      {/*<th scope="col">Uploader</th>*/}
                      {/*<th scope="col">Upload Date</th>*/}
                      {/*<th scope="col">Parsed Status</th>*/}
                    </tr>
                  </thead>
                  <tbody>
                    {selectedSheetData.data.slice(0, 100).map((row) => {
                      return (
                        <tr>
                          {Object.values(row).map((v) => (
                            <td
                            // style={{ maxWidth: "2.5rem", minWidth: "2rem" }}
                            >
                              {v}
                            </td>
                          ))}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
              <div className="align-content-center">
                <div className="vertical-divider h-75"></div>
              </div>

              {/*Controls */}

              <div className="col-4">
                {/*Setup Table*/}
                <div className="card shadow">
                  <div
                    className="card-body d-flex flex-row align-items-center justify-content-between"
                    onClick={() =>
                      setOpenControl(
                        openControl === "setup_table" ? "" : "setup_table"
                      )
                    }
                  >
                    <h4>Setup Table</h4>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="sidebar-icon "
                      style={
                        openControl !== "setup_table"
                          ? {}
                          : { transform: "rotate(90deg)" }
                      }
                    />
                  </div>
                  {openControl === "setup_table" && (
                    <div className="p-3">
                      <label>
                        Specify how many rows to remove in order to parse the
                        correct column names.
                      </label>
                      <input
                        style={{
                          color: "rgba(100,100,100,0.8)",
                          width: "5rem",
                        }}
                        type="number"
                        className="textinput textInput form-control"
                        value={headerRows}
                        onChange={(v) => {
                          setHeaderRows(v.target.value)
                        }}
                      />
                      <br />
                      <label>
                        If the column names are properly found, paratus can try
                        to auto detect the remaining settings or the user can
                        manually supply all information.
                      </label>
                      <button
                        className="default-button "
                        onClick={handleAutoParse}
                      >
                        Try Auto Parse
                      </button>
                    </div>
                  )}
                </div>
                {/*Task Types*/}

                <div className="card shadow">
                  <div
                    className="card-body d-flex flex-row align-items-center justify-content-between"
                    onClick={() =>
                      setOpenControl(
                        openControl === "task_types" ? "" : "task_types"
                      )
                    }
                  >
                    <h4>
                      Define Task Type{" "}
                      {!completedTaskType ? (
                        <FontAwesomeIcon icon={faCog} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                    </h4>

                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="sidebar-icon "
                      style={
                        openControl !== "task_types"
                          ? {}
                          : { transform: "rotate(90deg)" }
                      }
                    />
                  </div>
                  {openControl === "task_types" && (
                    <div className="p-3">
                      <h5>Task Type:</h5>
                      Select the task type the sheet corresponds too. If the
                      sheet contains multiple task types, select the task type
                      category
                      <div style={{ width: "20rem" }}>
                        {/*<Select options={taskTypeOptions} />*/}

                        <TabbedGroupedSelect
                          onChange={handleTaskTypeSelect}
                          currentValue={taskDropdown}
                          groupedOptions={taskTypeOptions}
                          onGroupSelect={handleTaskCatSelect}
                        />
                      </div>
                      {taskDropdown && taskDropdown.type === "category" && (
                        <>
                          <label>
                            Select which column to use to link task types
                          </label>
                          <Select
                            options={selectedSheetData.schema.fields.map(
                              (v) => {
                                return {
                                  value: v.name,
                                  label: v.name,
                                }
                              }
                            )}
                            onChange={setTaskTypeCol}
                            value={taskTypeCol}
                          />

                          {taskTypeMappings && (
                            <>
                              Map row values to task types
                              <div
                                style={{
                                  maxHeight: "30rem",
                                  overflow: "scroll",
                                }}
                              >
                                <table className="table table-hover table-responsive text-center ">
                                  <thead
                                    className="thead-light"
                                    // style={{ zIndex: 1 }}
                                  >
                                    <tr>
                                      <th scope="col">Row Value</th>
                                      <th scope="col">Task Type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {taskTypeMappings.map((v) => {
                                      return (
                                        <tr>
                                          <td>{v.row_value}</td>
                                          <td>
                                            <Select
                                              value={v.task_type}
                                              isClearable={true}
                                              onChange={(e) =>
                                                handleTaskTypeMappingChange(
                                                  v,
                                                  e
                                                )
                                              }
                                              options={taskDropdown.options}
                                            />
                                            {!v.task_type && (
                                              <button
                                                className="default-button"
                                                onClick={() =>
                                                  handleCreateNewTask(v)
                                                }
                                              >
                                                Create New
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </>
                      )}
                      {/*<label>*/}
                      {/*  {"IMR FIX REPLACE WITH DELIOMTER"}*/}
                      {/*  <input*/}
                      {/*    type="checkbox"*/}
                      {/*    onChange={() => setImr(!imr)}*/}
                      {/*    checked={imr}*/}
                      {/*  />*/}
                      {/*</label>*/}
                      {/*{taskTypeCol && "Delimiter"}*/}
                    </div>
                  )}
                </div>

                {/*Link Trainees*/}
                <div className="card shadow">
                  <div
                    className="card-body d-flex flex-row align-items-center justify-content-between"
                    onClick={() =>
                      setOpenControl(openControl === "row_id" ? "" : "row_id")
                    }
                  >
                    <h4>
                      Link Rows to Trainees{" "}
                      {!completedLinkT ? (
                        <FontAwesomeIcon icon={faCog} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                    </h4>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="sidebar-icon "
                      style={
                        openControl !== "row_id"
                          ? {}
                          : { transform: "rotate(90deg)" }
                      }
                    />
                  </div>
                  {openControl === "row_id" && (
                    <div className="p-3">
                      <h5>Identifying Columns:</h5>
                      Select Columns that can be used to link to a trainee
                      (order of preference: DOD ID, email, names)
                      <Select
                        isMulti
                        value={Object.values(joinHeaders).map((v) => v.column)}
                        onChange={handleHeaderSelectionChange}
                        options={selectedSheetData.schema.fields.map((v) => {
                          return { value: v.name, label: v.name }
                        })}
                      />
                      <>
                        Map columns to corresponding value
                        <div
                          style={{
                            maxHeight: "30rem",
                            minHeight: "20rem",
                            overflow: "scroll",
                            scrollbarWidth: "none",
                          }}
                        >
                          <table className="table table-hover table-responsive text-center ">
                            <thead
                              className="thead-light"
                              // style={{ zIndex: 1 }}
                            >
                              <tr>
                                <th scope="col">Column Name</th>
                                <th scope="col">Trainee Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.values(joinHeaders).map((v) => {
                                return (
                                  <tr>
                                    <td>{v.column.value}</td>
                                    <td>
                                      <Select
                                        value={v.mapping}
                                        onChange={(e) =>
                                          handleMappingChange(v.column.value, e)
                                        }
                                        options={idOptions}
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </div>
                  )}
                </div>

                {/*Dates*/}
                <div className="card shadow">
                  <div
                    className="card-body d-flex flex-row align-items-center justify-content-between"
                    onClick={() =>
                      setOpenControl(openControl === "dates" ? "" : "dates")
                    }
                  >
                    <h4>
                      Select Dates{" "}
                      {!completedDates ? (
                        <FontAwesomeIcon icon={faCog} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      )}
                    </h4>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="sidebar-icon "
                      style={
                        openControl !== "dates"
                          ? {}
                          : { transform: "rotate(90deg)" }
                      }
                    />
                  </div>
                  {openControl === "dates" && (
                    <div className="p-3">
                      <h5>Due Date Column:</h5>
                      Select column that corresponds to the due date of the task
                      <Select
                        value={dueDateHeader}
                        onChange={setDueDateHeader}
                        options={selectedSheetData.schema.fields.map((v) => {
                          return { value: v.name, label: v.name }
                        })}
                      />
                      If the due date is calculated as a time from the chosen
                      column, add a offset value in days. For example: SOU needs
                      to be completed every 3 years so select the most recent
                      completion date and put 3 * 365 days in the following
                      input
                      <div style={{ width: "10rem" }}>
                        <FormField
                          type="number"
                          value={dateOffset}
                          setter={setDateOffset}
                        />
                      </div>
                      <br />
                      <h5>{"Optional Completion Date Header: "}</h5>
                      Select column that corresponds to the completion date of
                      the task, if a row is has an empty value, no completion
                      date will be assigned and the task will be outstanding for
                      the trainee
                      <Select
                        isClearable={true}
                        value={completeDateHeader}
                        onChange={setCompleteDateHeader}
                        options={selectedSheetData.schema.fields.map((v) => {
                          return { value: v.name, label: v.name }
                        })}
                      />
                    </div>
                  )}
                </div>

                {/*Optional*/}
                <div className="card shadow">
                  <div
                    className="card-body d-flex flex-row align-items-center justify-content-between"
                    onClick={() =>
                      setOpenControl(
                        openControl === "optional" ? "" : "optional"
                      )
                    }
                  >
                    <h4>Optional Columns</h4>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="sidebar-icon "
                      style={
                        openControl !== "optional"
                          ? {}
                          : { transform: "rotate(90deg)" }
                      }
                    />
                  </div>
                  {openControl === "optional" && (
                    <div className="p-3">
                      <h5>{"Optional Description Header: "}</h5>
                      Select the column to use as a task description for each
                      trainee.
                      <Select
                        isClearable={true}
                        value={descriptionHeader}
                        onChange={setDescriptionHeader}
                        options={selectedSheetData.schema.fields.map((v) => {
                          return { value: v.name, label: v.name }
                        })}
                      />
                    </div>
                  )}
                </div>
                <br />
                <button
                  onClick={uploadTasks}
                  disabled={
                    !(completedTaskType && completedLinkT && completedDates)
                  }
                  className={
                    !(completedTaskType && completedLinkT && completedDates)
                      ? "default-button default-button-disabled"
                      : "default-button"
                  }
                >
                  Preview
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const UploadFile = () => {
  const [file, setFile] = useState([])

  const [uploadStatus, setUploadStatus] = useState("idle")
  const [lastSheetTitle, setLastSheetTitle] = useState("")
  const [lastSheetType, setLastSheetType] = useState("")

  const [percentComplete, setPercentComplete] = useState(0)

  const [uploadedSheets, setUploadedSheets] = useState([])

  const user = useSelector((state) => state.user)

  //search states
  const [q, setQ] = useState("")

  const [selectedSheet, setSelectedSheet] = useState(null)

  // can this profile upload spreadsheets for the current UTA
  const can_upload = true

  const breadCrumbs = [
    <div className="breadCrumb" onClick={() => setSelectedSheet(null)}>
      Upload File
    </div>,
  ]

  const get_loaded_sheets = useCallback(() => {
    axios
      .get(`/get-associated-spreadsheets/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setUploadedSheets(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [user.currentFocusedTeam.id])

  useEffect(() => {
    if (user.currentFocusedTeam) {
      get_loaded_sheets()
    }
  }, [get_loaded_sheets, user])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLastSheetTitle(file.name)
    setUploadStatus("loading")
    setPercentComplete(0)

    const formData = new FormData()
    formData.append("file", file)

    axios
      .post(`/upload-bulk/?org_id=${user.currentFocusedTeam.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        // getProgress(res.data.id)
        // clearInput()
        setUploadStatus("success")
        setSelectedSheet(res.data)
        get_loaded_sheets()
      })
      .catch((err) => {
        setUploadStatus("failed")
        // clearInput()
        console.log(err.response)
      })
  }

  const filteredSheets = uploadedSheets.filter((sheet) =>
    sheet.display_name.toLowerCase().includes(q.toLowerCase())
  )
  //

  return (
    <div>
      {selectedSheet ? (
        <Parser
          selectedSheet={selectedSheet}
          org_id={user.currentFocusedTeam.id}
          parentBreadCrumbs={breadCrumbs}
        />
      ) : (
        <div>
          <DisplayBread breadCrumbs={breadCrumbs} />
          <div className="flex-row d-flex justify-content-between ">
            <div className="col-sm-3">
              <div
                className="card"
                style={{
                  padding: "1rem",
                  height: "100%",
                  fontSize: "1.1rem",
                }}
              >
                <form onSubmit={handleSubmit} className="h-100">
                  {uploadStatus === "failed" && (
                    <li
                      className="list-group-item list-group-item-danger text-center"
                      style={{ borderRadius: "5px", border: "none" }}
                    >
                      There was an error uploading the following sheet:{" "}
                      <b>{lastSheetTitle}</b> of type <b>{lastSheetType}</b>
                    </li>
                  )}
                  {uploadStatus === "success" && (
                    <li
                      className="list-group-item list-group-item-success text-center"
                      style={{ borderRadius: "5px", border: "none" }}
                    >
                      Successfully uploaded the following sheet:{" "}
                      <b>{lastSheetTitle}</b> of type <b>{lastSheetType}</b>
                    </li>
                  )}
                  <div className="d-flex flex-column  h-100 ">
                    <div>
                      <h4 style={{ marginTop: "0.5rem" }}>Upload New Sheet</h4>
                    </div>

                    <div className="d-flex flex-column align-items-center h-100 justify-content-center">
                      <label style={{ display: "none" }}>Select File :</label>
                      <input
                        type="file"
                        className="form-control"
                        name="upload_file"
                        onChange={(e) => setFile(e.target.files[0])}
                        id="sheet-input"
                        required
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />

                      <button
                        type="submit"
                        className={
                          can_upload ? "default-button" : "btn btn-secondary"
                        }
                        disabled={!can_upload}
                      >
                        Upload File
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <h2 className="align-content-center">OR</h2>
            <div className="card col-sm-7" style={{ padding: "1rem" }}>
              <h4>
                Select Existing
                <div className="search-wrapper" style={{ marginTop: "1rem" }}>
                  <label htmlFor="search-form">
                    <input
                      style={{ marginBottom: "16px" }}
                      type="search"
                      name="search-form"
                      id="search-form"
                      className="search-input textInput form-control"
                      placeholder="Search for..."
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <span className="sr-only">Search here</span>
                  </label>
                </div>
              </h4>

              <table className="table table-hover table-responsive text-center">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Sheet Name</th>
                    <th scope="col">Uploader</th>
                    <th scope="col">Upload Date</th>
                    <th scope="col">Parsed Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSheets.map((sheet) => {
                    return (
                      <tr
                        key={sheet.id}
                        onClick={() => setSelectedSheet(sheet)}
                      >
                        <td>{sheet.display_name}</td>
                        <td>{sheet.username}</td>
                        <td>
                          {new Date(sheet.upload_date).toLocaleString("en-US", {
                            hour12: false,
                          })}
                        </td>
                        <td>{sheet.parsed ? "Done" : "Not Parsed"}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* Loading Modal */}
          <Modal
            styles={modalStylesWarning}
            open={uploadStatus === "loading"}
            center
            focusTrapped={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}
            closeOnEsc={false}
          >
            <h5 className="modal-title">Uploading sheet</h5>
            <hr />
            <p className="modal-subtitle">
              We are currently processing the following sheet:{" "}
              <b>{lastSheetTitle}</b> of type <b>{lastSheetType}</b>. Please do
              not refresh or leave this page while or the process might end
              abruptly. This may take a few minutes.
            </p>
            <SmallLoader />
            <ProgressBar color="var(--blue)" percent={percentComplete} />
          </Modal>
          {/* {percentComplete === 100 && <Confetti recycle={false} />} */}
        </div>
      )}
    </div>
  )
}

const Upload = () => {
  return (
    <div className="card-grey  shadow p-3">
      <UploadFile />
    </div>
  )
}

export default Upload

import React, { useState, useEffect } from "react"

const SearchTraineeListItem = (props) => {
  const { trainee, setTrainees, changeTrainees, startAdded } = props

  const [changed, setChanged] = useState(false)

  useEffect(() => {
    if (changeTrainees.includes(trainee)) {
      setChanged(true)
    } else {
      setChanged(false)
    }
  }, [changeTrainees])

  const handleAdd = () => {
    setTrainees((trainees) => [...trainees, trainee])
  }

  const handleRemove = () => {
    setTrainees((trainees) => trainees.filter((item) => item.id !== trainee.id))
  }

  return (
    <li className="list-group-item">
      {trainee.name}
      {!changed ? (
        <button
          className={
            !startAdded
              ? "add-btn btn btn-outline-primary btn-sm float-right"
              : "add-btn btn btn-outline-danger btn-sm float-right"
          }
          onClick={handleAdd}
        >
          {!startAdded ? "Add" : "Remove"}
        </button>
      ) : (
        <button
          className={
            startAdded
              ? "add-btn btn btn-outline-primary btn-sm float-right"
              : "add-btn btn btn-outline-danger btn-sm float-right"
          }
          onClick={handleRemove}
          style={{ outline: 0 }}
        >
          {startAdded ? "Add" : "Remove"}
        </button>
      )}
    </li>
  )
}

export default SearchTraineeListItem

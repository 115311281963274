import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import SmallLoader from "../../components/smallLoader"
import ProgressBar from "../../components/progressBar"

import Modal from "react-responsive-modal"
import { modalStyles, modalStylesDanger } from "../../styles/constanStyles"
import Accordion from "../../components/accordion"

import axios from "axios"

// Redux
import { useSelector, useDispatch } from "react-redux"
import {
  setErrors,
  setTeam,
  getProfile,
  setTeamOrEmpty,
} from "../../redux/userSlice"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faExclamationTriangle,
  faCheckCircle,
  faUserSlash,
  faUserFriends,
  faUsers,
  faTasks,
  faTrash,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"

import { Line } from "react-chartjs-2"

import Chart from "chart.js/auto"
import { CategoryScale } from "chart.js"
Chart.register(CategoryScale)

// Helper components
const DashboardCard = ({ color, textColor, children, trainees }) => {
  return (
    <div className="col-xl-4 col-md-6 mb-4">
      <div
        className="card shadow h-100"
        style={{ borderLeft: `4px solid ${color}` }}
      >
        <div className="card-body text">
          <div
            style={{
              color: `${textColor}`,
            }}
          >
            {children}
          </div>
          <br />
          <div style={{ fontSize: "1rem", color: "gray" }}>
            {trainees &&
              trainees.length > 0 &&
              trainees.map((t) => {
                return (
                  <div>
                    <TraineeCardSlim
                      name={t.formattedName}
                      compliance={t.percentComplete}
                      color="var(--black)"
                      traineeID={t.id}
                      key={t.id}
                    />
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

const DashboardCardSlim = ({ color, textColor, children, trainees }) => {
  return (
    <div className="">
      <div
        className="card shadow container"
        style={{
          borderLeft: `4px solid ${color}`,
          height: "60px",
          width: "275px",
          paddingTop: "1.5px",
        }}
      >
        <div className="text">
          <div
            style={{
              color: `${textColor}`,
              paddingLeft: "5px",
            }}
          >
            {children}
          </div>
          <br />
          <div style={{ fontSize: "1rem", color: "gray" }}>
            {trainees &&
              trainees.length > 0 &&
              trainees.map((t, ti) => {
                return <p key={ti}>{t.toUpperCase()}</p>
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

const TraineeCardSlim = (props) => {
  return (
    <Link
      to={{
        pathname: `/trainee/profile/${props.traineeID}`,
        state: { name: props.name },
      }}
      style={{
        textDecoration: "none",
        color: "inherit",
        color: `${props.color}`,
      }}
    >
      <p>
        {props.name.toUpperCase()}{" "}
        {props.group && `(${props.group.name.toUpperCase()})`}
      </p>
    </Link>
  )
}

const TraineeCard = (props) => {
  const color =
    props.compliance < 100
      ? "var(--red)"
      : props.num_tasks_due > 0
        ? "var(--yellow)"
        : "var(--green)"

  return (
    <div style={{ minWidth: "15rem", maxWidth: "15rem" }}>
      <Link
        to={{
          pathname: `/trainee/profile/${props.traineeID}`,
          state: { name: props.name },
        }}
        style={{
          textDecoration: "none",

          color: color,
        }}
      >
        <div className="card shadow h-100">
          <div className="card-body">
            <p className="text font-weight-bold mb-1">
              {props.name.toUpperCase()}{" "}
              {props.group && `(${props.group.name.toUpperCase()})`}
            </p>

            <ProgressBar percent={props.compliance} color={color} />
          </div>
        </div>
      </Link>
    </div>
  )
}

const TeamOverview = ({
  currentTeam,
  currentTeamTrainees,
  handleTraineeComparatorChange,
  isTraineeCompareByName,
  ...rest
}) => {
  const [q, setQ] = useState("")
  const searchParam = ["name"]

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        if (item[newItem]) {
          return (
            item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          )
        } else {
          return false
        }
      })
    })
  }

  return (
    <div className="wrapper">
      <div
        className="search-wrapper d-flex justify-content-between"
        style={{
          marginTop: "1rem",
          marginLeft: "1rem",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        <label htmlFor="search-form">
          <input
            type="search"
            name="search-form"
            id="search-form"
            className="search-input textInput form-control"
            placeholder="Search for..."
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
          <span className="sr-only">Search</span>
        </label>
        <div>
          <button
            className="default-button "
            style={{ margin: 0, marginLeft: "1rem" }}
            onClick={() =>
              handleTraineeComparatorChange(!isTraineeCompareByName)
            }
          >
            {isTraineeCompareByName ? "Sort by Readiness" : "Sort by Name"}
          </button>
        </div>
      </div>
      <div className="container-fluid fade-in">
        <br />
        <div className="row justify-content-evenly">
          {search(currentTeamTrainees).map((trainee) => (
            <TraineeCard
              name={trainee.name}
              group={trainee.group}
              compliance={trainee.compliance_perc}
              traineeID={trainee.id}
              key={trainee.id}
              num_tasks_due={trainee.num_tasks_due}
            />
          ))}
        </div>
      </div>
      <br />
    </div>
  )
}

const Summary = (props) => {
  return (
    <div className="d-flex">
      <div className="col-12 col-md-6">
        <TaskOverview
          teamTasks={props.teamTasks}
          taskLoading={props.taskLoading}
        />
      </div>
      <div className="col-12 col-md-6">
        <TeamOverview
          numOverdue={props.numOverdue}
          numComingDue={props.numComingDue}
          numCompliant={props.numCompliant}
          tasksCompleted={props.tasksCompleted}
          user={props.user}
          currentTeam={props.currentTeam}
          currentTeamTrainees={props.currentTeamTrainees}
          handleTraineeComparatorChange={props.handleTraineeComparatorChange}
          isTraineeCompareByName={props.isTraineeCompareByName}
          small={true}
        />
      </div>
    </div>
  )
}

const SubDropDownTaskType = ({ name, color, trainees }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="">
      <div
        className="card-body d-flex flex-row align-items-center justify-content-between"
        onClick={() => setIsOpen(!isOpen)}
        style={{ color: color }}
      >
        <div className="">
          <h1 style={{ fontSize: ".9rem", margin: 0 }}>
            <b>
              {name} ({trainees.length})
            </b>{" "}
          </h1>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="sidebar-icon "
          // onClick={() => setIsOpen(!isOpen)}
          style={!isOpen ? {} : { transform: "rotate(90deg)" }}
        />
      </div>

      {isOpen && (
        <div className="d-flex flex-row flex-wrap p-3 ">
          {trainees.map((t) => (
            <Link
              to={{
                pathname: `/trainee/profile/${t.id}`,
              }}
              style={{ textDecoration: "none" }}
              className="m-1"
            >
              <div
                className="card  h-100 p-1 "
                style={{ fontSize: ".6rem", color: color }}
              >
                {t.name}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

const TaskTypeCard = ({ taskType }) => {
  const [isOpen, setIsOpen] = useState(false)

  const perc =
    ((taskType.compliant.length + taskType.coming_due.length) /
      (taskType.compliant.length +
        taskType.coming_due.length +
        taskType.overdue.length)) *
    100

  return (
    <div className="card shadow h-100">
      <div
        className="card-body d-flex flex-row align-items-center justify-content-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="col-6">
          <h2 style={{ fontSize: "1.1rem", margin: 0 }}>
            <b>{taskType.task}</b>{" "}
          </h2>
          <ProgressBar percent={perc} color={"var(--green)"} />
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="sidebar-icon "
          // onClick={() => setIsOpen(!isOpen)}
          style={!isOpen ? {} : { transform: "rotate(90deg)" }}
        />
      </div>
      {isOpen && (
        <div>
          <SubDropDownTaskType
            name={"Compliant"}
            trainees={taskType.compliant}
            color={"var(--green)"}
          />
          <SubDropDownTaskType
            name={"Coming Due"}
            trainees={taskType.coming_due}
            color={"var(--yellow)"}
          />
          <SubDropDownTaskType
            name={"Over Due"}
            trainees={taskType.overdue}
            color={"var(--red)"}
          />
        </div>
      )}
    </div>
  )
}

const TaskOverview = ({ teamTasks, taskLoading }) => {
  if (taskLoading) {
    return <SmallLoader />
  }

  return (
    <div className="container-fluid fade-in ">
      <br />
      {teamTasks && teamTasks.length > 0 ? (
        teamTasks.map((taskType) => <TaskTypeCard taskType={taskType} />)
      ) : (
        <div>There are currently no tasks in this team</div>
      )}
      <br />
    </div>
  )
}

// Trainee comparator functions
const compTraineeByName = (a, b) => {
  if (a.name === b.name) {
    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0
  }

  return a.name > b.name ? 1 : -1
}

const compTraineeByTasks = (a, b) => {
  if (a.compliance_perc !== b.compliance_perc) {
    return a.compliance_perc < b.compliance_perc ? -1 : 1
  }
  return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
}

const OverviewCard = (props) => {
  const { currentTeam, teamTasks, taskLoading } = props
  // Sorted trainees
  const [currentTeamTrainees, setCurrentTeamTrainees] = useState([])
  const [isTraineeCompareByName, setIsTraineeCompareByName] = useState(false)

  // Overview Card Tab State
  const [overviewView, setOverviewView] = useState("summary")

  // Sort trainees by name on load
  useEffect(() => {
    if (Array.isArray(currentTeam)) {
      if (isTraineeCompareByName) {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByName))
      } else {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByTasks))
      }
    }
  }, [currentTeam])

  // Handler function for changing sorting methods
  const handleTraineeComparatorChange = (isName) => {
    if (isName) {
      setIsTraineeCompareByName(true)
      if (Array.isArray(currentTeam)) {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByName))
      }
    } else {
      setIsTraineeCompareByName(false)
      if (Array.isArray(currentTeam)) {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByTasks))
      }
    }
  }

  return (
    <>
      <div className="card shadow">
        <div
          className="card-header"
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div className="d-flex">
            <button
              className={
                overviewView === "summary"
                  ? "default-button on-bar default-button-active"
                  : "default-button on-bar"
              }
              style={{ borderRadius: "0.3rem 0 0 0.3rem " }}
              onClick={() => setOverviewView("summary")}
            >
              Summary
            </button>
            <button
              className={
                overviewView === "team"
                  ? "default-button on-bar default-button-active"
                  : "default-button on-bar"
              }
              onClick={() => setOverviewView("team")}
            >
              Members
            </button>
            <button
              className={
                overviewView === "task"
                  ? "default-button on-bar default-button-active "
                  : "default-button on-bar "
              }
              style={{ borderRadius: "0 0.3rem 0.3rem 0" }}
              onClick={() => setOverviewView("task")}
            >
              Task
            </button>
          </div>
        </div>
        {overviewView === "summary" && (
          <Summary
            teamTasks={teamTasks}
            taskLoading={taskLoading}
            numOverdue={props.numOverdue}
            numComingDue={props.numComingDue}
            numCompliant={props.numCompliant}
            tasksCompleted={props.tasksCompleted}
            user={props.user}
            currentTeam={currentTeam}
            currentTeamTrainees={currentTeamTrainees}
            handleTraineeComparatorChange={handleTraineeComparatorChange}
            isTraineeCompareByName={isTraineeCompareByName}
          />
        )}
        {overviewView === "team" && (
          <TeamOverview
            numOverdue={props.numOverdue}
            numComingDue={props.numComingDue}
            numCompliant={props.numCompliant}
            tasksCompleted={props.tasksCompleted}
            user={props.user}
            currentTeam={currentTeam}
            currentTeamTrainees={currentTeamTrainees}
            handleTraineeComparatorChange={handleTraineeComparatorChange}
            isTraineeCompareByName={isTraineeCompareByName}
          />
        )}
        {overviewView === "task" && (
          <TaskOverview teamTasks={teamTasks} taskLoading={taskLoading} />
        )}

        <div />
      </div>
    </>
  )
}

const Historical = () => {
  const user = useSelector((state) => state.user)
  const [historicalData, setHistoricalData] = useState({})

  useEffect(() => {
    axios
      .get(`get-historical-readiness/?org_id=${user.currentFocusedTeam.id}`) //HELLO
      .then((res) => {
        const newHistoricalData = {
          labels: [
            ...res.data.historical.map((v) => v.dt),
            ...res.data.projected.map((v, i) => v.dt),
          ],
          datasets: [
            {
              label: "Historical Readiness",
              data: res.data.historical.map((v) => v.perc.toFixed(1)),
              borderColor: "rgb(255, 99, 132)",
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: "Projected Readiness",
              data: [
                ...Array(res.data.historical.length).fill(null),
                ...res.data.projected.map((v) => v.perc.toFixed(1)),
              ],
              borderColor: "rgb(255, 99, 132)",
              borderDash: [2, 3],
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
              label: "Connect",
              pointRadius: 0,
              pointHitRadius: 0,
              data: [
                ...res.data.historical.map((v, i) => {
                  if (i == res.data.historical.length - 1) {
                    return v.perc.toFixed(1)
                  }
                  return null
                }),
                ...res.data.projected.map((v, i) => {
                  if (i == 0) {
                    return v.perc.toFixed(1)
                  }
                  return null
                }),
              ],
              borderColor: "rgb(255, 99, 132)",
              borderDash: [2, 3],
              backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
          ],
        }

        setHistoricalData(newHistoricalData)
      })
      .catch((err) => {})
  }, [user.currentFocusedTeam])

  return (
    <div
      className="card shadow  col-8 mb-4 "
      style={{ borderLeft: `4px solid black` }}
    >
      <div className="card-body text ">
        {Object.values(historicalData).length > 0 ? (
          <Line
            data={historicalData}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                x: {
                  ticks: {
                    maxTicksLimit: 10,
                  },
                },
              },

              plugins: {
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: "Historical Readiness",
                },
              },
            }}
          />
        ) : (
          <SmallLoader />
        )}
      </div>
    </div>
  )
}
// Main component
const Dashboard = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const [currentTeam, setCurrentTeam] = useState({})
  const [loading, setLoading] = useState(false)

  const [currentTeamGroups, setCurrentTeamGroups] = useState([])
  const [groupLoading, setGroupLoading] = useState(false)

  const [teamTasks, setTeamTasks] = useState([])
  const [taskLoading, setTaskLoading] = useState(false)

  // Top dashboard info states
  const [numOverdue, setNumOverdue] = useState(0)
  const [numComingDue, setNumComingDue] = useState(0)
  const [numCompliant, setNumCompliant] = useState(0)
  const [tasksCompleted, setTasksCompleted] = useState(0)

  useEffect(() => {
    if (user.status === "idle" || user.status === "success") {
      if (user.currentFocusedTeam) {
        setLoading(true)

        axios
          .get(`trainees-overview/?org_id=${user.currentFocusedTeam.id}`) //HELLO
          .then((res) => {
            setCurrentTeam(res.data)
            // Limit trainee lists to 6
            // setRedList(res.data.red_list.slice(0, 6))
            // setYellowList(res.data.yellow_list.slice(0, 6))
            // setGreenList(res.data.green_list.slice(0, 6))

            setLoading(false)

            // getGroups()
            getTasks()

            // Set top info states

            setNumOverdue(0)
            setNumComingDue(0)
            setNumCompliant(0)
            setTasksCompleted(0)

            res.data.map((t) => {
              if (t.num_tasks_overdue > 0) {
                setNumOverdue((prev) => prev + 1)
              } else if (t.num_tasks_due > 0) {
                setNumComingDue((prev) => prev + 1)
              } else {
                setNumCompliant((prev) => prev + 1)
              }

              setTasksCompleted((prev) => prev + t.num_tasks_completed)
            })
          })
          .catch((err) => {
            console.log(err)
            dispatch(
              setErrors([
                "An unexpected error has occured, please reload the page",
              ])
            )
          })
      }
    }
  }, [user.currentFocusedTeam, user.status])

  const getGroups = () => {
    setGroupLoading(true)
    axios
      .get(`/get-shop-groups/${user.currentFocusedTeam}`)
      .then((res) => {
        setCurrentTeamGroups(res.data)
        setGroupLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setGroupLoading(false)
        dispatch(
          setErrors(["An unexpected error has occured, please reload the page"])
        )
      })
  }

  // Comparator method to sort tasks by most trainees
  const compTasks = (a, b) => {
    if (a.trainees.length === b.trainees.length) {
      return a.task > b.task ? 1 : a.task < b.task ? -1 : 0
    }

    return a.trainees.length < b.trainees.length ? 1 : -1
  }

  const getTasks = () => {
    setTaskLoading(true)
    axios
      .get(`/tasks-overview/?org_id=${user.currentFocusedTeam.id}`)
      // .get(`/tasks-overview/25`, { baseURL: "http://localhost:8000" })
      .then((res) => {
        // setTeamTasks(res.data.sort(compTasks))
        setTeamTasks(res.data)
        setTaskLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setTaskLoading(false)
        dispatch(
          setErrors(["An unexpected error has occured, please reload the page"])
        )
      })
  }

  if (loading) {
    return <LoadingDashboard />
  }

  return (
    <div>
      <>
        <div>
          <div className="row">
            <DashboardCard color="var(--blue)" textColor="black">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                    Tasks Completed
                  </div>
                  <ProgressBar
                    percent={Math.round(tasksCompleted)}
                    color="var(--blue)"
                  />
                </div>
              </div>
              <hr />
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-uppercase mb-1 text-red">
                    Individuals with Overdue Tasks
                  </div>
                  <div
                    className="h5 mb-0 text-gray-800"
                    style={{ fontWeight: "bold" }}
                  >
                    {numOverdue}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-yellow text-uppercase mb-1">
                    Individuals with Coming Due Tasks
                  </div>
                  <div
                    className="h5 mb-0 text-gray-800"
                    style={{ fontWeight: "bold" }}
                  >
                    {numComingDue}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-green text-uppercase mb-1">
                    Compliant Individuals
                  </div>
                  <div
                    className="h5 mb-0 text-gray-800"
                    style={{ fontWeight: "bold" }}
                  >
                    {numCompliant}
                  </div>
                </div>
              </div>
            </DashboardCard>

            <Historical />

            {/*<DashboardCard*/}
            {/*  color="var(--red)"*/}
            {/*  textColor="var(--red)"*/}
            {/*  trainees={"redList"}*/}
            {/*>*/}
            {/*  TRAINEES MOST LIKELY TO FALL BEHIND*/}
            {/*</DashboardCard>*/}

            {/*<DashboardCard*/}
            {/*  color="var(--yellow)"*/}
            {/*  textColor="var(--yellow)"*/}
            {/*  trainees={"yellowList"}*/}
            {/*>*/}
            {/*  TRAINEES LEAST LIKELY TO COMPLETE TASKS*/}
            {/*</DashboardCard>*/}

            {/*<DashboardCard*/}
            {/*  color="var(--green)"*/}
            {/*  textColor="var(--green)"*/}
            {/*  trainees={"greenList"}*/}
            {/*>*/}
            {/*  TRAINEES MOST LIKELY TO COMPLETE TASKS*/}
            {/*</DashboardCard>*/}
          </div>
        </div>
      </>

      <div>
        <OverviewCard
          currentTeam={currentTeam}
          currentTeamGroups={currentTeamGroups}
          groupLoading={groupLoading}
          teamTasks={teamTasks}
          taskLoading={taskLoading}
          getGroups={getGroups}
          numOverdue={numOverdue}
          numComingDue={numComingDue}
          numCompliant={numCompliant}
          tasksCompleted={tasksCompleted}
          user={user}
        />
      </div>
    </div>
  )
}

export default Dashboard

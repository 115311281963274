import React from "react"
import { eventTimeFormatted } from "../pages/supervisor/events"
const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const EventDetailBody = ({ event }) => {
  const eventTime = eventTimeFormatted(event)
  return (
    <>
      <b>Date/Time: </b>
      <span>{eventTime}</span>
      <br />
      <b>Description: </b>
      {event.description}
      <br />
      {event.capacity === 0 ? (
        <>
          <b>Capacity: </b>
          unlimited
        </>
      ) : (
        <>
          <b>Capacity: </b>
          {event.capacity}
        </>
      )}
      <br />
      {event.location && event.location.length > 0 && (
        <>
          <b>Location: </b>
          {event.location}
          <br />
        </>
      )}
      <b>Trainees: </b>
      {Array.isArray(event.trainees) && (
        <ul>
          {event.trainees.map((trainee, ti) => {
            if (ti === event.trainees.length - 1) {
              return <li key={trainee.id}>{trainee.name}</li>
            } else {
              return <li key={trainee.id}>{trainee.name}, </li>
            }
          })}
        </ul>
      )}
      {(!event.trainees || event.trainees.length === 0) && (
        <>
          <span>None</span>
          <br />
          <br />
        </>
      )}
    </>
  )
}

export default EventDetailBody

import React, { useState, useEffect } from "react"
import AuthLayout from "../layouts/authLayout"
import { Link } from "react-router-dom"
import axios from "axios"

import StatusEnum from "../util/StatusEnum"
import Modal from "react-responsive-modal"
import { modalStyles } from "../styles/constanStyles"
import { ScaleLoader } from "react-spinners"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { login, clearErrors } from "../redux/userSlice"

const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("")
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false)
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(
    StatusEnum.idle
  )
  // const [remember, setRemember] = useState(false)

  const dispatch = useDispatch()

  const { errors, status } = useSelector((state) => state.user)

  // Clear redux errors on page load
  useEffect(() => {
    dispatch(clearErrors())
  }, [dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    const data = { username, password }
    dispatch(login(data))
  }

  const openForgotPasswordModal = (e) => {
    e.preventDefault()
    setForgotPasswordModalOpen(true)
    setForgotPasswordStatus(StatusEnum.idle)
    setForgotPasswordEmail("")
  }

  const handleForgotPassword = (e) => {
    e.preventDefault()

    setForgotPasswordStatus(StatusEnum.loading)

    const body = { email: forgotPasswordEmail }

    axios
      .post("/forgot-password", body)
      .then(() => {
        setForgotPasswordStatus(StatusEnum.success)
      })
      .catch(() => {
        setForgotPasswordStatus(StatusEnum.error)
      })
  }

  return (
    <AuthLayout>
      <div
        style={{
          width: "100vw",
          minHeight: "100vh",
          backgroundImage:
            "linear-gradient(180deg, var(--blue) 10%, var(--dark-blue) 100%)",
          backgroundSize: "cover",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          paddingTop: "3rem",
        }}
      >
        <div style={{ position: "absolute", top: 0, left: 0 }}></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-lg-3 d-none d-lg-block "></div>
                    <div className="col-lg-6">
                      <div className="p-5">
                        <div className="text-center">
                          <h1 className="h4 text-gray-900 mb-4">
                            Welcome Back!
                          </h1>
                        </div>
                        {errors && errors.length > 0 && (
                          <div className="h6 text-center text-danger">
                            {errors[0]}
                          </div>
                        )}

                        <form className="user" onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input
                              type="string"
                              className="form-control form-control-user"
                              name="username"
                              id="username"
                              aria-describedby="emailHelp"
                              placeholder="Enter Email Address..."
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              style={{
                                borderRadius: "10rem",
                                margin: "1rem 0",
                                fontSize: "0.8rem",
                                height: "calc(1.5em + 1.8rem + 2px)",
                              }}
                              required
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="password"
                              className="form-control form-control-user"
                              name="password"
                              id="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              style={{
                                borderRadius: "10rem",
                                margin: "1rem 0",
                                fontSize: "0.8rem",
                                height: "calc(1.5em + 1.8rem + 2px)",
                              }}
                              required
                            />
                          </div>
                          {/* <div className="form-group">
                          <div className="custom-control custom-checkbox small">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck"
                              style={{ marginRight: "5px" }}
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck"
                              checked={remember}
                              onChange={() =>
                                setRemember((remember) => !remember)
                              }
                            >
                              Remember Me
                            </label>
                          </div>
                        </div> */}
                          <a>
                            <button
                              type="submit"
                              className="btn btn-primary btn-user btn-block"
                              style={{
                                borderRadius: "10rem",
                                margin: "1rem 0",
                                fontSize: "0.8rem",
                                height: "3rem",
                                width: "100%",
                                backgroundColor: "var(--blue)",
                              }}
                              disabled={status === "loading"}
                            >
                              Login
                            </button>
                          </a>
                        </form>
                        <hr />
                        <div className="text-center">
                          <a
                            className="small"
                            style={{ cursor: "pointer" }}
                            onClick={(e) => openForgotPasswordModal(e)}
                          >
                            Forgot Password?
                          </a>
                        </div>
                        {/* <div className="text-center">
                        <Link className="small" to="/register">
                          Create an Account!
                        </Link>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        styles={modalStyles}
        open={forgotPasswordModalOpen}
        onClose={() => setForgotPasswordModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Forgot Password?</h5>
        <hr />
        {(forgotPasswordStatus === StatusEnum.idle ||
          forgotPasswordStatus === StatusEnum.error) && (
          <>
            <p className="modal-subtitle">Please enter your email</p>
            <form onSubmit={handleForgotPassword}>
              <input
                className="modal-input"
                type="email"
                value={forgotPasswordEmail}
                onChange={(e) => setForgotPasswordEmail(e.target.value)}
                required
              />
              <hr />
              {forgotPasswordStatus == StatusEnum.error && (
                <p className="h6 text-center text-danger">
                  An error has occurred, please try again or contact the EzTrain
                  team directly
                </p>
              )}
              <div className="modal-button-container ">
                <button
                  className="default-button default-button-gray"
                  onClick={() => setForgotPasswordModalOpen(false)}
                  disabled={forgotPasswordStatus == StatusEnum.loading}
                  type="reset"
                >
                  Cancel
                </button>
                <button
                  className="default-button "
                  type="submit"
                  disabled={forgotPasswordStatus == StatusEnum.loading}
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        )}
        {forgotPasswordStatus === StatusEnum.loading && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <ScaleLoader color="black" loading={true} size={75} />
          </div>
        )}
        {forgotPasswordStatus === StatusEnum.success && (
          <>
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <p className="modal-subtitle">
                Please check your email for a link to reset your password
              </p>
            </div>{" "}
            <div className="modal-button-container ">
              <button
                className="default-button"
                onClick={() => setForgotPasswordModalOpen(false)}
              >
                Done
              </button>
            </div>
          </>
        )}
      </Modal>
    </AuthLayout>
  )
}

export default Login

import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"

import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { getProfile, setTeam, setTeamID } from "../../redux/userSlice"

import { ReactMultiEmail } from "react-multi-email"
import "react-multi-email/dist/style.css"
import { modalStyles, modalStylesDanger } from "../../styles/constanStyles"
import Modal from "react-responsive-modal"
import StatusEnum from "../../util/StatusEnum"
import { ScaleLoader } from "react-spinners"
import Accordion from "../../components/accordion"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faCog,
  faInfoCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"
import UTAManager from "./tabs/utaManager"
import OrgChartView from "../supervisor/orgChart"

const SupervisorListTab = () => {
  const [childSupervisors, setChildSupervisors] = useState([])
  const [directSupervisors, setDirectSupervisors] = useState([])
  const [parentSupervisors, setParentSupervisors] = useState([])
  const [supervisorListLoading, setSupervisorListLoading] = useState(true)
  const [orgToRemove, setOrgToRemove] = useState([])
  const [supervisorToRemove, setSupervisorToRemove] = useState({})
  const [removeOrgModalOpen, setRemoveOrgModalOpen] = useState(false)
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  useEffect(() => {
    getSupervisorList()
  }, [user.currentFocusedTeam])

  const SupervisorDropdown = ({ supervisorList, deletable = false }) => {
    return (
      <div>
        {supervisorList.map((supervisor) => (
          <Accordion
            key={supervisor.user_id}
            title={<AccordionTitle supervisor={supervisor} />}
            containerStyles={{ maxWidth: "none" }}
            bodyStyles={{ maxWidth: "none" }}
          >
            <h6>
              <b>{supervisor.username}</b> has access to:
            </h6>
            <ul style={{ marginLeft: "1rem" }}>
              {(supervisor.org && supervisor.org.length) > 0 ? (
                supervisor.org.map((org, si) => {
                  return (
                    <li key={`${org.id}${supervisor.user_id}`}>
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        <Link
                          onClick={() => {
                            localStorage.setItem("Team", org)
                            dispatch(setTeam(org))
                          }}
                          style={{ textDecoration: "none" }}
                          to="/home"
                        >
                          <p style={{ marginBottom: "0" }}>{org.name}</p>
                        </Link>
                        {deletable && (
                          <FontAwesomeIcon
                            icon={faTrash}
                            style={{
                              cursor: "pointer",
                              color: "var(--red)",
                            }}
                            onClick={() => {
                              setSupervisorToRemove(supervisor)
                              setOrgToRemove(org)
                              setRemoveOrgModalOpen(true)
                            }}
                          />
                        )}
                      </div>
                    </li>
                  )
                })
              ) : (
                <p>None</p>
              )}
            </ul>
          </Accordion>
        ))}
      </div>
    )
  }

  const getSupervisorList = () => {
    axios
      .get(`/get-org-supervisors/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setChildSupervisors(res.data.children)
        setDirectSupervisors(res.data.direct)
        setParentSupervisors(res.data.parent)

        setSupervisorListLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setSupervisorListLoading(false)
      })
  }

  const handleRemoveShopAccess = () => {
    setSupervisorListLoading(true)
    setRemoveOrgModalOpen(false)

    const body = {
      org_id: orgToRemove.id,
      user_id: supervisorToRemove.user_id,
    }
    console.log(supervisorToRemove)
    axios
      .delete("/delete-org-access/", { data: body })
      .then((res) => {
        getSupervisorList()
      })
      .catch((err) => {
        console.log(err.response)
        getSupervisorList()
      })
  }

  const AccordionTitle = ({ supervisor }) => {
    return (
      <h2 style={{ fontSize: "1.1rem", margin: 0 }}>
        <b>{supervisor.username}</b>
      </h2>
    )
  }

  return (
    <div className="col-xl-12 col-lg-7 mt-2">
      <div className="container-fluid fade-in" style={{ padding: "1rem" }}>
        {supervisorListLoading ? (
          <div style={{ textAlign: "center" }}>
            <ScaleLoader />
          </div>
        ) : (
          <div>
            {parentSupervisors.length > 0 && (
              <div>
                <h3>Admins of Parent Organizations</h3>
                <SupervisorDropdown supervisorList={parentSupervisors} />
              </div>
            )}
            {directSupervisors.length > 0 && (
              <div>
                <h3>Admins of Organization</h3>
                <SupervisorDropdown
                  supervisorList={directSupervisors}
                  deletable={true}
                />
              </div>
            )}
            {childSupervisors.length > 0 && (
              <div>
                <h3>Admins of Children Organization</h3>
                <SupervisorDropdown
                  supervisorList={childSupervisors}
                  deletable={true}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        styles={modalStylesDanger}
        open={removeOrgModalOpen}
        onClose={() => {
          setRemoveOrgModalOpen(false)
        }}
        center
        focusTrapped={false}
      >
        <p className="modal-title" style={{ color: "var(--red)" }}>
          Remove Shop Access
        </p>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to remove {supervisorToRemove.name}'s access to{" "}
          {orgToRemove.name}?
        </p>
        <div className="modal-button-container">
          <button
            className="default-button default-button-gray"
            onClick={() => setRemoveOrgModalOpen(false)}
          >
            No
          </button>
          <button
            className="default-button default-button-red"
            onClick={() => handleRemoveShopAccess()}
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  )
}
const GrantAccess = () => {
  const [emails, setEmails] = useState([])
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [inviteStatus, setInviteStatus] = useState(StatusEnum.error)

  const [errorMessage, setErrorMessage] = useState([])

  const [selectedOrgs, setSelectedOrgs] = useState([])
  const user = useSelector((state) => state.user)
  const handleCheckboxChange = (org) => {
    if (selectedOrgs.includes(org)) {
      // Remove organization if it is already selected
      setSelectedOrgs(selectedOrgs.filter((o) => o !== org))
    } else {
      // Add organization to the list of selected organizations
      setSelectedOrgs([...selectedOrgs, org])
    }
  }

  const handleInvitationSend = (e) => {
    e.preventDefault()
    setInviteStatus(StatusEnum.loading)
    setInviteModalOpen(true)

    let failedEmails = []

    setInviteStatus(StatusEnum.success)
    emails.map((email) => {
      const body = {
        email: email,
        organization_ids: selectedOrgs.map((org) => org.id),
      }

      axios

        .post("/grant-access/", body)
        .then((res) => {
          setInviteStatus(StatusEnum.success)
          setEmails([])
        })
        .catch((err) => {
          failedEmails.push(email)
          console.log(err.response)
          setInviteStatus(StatusEnum.error)
          if (err.response.data) {
            setErrorMessage((prevState) => [err.response.data, ...prevState])
          }
        })
    })
    setEmails(failedEmails)
  }
  return (
    <>
      <div
        className="col-xl-12 col-lg-7"
        style={{
          marginBottom: "1rem",
          marginTop: "1rem",
          marginLeft: "1rem",
          minHeight: "15rem",
        }}
      >
        <div className="container-fluid fade-in">
          <form onSubmit={(e) => handleInvitationSend(e)}>
            <h3>Add Access</h3>
            <ReactMultiEmail
              placeholder="Input email(s)"
              emails={emails}
              onChange={(_emails) => {
                setEmails(_emails)
              }}
              autoFocus={true}
              style={{ width: "50%" }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
            <div>Choose Orgs to grant access</div>
            <OrgChartView
              onSelect={handleCheckboxChange}
              checkboxes={true}
              selected={selectedOrgs}
            />

            <br />
            <button
              type="submit"
              className="default-button"
              style={{ marginLeft: 0 }}
            >
              Send Invitations
            </button>
          </form>
        </div>
      </div>
      <Modal
        styles={modalStyles}
        open={inviteModalOpen}
        onClose={() => {
          if (inviteStatus !== StatusEnum.loading) {
            setInviteModalOpen(false)
          }
        }}
        showCloseIcon={!inviteStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        {inviteStatus === StatusEnum.loading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ScaleLoader color="black" loading={true} size={75} />
          </div>
        )}

        {inviteStatus === StatusEnum.error && (
          <>
            {errorMessage && errorMessage.length > 0 ? (
              <p className="modal-title" style={{ color: "var(--red)" }}>
                {errorMessage}
              </p>
            ) : (
              <p className="modal-title" style={{ color: "var(--red)" }}>
                There was an error sending the invitation(s)
              </p>
            )}

            <div className="modal-button-container">
              <button
                className="default-button default-button-red"
                onClick={() => setInviteModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
        {inviteStatus === StatusEnum.success && (
          <>
            <p className="modal-title">Invitation(s) successfully sent</p>
            <div className="modal-button-container">
              <button
                className="default-button"
                onClick={() => setInviteModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

const InvitationTab = () => {
  const [emails, setEmails] = useState([])
  const [inviteModalOpen, setInviteModalOpen] = useState(false)
  const [inviteStatus, setInviteStatus] = useState(StatusEnum.error)
  const [invitationList, setInvitationList] = useState([])
  const [loadingInvitations, setLoadingInvitations] = useState(true)

  const [errorMessage, setErrorMessage] = useState([])
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false)

  const [selectedOrgs, setSelectedOrgs] = useState([])
  const user = useSelector((state) => state.user)
  const handleCheckboxChange = (org) => {
    if (selectedOrgs.includes(org)) {
      // Remove organization if it is already selected
      setSelectedOrgs(selectedOrgs.filter((o) => o !== org))
    } else {
      // Add organization to the list of selected organizations
      setSelectedOrgs([...selectedOrgs, org])
    }
  }

  useEffect(() => {
    getAccountInvitations()
  }, [user.currentFocusedTeam])

  const getAccountInvitations = () => {
    setInvitationList(true)

    axios
      .get("/get-account-invitations/")
      .then((res) => {
        setInvitationList(res.data)
        setLoadingInvitations(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingInvitations(false)
      })
  }

  const handleInvitationSend = (e) => {
    e.preventDefault()
    setInviteStatus(StatusEnum.loading)
    setInviteModalOpen(true)

    let failedEmails = []

    setInviteStatus(StatusEnum.success)
    emails.map((email) => {
      const body = {
        email: email,
        organization_ids: selectedOrgs.map((org) => org.id),
      }

      axios
        .post("/send-profile-invitation/", body)
        .then((res) => {
          setInviteStatus(StatusEnum.success)
          setEmails([])
          getAccountInvitations()
        })
        .catch((err) => {
          failedEmails.push(email)
          console.log(err.response)
          setInviteStatus(StatusEnum.error)
          if (err.response.data) {
            setErrorMessage((prevState) => [err.response.data, ...prevState])
          }
        })
    })
    setEmails(failedEmails)
  }
  return (
    <>
      <div
        className="col-xl-12 col-lg-7"
        style={{
          marginBottom: "1rem",
          marginTop: "1rem",
          marginLeft: "1rem",
          minHeight: "15rem",
        }}
      >
        <div className="container-fluid fade-in">
          <form onSubmit={(e) => handleInvitationSend(e)}>
            <h3>Invite People to Paratus</h3>
            <ReactMultiEmail
              placeholder="Input email(s)"
              emails={emails}
              onChange={(_emails) => {
                setEmails(_emails)
              }}
              autoFocus={true}
              style={{ width: "50%" }}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
            <div>Choose Orgs to grant access</div>
            <OrgChartView
              onSelect={handleCheckboxChange}
              checkboxes={true}
              selected={selectedOrgs}
            />

            <br />
            <button
              type="submit"
              className="default-button"
              style={{ marginLeft: 0 }}
            >
              Send Invitations
            </button>
          </form>
          <div>
            <h3>Past Invitations: </h3>
            {loadingInvitations ? (
              <div style={{ textAlign: "center" }}>
                <ScaleLoader />
              </div>
            ) : (
              <table
                className="table table-hover table-responsive text-center"
                style={{ backgroundColor: "#eaecf4" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Date</th>
                    <th scope="col">Invitation Status</th>
                  </tr>
                </thead>
                <tbody>
                  {invitationList &&
                    invitationList.length > 0 &&
                    invitationList.map((invitation) => {
                      return (
                        <tr key={invitation.email}>
                          <td>{invitation.email}</td>
                          <td>
                            {new Date(invitation.created_date).toDateString()}
                          </td>
                          <td>
                            {invitation.is_expired
                              ? "expired"
                              : invitation.status}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Modal
        styles={modalStyles}
        open={inviteModalOpen}
        onClose={() => {
          if (inviteStatus !== StatusEnum.loading) {
            setInviteModalOpen(false)
          }
        }}
        showCloseIcon={!inviteStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        {inviteStatus === StatusEnum.loading && (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <ScaleLoader color="black" loading={true} size={75} />
          </div>
        )}

        {inviteStatus === StatusEnum.error && (
          <>
            {errorMessage && errorMessage.length > 0 ? (
              <p className="modal-title" style={{ color: "var(--red)" }}>
                {errorMessage}
              </p>
            ) : (
              <p className="modal-title" style={{ color: "var(--red)" }}>
                There was an error sending the invitation(s)
              </p>
            )}

            <div className="modal-button-container">
              <button
                className="default-button default-button-red"
                onClick={() => setInviteModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
        {inviteStatus === StatusEnum.success && (
          <>
            <p className="modal-title">Invitation(s) successfully sent</p>
            <div className="modal-button-container">
              <button
                className="default-button"
                onClick={() => setInviteModalOpen(false)}
              >
                Ok
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}
const FormField = ({ title, value, type, setter }) => (
  <>
    <p>{title}</p>
    <div className="input-group mb-3">
      <input
        style={{ color: "rgba(100,100,100,0.8)" }}
        type={type}
        className="textinput textInput form-control"
        value={value}
        onChange={(v) => {
          setter(v.target.value)
        }}
      />
    </div>
  </>
)

const RemoveTraineeWindow = ({ setOpen }) => {
  const user = useSelector((state) => state.user)

  const [trainees, setTrainees] = useState([])
  const [traineesAdd, setTraineesAdd] = useState([])
  const [modalError, setModalError] = useState("")

  const [q, setQ] = useState("")
  const searchParam = [
    "id",
    "name",
    "num_tasks_overdue",
    "num_tasks_due",
    "num_tasks_completed",
    "id_number",
    "org",
    "phone_number",
    "is_verified",
  ]

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((searchP) => {
        if (item[searchP]) {
          return item[searchP]
            .toString()
            .toLowerCase()
            .includes(q.toLowerCase())
        }
        return false
      })
    })
  }

  useEffect(() => {
    axios
      .get(`all_trainees/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setTrainees(res.data)
      })

      .catch((err) => {
        setModalError(err)
      })
  }, [user.currentFocusedTeam])

  const handleCheck = (id) => {
    if (traineesAdd.includes(id)) {
      setTraineesAdd((prev) => prev.filter((p) => p !== id))
    } else {
      setTraineesAdd((prev) => [...prev, id])
    }
  }

  const submitAddTrainees = () => {
    axios
      .post(
        `remove-trainees/?org_id=${user.currentFocusedTeam.id}`,
        traineesAdd
      )
      .then((res) => {
        setOpen(false)
      })

      .catch((err) => {
        setModalError(err)
      })
  }

  console.log(traineesAdd)
  return (
    <div>
      <div className="search-wrapper" style={{ display: "inline-block" }}>
        <label htmlFor="search-form">
          <input
            style={{ marginBottom: "16px" }}
            type="search"
            name="search-form"
            id="search-form"
            className="search-input textInput form-control"
            placeholder="Search for..."
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
          <span className="sr-only">Search names here</span>
        </label>
      </div>
      {trainees.length > 0 && (
        <div style={{ maxHeight: "40rem", overflow: "auto" }}>
          <table className="table table-hover table-responsive text-center">
            <thead className="thead-light">
              <tr>
                <th scope="col">Select</th>
                <th scope="col">EMP #</th>
                <th scope="col">Full Name</th>
                <th scope="col">email</th>
              </tr>
            </thead>
            <tbody>
              {search(trainees).map((trainee, ti) => (
                <tr key={ti}>
                  <td>
                    <input
                      type="checkbox"
                      checked={traineesAdd.includes(trainee.id)}
                      onChange={() => handleCheck(trainee.id)}
                    />
                  </td>
                  <td>{trainee.id_number}</td>
                  <td>{trainee.name}</td>
                  <td>{trainee.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {traineesAdd.length > 0 && (
        <button className="default-button" onClick={submitAddTrainees}>
          Remove Trainees
        </button>
      )}

      {modalError && `ERROR: ${modalError}`}
    </div>
  )
}

const AddTraineeWindow = ({ setOpen }) => {
  const user = useSelector((state) => state.user)

  const [trainees, setTrainees] = useState([])
  const [traineesAdd, setTraineesAdd] = useState([])
  const [modalError, setModalError] = useState("")

  const [q, setQ] = useState("")
  const searchParam = [
    "id",
    "name",
    "num_tasks_overdue",
    "num_tasks_due",
    "num_tasks_completed",
    "id_number",
    "org",
    "phone_number",
    "is_verified",
  ]

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((searchP) => {
        if (item[searchP]) {
          return item[searchP]
            .toString()
            .toLowerCase()
            .includes(q.toLowerCase())
        }
        return false
      })
    })
  }

  useEffect(() => {
    axios

      .get(`all_trainees/`)

      .then((res) => {
        setTrainees(res.data)
      })

      .catch((err) => {
        setModalError(err)
      })
  }, [user.currentFocusedTeam])

  const handleCheck = (id) => {
    if (traineesAdd.includes(id)) {
      setTraineesAdd((prev) => prev.filter((p) => p !== id))
    } else {
      setTraineesAdd((prev) => [...prev, id])
    }
  }

  const submitAddTrainees = () => {
    axios
      .post(`add-trainees/?org_id=${user.currentFocusedTeam.id}`, traineesAdd)
      .then((res) => {
        setOpen(false)
      })

      .catch((err) => {
        setModalError(err)
      })
  }

  console.log(traineesAdd)
  return (
    <div>
      <div className="search-wrapper" style={{ display: "inline-block" }}>
        <label htmlFor="search-form">
          <input
            style={{ marginBottom: "16px" }}
            type="search"
            name="search-form"
            id="search-form"
            className="search-input textInput form-control"
            placeholder="Search for..."
            value={q}
            onChange={(e) => setQ(e.target.value)}
          />
          <span className="sr-only">Search names here</span>
        </label>
      </div>
      {trainees.length > 0 && (
        <div style={{ height: "40rem", overflow: "auto" }}>
          <table className="table table-hover table-responsive text-center">
            <thead className="thead-light">
              <tr>
                <th scope="col">Select</th>
                <th scope="col">EMP #</th>
                <th scope="col">Full Name</th>
                <th scope="col">email</th>
              </tr>
            </thead>
            <tbody>
              {search(trainees).map((trainee, ti) => (
                <tr key={ti}>
                  <td>
                    <input
                      type="checkbox"
                      checked={traineesAdd.includes(trainee.id)}
                      onChange={() => handleCheck(trainee.id)}
                    />
                  </td>
                  <td>{trainee.id_number}</td>
                  <td>{trainee.name}</td>
                  <td>{trainee.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {traineesAdd.length > 0 && (
        <button className="default-button" onClick={submitAddTrainees}>
          Add Trainees
        </button>
      )}

      {modalError && `ERROR: ${modalError}`}
    </div>
  )
}
const OrgEdit = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [nameLong, setNameLong] = useState(user.currentFocusedTeam.name_long)
  const [nameShort, setNameShort] = useState(user.currentFocusedTeam.name_short)
  const [passcode, setPasscode] = useState(user.currentFocusedTeam.name_code)
  const [parentId, setParentId] = useState(user.currentFocusedTeam.parentId)

  const [parentModal, setParentModal] = useState(false)
  const [mergeModal, setMergeModal] = useState(false)
  const [addTraineesModal, setAddTraineesModal] = useState(false)
  const [removeTraineesModal, setRemoveTraineesModal] = useState(false)
  const [selectedMoveMergeOrg, setSelectedMoveMergeOrg] = useState({})
  const [modalError, setModalError] = useState("")
  const handleSave = () => {
    const body = {
      id: user.currentFocusedTeam.id,
      name_long: nameLong,
      name_short: nameShort,
      name_code: passcode,
    }

    axios
      .post("/update-org/", body)
      .then(() => {
        dispatch(getProfile())
      })
      .catch((err) => {
        console.log(err.response)
      })
  }

  const handleMerge = () => {
    const body = {
      new_org_id: selectedMoveMergeOrg.id,
      current_org_id: user.currentFocusedTeam.id,
    }

    axios
      .post("/merge-org/", body)
      .then(() => {
        dispatch(getProfile())
        dispatch(setTeamID(selectedMoveMergeOrg.id))
      })
      .catch((err) => {
        setModalError(err.response.data.detail)
        console.log(err.response)
      })
  }

  const handleParent = () => {
    const body = {
      new_org_id: selectedMoveMergeOrg.id,
      current_org_id: user.currentFocusedTeam.id,
    }

    axios
      .post("/move-org/", body)
      .then(() => {
        dispatch(getProfile())
      })
      .catch((err) => {
        console.log(err.response)
        setModalError(err.response.data.detail)
      })
  }

  const handleOrgChange = (org) => {
    setSelectedMoveMergeOrg(org)
  }

  const setInitial = () => {
    setNameLong(user.currentFocusedTeam.name_long)
    setNameShort(user.currentFocusedTeam.name_short)
    setPasscode(user.currentFocusedTeam.name_code)
    setParentId(user.currentFocusedTeam.parentId)
  }

  useEffect(() => {
    setInitial()
  }, [user])

  return (
    <div>
      <Modal
        styles={modalStyles}
        open={mergeModal}
        onClose={() => {
          setMergeModal(false)
        }}
        // showCloseIcon={!inviteStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        {selectedMoveMergeOrg.name
          ? `Merging ${user.currentFocusedTeam.name} into ${selectedMoveMergeOrg.name}`
          : "Select and org below"}
        <br />
        <button className="default-button" onClick={handleMerge}>
          Save
        </button>
        <OrgChartView
          onSelect={handleOrgChange}
          // checkboxes={true}
          selected={[selectedMoveMergeOrg]}
        />
        {modalError && `ERROR: ${modalError}`}
      </Modal>

      <Modal
        styles={modalStyles}
        open={parentModal}
        onClose={() => {
          setParentModal(false)
        }}
        // showCloseIcon={!inviteStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        {selectedMoveMergeOrg.name
          ? `Moving ${user.currentFocusedTeam.name} into ${selectedMoveMergeOrg.name}`
          : "Select and org below"}
        <br />
        <button className="default-button" onClick={handleParent}>
          Save
        </button>
        <OrgChartView
          onSelect={handleOrgChange}
          // checkboxes={true}
          selected={[selectedMoveMergeOrg]}
        />
        {modalError && `ERROR: ${modalError}`}
      </Modal>

      <Modal
        styles={modalStyles}
        open={addTraineesModal}
        onClose={() => {
          setAddTraineesModal(false)
        }}
        // showCloseIcon={!inviteStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        <AddTraineeWindow setOpen={setAddTraineesModal} />
      </Modal>

      <Modal
        styles={modalStyles}
        open={removeTraineesModal}
        onClose={() => {
          setRemoveTraineesModal(false)
        }}
        // showCloseIcon={!inviteStatus === StatusEnum.loading}
        center
        focusTrapped={false}
      >
        <RemoveTraineeWindow setOpen={setRemoveTraineesModal} />
      </Modal>

      <div>
        <h5>Account Info</h5>
        <button className="default-button" onClick={handleSave}>
          Save
        </button>
        <button className="default-button" onClick={() => setParentModal(true)}>
          Change Parent
        </button>
        <button className="default-button" onClick={() => setMergeModal(true)}>
          Merge into existing organization
        </button>
        <button
          className="default-button"
          onClick={() => setAddTraineesModal(true)}
        >
          Add Trainees
        </button>

        <button
          className="default-button"
          onClick={() => setRemoveTraineesModal(true)}
        >
          Remove Trainees
        </button>
      </div>
      <FormField
        title="Name (Long Form)"
        type="text"
        value={nameLong}
        setter={setNameLong}
      />
      <FormField
        title="Name (Short Form)"
        type="text"
        value={nameShort}
        setter={setNameShort}
      />
      <FormField
        title="Passcode"
        type="text"
        value={passcode}
        setter={setPasscode}
      />
    </div>
  )
}

const AdminHome = () => {
  const [tabView, setTabView] = useState("org-edit")

  return (
    <div className="card shadow">
      <div
        className="card-header"
        style={{
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          paddingBottom: 0,
        }}
      >
        <div className="nav">
          <h4
            className={
              tabView === "org-edit"
                ? "tab-link header-text tab-link-active"
                : "tab-link header-text"
            }
            onClick={() => setTabView("org-edit")}
          >
            Edit Organization
          </h4>
          <h4
            className={
              tabView === "list"
                ? "tab-link header-text tab-link-active"
                : "tab-link header-text"
            }
            onClick={() => setTabView("list")}
          >
            Supervisor List
          </h4>
          <h4
            className={
              tabView === "grant-access"
                ? "tab-link header-text tab-link-active"
                : "tab-link header-text"
            }
            onClick={() => setTabView("grant-access")}
          >
            Grant Access
          </h4>
          <h4
            className={
              tabView === "invite"
                ? "tab-link header-text tab-link-active"
                : "tab-link header-text"
            }
            onClick={() => setTabView("invite")}
          >
            Send Invitations
          </h4>
        </div>
      </div>
      {tabView === "list" && <SupervisorListTab />}
      {tabView === "grant-access" && <GrantAccess />}
      {tabView === "invite" && <InvitationTab />}
      {tabView === "org-edit" && <OrgEdit />}
    </div>
  )
}

export default AdminHome

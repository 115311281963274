import React, { useEffect } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import axios from "axios"

// Redux
import {
  setAuthenticated,
  getProfile,
  setFirstLoadStatus,
  setTeam,
  getUtas,
} from "./redux/userSlice"
import { store } from "./redux/store"
import { useSelector } from "react-redux"

// Pages
import ProtectedRoute from "./util/ProtectedRoute"
import AdminRoute from "./util/AdminRoute"
import UnauthenticatedRoute from "./util/UnauthenticatedRoute"
import TraineeRoute from "./util/TraineeRoute"

import AdminHome from "./pages/admin/admin"

import AddAttendance from "./pages/supervisor/addAttendance"
import AddEvent from "./pages/supervisor/addEvent"
import Attendance from "./pages/supervisor/attendance"
import Communications from "./pages/supervisor/communications"
import CommunicationsLog from "./pages/supervisor/communicationsLog"
import AddCompliance from "./pages/supervisor/addCompliance"
import Compliance from "./pages/supervisor/compliance"
import DocumentsPage from "./pages/supervisor/documents"
import EditEvent from "./pages/supervisor/editEvent"
import EventDetail from "./pages/supervisor/eventDetail"
import Events from "./pages/supervisor/events"
import EventNotify from "./pages/supervisor/eventNotify"
import Dashboard from "./pages/supervisor/dashboard"
import Index from "./pages/index"
import BaseOverview from "./pages/supervisor/baseOverview"
import Log from "./pages/supervisor/log"
import ReceivedMessagesLog from "./pages/supervisor/receivedMessagesLog"
import Login from "./pages/login"
import Profile from "./pages/supervisor/traineeProfile"
import Register from "./pages/register"
import ResetPassword from "./pages/resetPassword"
import ShopAccess from "./pages/non-admin/shop-acess"
import SupervisorProfile from "./pages/supervisor/supervisorProfile"
import SupervisorChangePassword from "./pages/supervisor/supervisorChangePassword"

import TraineeHome from "./pages/trainee/traineeHome"
import TraineeUserProfile from "./pages/trainee/traineeUserProfile"
import TraineeAvailability from "./pages/trainee/traineeAvailability"
import TraineeDocuments from "./pages/trainee/traineeDocuments"
import TraineeViewSchedule from "./pages/trainee/traineeViewSchedule"
import TraineeViewTasks from "./pages/trainee/traineeViewTasks"
import Upload from "./pages/supervisor/upload"

import PageNotFound from "./pages/404"
import NonAdminRoute from "./util/NonAdminRoute"
import OrgChart from "./pages/supervisor/orgChart"
import OrgChartView from "./pages/supervisor/orgChart"
import DefaultLayout from "./layouts/defaultLayout"
import TaskTypeViewEdit from "./pages/supervisor/taskTypeViewer"
import Create from "./pages/supervisor/create"
import LandingPage from "./pages/supervisor/landingPage"

const token = localStorage.Token
const team = localStorage.Team

// Persist auth state if token exists in local storage
if (token) {
  store.dispatch(setFirstLoadStatus("loading"))
  store.dispatch(setAuthenticated())
  axios.defaults.headers.common["Authorization"] = "Bearer " + token
  setTimeout(function () {
    store
      .dispatch(getProfile())
      .then(({ payload }) => {
        // Set currentFocusedTeam if it exists in local storage
        if (team && team.length > 0) {
          if (payload.data.teams.some((e) => e.code === team)) {
            store.dispatch(setTeam(team))
            setTimeout(function () {
              store.dispatch(setFirstLoadStatus("success"))
            }, 5000)
          } else {
            localStorage.removeItem("Team")
            store.dispatch(setFirstLoadStatus("failed"))
          }
        }
      })
      .catch(() => {})
  }, 1)
} else {
  store.dispatch(setFirstLoadStatus("idle"))
}

function App() {
  const currentFocusedTeam = useSelector(
    (state) => state.user.currentFocusedTeam
  )

  // Set currentFocusedTeam to local storage on page unload
  useEffect(() => {
    const onbeforeunloadFn = () => {
      if (currentFocusedTeam && currentFocusedTeam.length > 0) {
        localStorage.setItem("Team", currentFocusedTeam)
      }
    }

    window.addEventListener("beforeunload", onbeforeunloadFn)

    return () => {
      window.removeEventListener("beforeunload", onbeforeunloadFn)
    }
  }, [currentFocusedTeam])

  return (
    <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route
            exact
            path="/login"
            element={
              <UnauthenticatedRoute>
                <Login />
              </UnauthenticatedRoute>
            }
          />
          <Route
            exact
            path="/register"
            element={
              <UnauthenticatedRoute>
                <Register />
              </UnauthenticatedRoute>
            }
          />
          <Route
            exact
            path="/reset-password"
            element={
              <UnauthenticatedRoute>
                <ResetPassword />
              </UnauthenticatedRoute>
            }
          />

          <Route
            element={
              <ProtectedRoute>
                <DefaultLayout />
              </ProtectedRoute>
            }
          >
            {/* Admin only routes */}
            <Route
              exact
              path="/admin"
              element={
                <AdminRoute>
                  <AdminHome />
                </AdminRoute>
              }
            />

            {/* Supervisor only routes */}

            <Route
              exact
              path="/communications"
              element={
                <ProtectedRoute>
                  <Communications />{" "}
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/events"
              element={
                <ProtectedRoute>
                  <Events />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/events/new"
              element={
                <ProtectedRoute>
                  <AddEvent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/event/:eventID"
              element={
                <ProtectedRoute>
                  <EventDetail />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/event/:eventID/edit"
              element={
                <ProtectedRoute>
                  <EditEvent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/event/:eventID/notify"
              element={
                <ProtectedRoute>
                  <EventNotify />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/events/new"
              element={
                <ProtectedRoute>
                  <AddEvent />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/home"
              element={
                <ProtectedRoute>
                  <LandingPage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/tasks"
              element={
                <ProtectedRoute>
                  <Log />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/profile"
              element={
                <ProtectedRoute>
                  <SupervisorProfile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/trainee/profile/:userID"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/change-password"
              element={
                <ProtectedRoute>
                  <SupervisorChangePassword />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/upload"
              element={
                <ProtectedRoute>
                  <Upload />
                </ProtectedRoute>
              }
            />

            <Route
              exact
              path="/mission-requirements"
              element={
                <ProtectedRoute>
                  <Create />
                </ProtectedRoute>
              }
            />

            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </div>
    </Router>
  )
}

export default App

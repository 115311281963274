import React, { useEffect, useState } from "react"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import EventDetailBody from "../../components/eventDetailBody"

import axios from "axios"

import { Link, useParams } from "react-router-dom"
import { setErrors } from "../../redux/userSlice"
import { useDispatch } from "react-redux"

const EventDetail = () => {
  const { eventID } = useParams()
  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (eventID && eventID.length > 0) {
      axios
        .get(`/get-event/?event_id=${eventID}`)
        .then((res) => {
          setEvent(res.data)
          setLoading(false)
        })
        .catch((err) => {
          dispatch(setErrors(["Error has occured"]))
          if (err.response.status === 404) {
            dispatch(setErrors(["An event with this id does not exist"]))
          } else if (err.response.status === 403) {
            dispatch(setErrors(["You are not authorized to view this event"]))
          } else {
            dispatch(
              setErrors([
                "An unexpected error has occured, please return to the previous page and try again",
              ])
            )
          }
          setLoading(false)
        })
    }
  }, [eventID])

  if (loading) {
    return <LoadingDashboard />
  }

  return (
    <div>
      <h1>
        {event.title}{" "}
        <Link
          className="default-button"
          to={`/event/${event.id}/edit`}
          style={{ fontSize: "1rem" }}
        >
          Edit Event
        </Link>
      </h1>
      <div style={{ margin: "1rem 0" }}>
        <EventDetailBody event={event} />
        <Link to="/events" style={{ textDecoration: "none" }}>
          <span className="default-button">Go back</span>
        </Link>
      </div>
    </div>
  )
}

export default EventDetail

import axios from "axios"
import { setErrors } from "../../redux/userSlice"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react"
import SmallLoader from "../../components/smallLoader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faArrowsAlt,
  faArrowsAltH,
  faArrowsAltV,
  faChevronRight,
  faClock,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "react-router-dom"
import ProgressBar from "../../components/progressBar"
import { Line } from "react-chartjs-2"

const TraineeCard = (props) => {
  const color =
    props.compliance < 100
      ? "var(--red)"
      : props.num_tasks_due > 0
        ? "var(--yellow)"
        : "var(--green)"

  return (
    <div style={{ minWidth: "15rem", maxWidth: "15rem" }}>
      <Link
        to={{
          pathname: `/trainee/profile/${props.traineeID}`,
          state: { name: props.name },
        }}
        style={{
          textDecoration: "none",

          color: color,
        }}
      >
        <div className="card shadow h-100">
          <div className="card-body">
            <p className="text font-weight-bold mb-1">
              {props.name.toUpperCase()}{" "}
              {props.group && `(${props.group.name.toUpperCase()})`}
            </p>

            <ProgressBar percent={props.compliance} color={color} />
          </div>
        </div>
      </Link>
    </div>
  )
}

const OrgTaskLog = ({ user, taskTypeFilters }) => {
  const [taskLog, setTaskLog] = useState([])
  const [currentPageNumber, setCurrentPageNumber] = useState(1)
  const [dataToDisplay, setDataToDisplay] = useState([])
  const [isCompleted, setIsCompleted] = useState(false)
  const TOTAL_VALUES_PER_PAGE = 8

  useEffect(() => {
    if (taskTypeFilters.length > 0) {
      setCurrentPageNumber(1)
      if (isCompleted) {
        axios
          .post(
            `/get-completed-task-log/?org_id=${user.currentFocusedTeam.id}`,
            taskTypeFilters
          )
          .then((res) => {
            setTaskLog(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        axios
          .post(
            `/get-outstanding-task-log/?org_id=${user.currentFocusedTeam.id}`,
            taskTypeFilters
          )
          .then((res) => {
            setTaskLog(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }, [isCompleted, taskTypeFilters, user.currentFocusedTeam])

  const handleViewButton = () => {
    setTaskLog([])
    setIsCompleted(!isCompleted)
  }

  const goOnPrevPage = () => {
    if (currentPageNumber === 1) return
    setCurrentPageNumber((prev) => prev - 1)
  }
  const goOnNextPage = () => {
    if (currentPageNumber === taskLog.length / TOTAL_VALUES_PER_PAGE) return
    setCurrentPageNumber((prev) => prev + 1)
  }
  const handleSelectChange = (e) => {
    setCurrentPageNumber(e.target.value)
  }
  useEffect(() => {
    const start = (currentPageNumber - 1) * TOTAL_VALUES_PER_PAGE
    const end = currentPageNumber * TOTAL_VALUES_PER_PAGE
    setDataToDisplay(taskLog.slice(start, end))
  }, [currentPageNumber, taskLog])

  return (
    <div className="card shadow ">
      <div className="card-body ">
        <div className="d-flex flex-row flex-wrap justify-content-between align-items-center">
          <h3>Task Log</h3>

          <div className="d-flex flex-row">
            <button className="default-button" onClick={handleViewButton}>
              {isCompleted ? "View Outstanding" : "View Completed"}
            </button>
            <div className="d-flex" style={{ padding: ".4rem" }}>
              <button
                className="default-button on-bar"
                style={{
                  borderRadius: "0.3rem 0 0 0.3rem ",
                  borderRight: "none",
                }}
                onClick={goOnPrevPage}
              >
                Prev
              </button>

              <button
                className="default-button on-bar "
                style={{ borderRadius: "0 0.3rem 0.3rem 0" }}
                onClick={goOnNextPage}
              >
                Next
              </button>
            </div>

            <select
              className="m-1"
              onChange={handleSelectChange}
              value={currentPageNumber}
            >
              {taskLog && taskLog.length > 0 ? (
                Array.from(
                  Array(Math.ceil(taskLog.length / TOTAL_VALUES_PER_PAGE))
                )
                  .map((e, i) => i + 1)
                  .map((val) => {
                    return <option key={val}>{val}</option>
                  })
              ) : (
                <option key={1}>{1}</option>
              )}
            </select>
          </div>
        </div>
        {taskLog && taskLog.length > 0 ? (
          <div>
            <table className="table table-hover ">
              <thead className="thead-light">
                <tr>
                  {/*<th scope="col">EMP #</th>*/}
                  <th scope="col">Full Name</th>
                  <th scope="col">Org</th>
                  {/*<th scope="col">Organization</th>*/}
                  <th scope="col">Task</th>
                  {/*<th scope="col">Completed By</th>*/}
                  <th scope="col">
                    {isCompleted ? "Date Completed" : "Due Date"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataToDisplay.map((task, ti) => {
                  return (
                    <tr key={ti} style={{ fontSize: ".7rem" }}>
                      {/*<td>{task.trainee.id_number}</td>*/}
                      <td>{task.trainee.name}</td>
                      {/*<td>{task.org}</td>*/}
                      <td>{task.org.join(", ")}</td>
                      <td>{task.task_type.name_long}</td>

                      {/*<td>{task.author ? task.author : "Trainee"}</td>*/}
                      <td>
                        {isCompleted
                          ? new Date(task.completed_date).toLocaleDateString(
                              "en-US",
                              {
                                hour12: false,
                              }
                            )
                          : new Date(task.due_date).toLocaleDateString(
                              "en-US",
                              {
                                hour12: false,
                              }
                            )}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <SmallLoader />
        )}
      </div>
    </div>
  )
}
const TeamOverview = ({
  currentTeamTrainees,
  handleTraineeComparatorChange,
  isTraineeCompareByName,
  ...rest
}) => {
  const [q, setQ] = useState("")
  const searchParam = ["name"]

  const filteredTrainees = currentTeamTrainees.filter(
    (item) => item.name.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
  )

  return (
    <div className="card-grey  shadow ">
      <div className="card-body ">
        <div className="d-flex flex-row justify-content-between flex-wrap align-items-center">
          <h2>Team Overview</h2>
          <div className="d-flex flex-row">
            <button className="default-button">
              <FontAwesomeIcon icon={faCommentDots} /> Message
            </button>
            <button className="default-button">
              <FontAwesomeIcon icon={faClock} /> Schedule
            </button>
            <button
              className="default-button "
              onClick={() =>
                handleTraineeComparatorChange(!isTraineeCompareByName)
              }
            >
              <FontAwesomeIcon icon={faArrowsAltV} />{" "}
              {isTraineeCompareByName ? "Sort by Readiness" : "Sort by Name"}
            </button>
            <input
              type="search"
              name="search-form"
              id="search-form"
              style={{
                margin: ".4rem",
                borderRadius: ".25rem",
                borderWidth: "1px",
                padding: ".2rem",
              }}
              placeholder="Search for..."
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </div>
        </div>

        <div className="flex-column p-2 overflow-auto">
          <div className="container-fluid fade-in">
            <br />
            <div className="row justify-content-evenly">
              {filteredTrainees.map((trainee) => (
                <TraineeCard
                  name={trainee.name}
                  group={trainee.group}
                  compliance={trainee.compliance_perc}
                  traineeID={trainee.id}
                  key={trainee.id}
                  num_tasks_due={trainee.num_tasks_due}
                />
              ))}
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  )
}
const Historical = ({ taskTypeFilters }) => {
  const user = useSelector((state) => state.user)
  const [chartData, setChartData] = useState({})
  const [historicalData, setHistoricalData] = useState({})
  const [percType, setPercType] = useState(true)

  useEffect(() => {
    setHistoricalData({})

    if (taskTypeFilters.length > 0) {
      axios
        .post(
          `get-historical-readiness/?org_id=${user.currentFocusedTeam.id}`,
          taskTypeFilters
        ) //HELLO
        .then((res) => {
          setHistoricalData(res.data)
        })
        .catch((err) => {
          setHistoricalData({})
        })
    }
  }, [taskTypeFilters, user.currentFocusedTeam])

  useEffect(() => {
    if (Object.values(historicalData).length > 0) {
      const perc_type = percType ? "perc" : "perc_deployable"
      const descript = percType ? "Readiness" : "Deployable Percentage"
      const newChartData = {
        labels: [
          ...historicalData.historical.map((v) => v.dt),
          ...historicalData.projected.map((v, i) => v.dt),
        ],
        datasets: [
          {
            label: `Historical ${descript}`,
            data: historicalData.historical.map((v) => v[perc_type].toFixed(1)),
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: `Projected ${descript}`,
            data: [
              ...Array(historicalData.historical.length).fill(null),
              ...historicalData.projected.map((v) => v[perc_type].toFixed(1)),
            ],
            borderColor: "rgb(255, 99, 132)",
            borderDash: [2, 3],
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
          {
            label: "Connect",
            pointRadius: 0,
            pointHitRadius: 0,
            data: [
              ...historicalData.historical.map((v, i) => {
                if (i == historicalData.historical.length - 1) {
                  return v[perc_type].toFixed(1)
                }
                return null
              }),
              ...historicalData.projected.map((v, i) => {
                if (i == 0) {
                  return v[perc_type].toFixed(1)
                }
                return null
              }),
            ],
            borderColor: "rgb(255, 99, 132)",
            borderDash: [2, 3],
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      }

      setChartData(newChartData)
    }
  }, [historicalData, percType])

  return (
    <div className="card shadow  ">
      <div className="card-body text ">
        <h4>Historical and Projected Readiness</h4>
        {/*<button*/}
        {/*  className="default-button"*/}
        {/*  value={percType}*/}
        {/*  onClick={() => setPercType(!percType)}*/}
        {/*>*/}
        {/*  {percType*/}
        {/*    ? "Show Percentage of Personnel Fully Compliant"*/}
        {/*    : "Show Average Compliance"}*/}
        {/*</button>*/}
        <div style={{ height: "15rem" }}>
          {Object.values(chartData).length > 0 ? (
            <Line
              data={chartData}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                  x: {
                    ticks: {
                      maxTicksLimit: 10,
                    },
                  },
                  y: {
                    // min: 0,
                    // max: 100,
                    ticks: {
                      // Include a dollar sign in the ticks
                      callback: function (value, index, ticks) {
                        return value + "%"
                      },
                    },
                  },
                },

                plugins: {
                  legend: {
                    display: false,
                  },

                  // title: {
                  //   display: true,
                  //   text: percType
                  //     ? "Historical and Projected Readiness Trends"
                  //     : "Historical and Projected Deployable Trends",
                  // },
                },
              }}
            />
          ) : (
            <SmallLoader />
          )}
        </div>
      </div>
    </div>
  )
}
const SubDropDownTaskType = ({ name, color, trainees }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="p-2">
      <div
        className=" d-flex flex-row align-items-center gap-2 "
        onClick={() => setIsOpen(!isOpen)}
        style={{ color: color }}
      >
        <div className="">
          <h1 style={{ fontSize: ".9rem", margin: 0 }}>
            <b>
              {name} ({trainees.length})
            </b>{" "}
          </h1>
        </div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className="sidebar-icon "
          // onClick={() => setIsOpen(!isOpen)}
          style={!isOpen ? {} : { transform: "rotate(90deg)" }}
        />
      </div>

      {isOpen && (
        <div className="d-flex flex-row flex-wrap p-3 ">
          {trainees.map((t) => (
            <Link
              to={{
                pathname: `/trainee/profile/${t.id}`,
              }}
              style={{ textDecoration: "none" }}
              className="m-1"
            >
              <div
                className="card  h-100 p-1 "
                style={{ fontSize: ".6rem", color: color }}
              >
                {t.name}
              </div>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

const TypeCatCard = ({
  catName,
  perc,
  percDeployable,
  taskTypes,
  filterSetter,
  currentFilters,
}) => {
  const [isOpen, setIsOpen] = useState(taskTypes.length > 1)
  const [checked, setChecked] = useState(false)

  const handleClick = () => {
    if (checked) {
      filterSetter((prev) => [
        ...prev.filter((v) => !taskTypes.map((t) => t.id).includes(v)),
      ])
    }
    if (!checked) {
      filterSetter((prev) => [...prev, ...taskTypes.map((t) => t.id)])
    }
    setChecked(!checked)
  }

  useEffect(() => {
    setChecked(
      taskTypes.map((t) => t.id).every((item) => currentFilters.includes(item))
    )
  }, [currentFilters])

  return (
    <div>
      <div className="card shadow">
        <div className="card-body d-flex flex-row align-items-center gap-2">
          <input type="checkbox" checked={checked} onChange={handleClick} />
          <div
            className=" d-flex flex-row align-items-center justify-content-between gap-2"
            style={{ flex: "1 1 auto" }}
            onClick={() => setIsOpen(!isOpen)}
          >
            <div className="w-100 d-flex flex-row gap-2 flex-wrap justify-content-between">
              <h2 style={{ fontSize: "1.1rem", margin: 0, width: "40%" }}>
                <b>{catName}</b>{" "}
              </h2>
              <div className="w-50">
                <ProgressBar percent={perc} color={"var(--green)"} />
              </div>
              {/*Percentage of Personnel Fully Compliant*/}
              {/*<ProgressBar percent={percDeployable} color={"var(--green)"} />*/}
            </div>
            <FontAwesomeIcon
              icon={faChevronRight}
              className="sidebar-icon "
              // onClick={() => setIsOpen(!isOpen)}
              style={!isOpen ? {} : { transform: "rotate(90deg)" }}
            />
          </div>
        </div>

        {isOpen && (
          <div>
            {taskTypes.length > 1 ? (
              <div>
                <div className="d-flex flex-row flex-wrap align-items-center ps-3">
                  <div style={{ fontSize: ".8rem", margin: 0, width: "60%" }}>
                    Personnel Fully Compliant
                  </div>
                  <div style={{ width: "30%" }}>
                    <ProgressBar
                      percent={percDeployable}
                      color={"var(--green)"}
                    />
                  </div>
                </div>
                <div
                  className="ms-5"
                  style={{ maxHeight: "20rem", overflow: "auto" }}
                >
                  <hr />
                  {taskTypes.map((taskType) => (
                    <div key={taskType.id}>
                      <TaskTypeCard
                        taskType={taskType}
                        filterSetter={filterSetter}
                        checked={currentFilters.includes(taskType.id)}
                      />
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <TaskTypeComplianceDropdowns taskType={taskTypes[0]} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

const TaskTypeCard = ({ taskType, checked, filterSetter }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleCheck = () => {
    if (!checked) {
      filterSetter((prevState) => [taskType.id, ...prevState])
    }
    if (checked) {
      filterSetter((prevState) =>
        prevState.filter((item) => item !== taskType.id)
      )
    }
  }
  return (
    <div className="w-100 ">
      <div className="d-flex flex-row align-items-center gap-2">
        <input type="checkbox" checked={checked} onChange={handleCheck} />
        <div
          className="d-flex flex-row align-items-center justify-content-between gap-2"
          onClick={() => setIsOpen(!isOpen)}
          style={{ flex: "1 1 auto" }}
        >
          <div className="w-100 d-flex flex-row gap-2 flex-wrap justify-content-between align-items-center">
            <h4 style={{ fontSize: ".8rem", margin: 0, width: "80%" }}>
              <b>{taskType.name}</b>{" "}
            </h4>
            {taskType.perc.toFixed()}%{/*<div className="w-50">*/}
            {/*  <ProgressBar percent={taskType.perc} color={"var(--green)"} />*/}
            {/*</div>*/}
            {/*Percentage of Personnel Fully Compliant*/}
            {/*<ProgressBar percent={percDeployable} color={"var(--green)"} />*/}
          </div>
          <FontAwesomeIcon
            icon={faChevronRight}
            className="sidebar-icon "
            // onClick={() => setIsOpen(!isOpen)}
            style={!isOpen ? {} : { transform: "rotate(90deg)" }}
          />
        </div>
      </div>
      {isOpen && <TaskTypeComplianceDropdowns taskType={taskType} />}
    </div>
  )
}

const TaskTypeComplianceDropdowns = ({ taskType }) => {
  return (
    <div>
      <SubDropDownTaskType
        name={"Not Assigned"}
        trainees={taskType.not_assigned}
        color={"var(--green)"}
      />
      <SubDropDownTaskType
        name={"Compliant"}
        trainees={taskType.compliant}
        color={"var(--green)"}
      />
      <SubDropDownTaskType
        name={"Coming Due"}
        trainees={taskType.coming_due}
        color={"var(--yellow)"}
      />
      <SubDropDownTaskType
        name={"Over Due"}
        trainees={taskType.overdue}
        color={"var(--red)"}
      />
    </div>
  )
}
const compTraineeByName = (a, b) => {
  if (a.name === b.name) {
    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0
  }

  return a.name > b.name ? 1 : -1
}

const compTraineeByTasks = (a, b) => {
  if (a.compliance_perc !== b.compliance_perc) {
    return a.compliance_perc < b.compliance_perc ? -1 : 1
  }
  return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
}

const OrgProgress = ({ percCompliant, percDeployable, numTrainees }) => {
  return (
    <div className="card shadow h-100">
      <div className="card-body h-100">
        <div className="row no-gutters align-items-center">
          <div className="col mr-2">
            <h4 className="font-weight-bold   mb-1">
              Fully Compliant:{" "}
              {((percDeployable / 100) * numTrainees).toFixed()}/{numTrainees}{" "}
              Trainees
            </h4>
            Average Compliance
            <ProgressBar
              percent={Math.round(percCompliant)}
              color="var(--gray-500)"
            />
            {/*Percentage of Personnel Fully Compliant*/}
            {/*<ProgressBar*/}
            {/*  percent={Math.round(percDeployable)}*/}
            {/*  color="var(--gray-500)"*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </div>
  )
}
const TaskOverview = ({ teamTasks, setTaskTypeFilters, taskTypeFilters }) => {
  const handleSelectAll = () => {
    if (taskTypeFilters.length === 0) {
      setTaskTypeFilters(
        Object.values(teamTasks).reduce(
          (prev, value) => [...prev, ...value.children.map((c) => c.id)],
          []
        )
      )
    } else {
      setTaskTypeFilters([])
    }
  }
  return (
    <div className="card-grey  shadow p-3">
      <div className="d-flex flex-row justify-content-between flex-wrap">
        <h2>Task Overview</h2>
        <div className="d-flex flex-row">
          <button className="default-button" onClick={handleSelectAll}>
            {taskTypeFilters.length > 0 ? "Unselect All" : "Select All"}
          </button>
          <button className="default-button">
            <FontAwesomeIcon icon={faCommentDots} /> Message
          </button>
          <button className="default-button">
            <FontAwesomeIcon icon={faClock} /> Schedule
          </button>
          <button className="default-button ">
            <FontAwesomeIcon icon={faArrowsAltV} /> Sort
          </button>
        </div>
      </div>
      <div
      // className="card shadow"
      // style={{ borderLeft: `4px solid black` }}
      >
        <div className="card-body ">
          {teamTasks && Object.values(teamTasks).length > 0 ? (
            <div className="d-flex flex-row flex-wrap">
              {Object.entries(teamTasks).map(([key, value]) => (
                <div className="col-6 p-2" key={key}>
                  <TypeCatCard
                    catName={key}
                    perc={value.perc}
                    percDeployable={value.perc_deployable}
                    taskTypes={value.children}
                    filterSetter={setTaskTypeFilters}
                    currentFilters={taskTypeFilters}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div>There are currently no tasks in this team</div>
          )}
        </div>
      </div>
    </div>
  )
}
const LandingPage = () => {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const [teamTasks, setTeamTasks] = useState([])

  const [isTraineeCompareByName, setIsTraineeCompareByName] = useState(false)
  const [currentTeamTrainees, setCurrentTeamTrainees] = useState([])

  const [currentTeam, setCurrentTeam] = useState({})

  const [percCompliant, setPercCompliant] = useState(0)
  const [numTrainees, setNumTrainees] = useState(0)
  const [percDeployable, setPercDeployable] = useState(0)

  const [taskTypeFilters, setTaskTypeFilters] = useState([])
  const [overviewView, setOverviewView] = useState("tasks")

  useEffect(() => {
    setTaskTypeFilters([])
    setTeamTasks([])
    setCurrentTeamTrainees([])

    setCurrentTeam([])

    axios
      .get(`trainees-overview/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setCurrentTeam(res.data)
        const sum = res.data.reduce((a, b) => a + b.compliance_perc, 0)
        const avg = sum / res.data.length || 0
        setPercCompliant(avg)

        const sum_fully = res.data.reduce(
          (a, b) => a + (b.compliance_perc === 100 ? 1 : 0),
          0
        )
        const avg_full = (sum_fully / res.data.length || 0) * 100
        setPercDeployable(avg_full)

        setNumTrainees(res.data.length)
      })

      .catch((err) => {
        dispatch(err)
      })

    axios
      .get(`/tasks-overview/?org_id=${user.currentFocusedTeam.id}`)
      // .get(`/tasks-overview/25`, { baseURL: "http://localhost:8000" })
      .then((res) => {
        // setTeamTasks(res.data.sort(compTasks))
        setTeamTasks(res.data)
        setTaskTypeFilters(
          [].concat.apply(
            [],
            Object.values(res.data).map((value) =>
              value.children.map((c) => c.id)
            )
          )
        )
      })
      .catch((err) => {
        console.log(err)

        dispatch(
          setErrors(["An unexpected error has occured, please reload the page"])
        )
      })
  }, [dispatch, user.currentFocusedTeam.id])

  // Sort trainees by name on load
  useEffect(() => {
    if (Array.isArray(currentTeam)) {
      if (isTraineeCompareByName) {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByName))
      } else {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByTasks))
      }
    }
  }, [currentTeam, isTraineeCompareByName])

  // Handler function for changing sorting methods
  const handleTraineeComparatorChange = (isName) => {
    if (isName) {
      setIsTraineeCompareByName(true)
      if (Array.isArray(currentTeam)) {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByName))
      }
    } else {
      setIsTraineeCompareByName(false)
      if (Array.isArray(currentTeam)) {
        setCurrentTeamTrainees([...currentTeam].sort(compTraineeByTasks))
      }
    }
  }

  return (
    <div>
      <h2>{user.currentFocusedTeam.name}</h2>
      <div className="d-flex flex-row justify-content-between flex-wrap">
        <div className="d-flex flex-row ">
          <div className="d-flex" style={{ padding: ".4rem" }}>
            <button
              className={
                overviewView === "tasks"
                  ? "default-button on-bar default-button-active"
                  : "default-button on-bar"
              }
              style={{
                borderRadius: "0.3rem 0 0 0.3rem ",
                borderRight: "none",
              }}
              onClick={() => setOverviewView("tasks")}
            >
              Tasks
            </button>

            <button
              className={
                overviewView === "members"
                  ? "default-button on-bar default-button-active "
                  : "default-button on-bar "
              }
              style={{ borderRadius: "0 0.3rem 0.3rem 0" }}
              onClick={() => setOverviewView("members")}
            >
              Members
            </button>
          </div>
          <button className="default-button">Start Date</button>
          <button className="default-button">End Date</button>
          <button className="default-button">Filter</button>
        </div>

        <div className="d-flex flex-row">
          <button className="default-button">Share</button>
          <button className="default-button">Invite to Team</button>
        </div>
      </div>
      <div className="container-fluid fade-in d-flex flex-row flex-wrap h-100 align-items-start">
        <div className="d-flex flex-column gap-1 col-12 col-lg-7 p-1">
          {overviewView == "tasks" && (
            <TaskOverview
              teamTasks={teamTasks}
              setTaskTypeFilters={setTaskTypeFilters}
              taskTypeFilters={taskTypeFilters}
            />
          )}
          {overviewView == "members" && (
            <div
            // style={{ maxHeight: "40rem", overflow: "auto" }}
            // style={{ maxHeight: "inherit" }}
            >
              <TeamOverview
                currentTeamTrainees={currentTeamTrainees}
                handleTraineeComparatorChange={handleTraineeComparatorChange}
                isTraineeCompareByName={isTraineeCompareByName}
              />
            </div>
          )}
        </div>
        <div className="col-12 col-lg-5 p-1  ">
          <div className="card-grey  shadow p-3 d-flex flex-column  gap-2  ">
            <div style={{}}>
              <OrgProgress
                percCompliant={percCompliant}
                percDeployable={percDeployable}
                numTrainees={numTrainees}
                name={user.currentFocusedTeam.name}
              />
            </div>

            <Historical taskTypeFilters={taskTypeFilters} />

            <div
            // style={{ maxHeight: "30rem", overflow: "auto" }}
            >
              <OrgTaskLog user={user} taskTypeFilters={taskTypeFilters} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage

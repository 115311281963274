import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"

import axios from "axios"

import DefaultLayout from "../../layouts/defaultLayout"

// Components
import { Modal } from "react-responsive-modal"
import { modalStyles } from "../../styles/constanStyles"

import SearchTraineeListItem from "../../components/searchTraineeListItem"

// Redux
import { useSelector, useDispatch } from "react-redux"
import { setTeam as reduxSetTeam } from "../../redux/userSlice"
import { format } from "date-fns"

const AddEvent = () => {
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [currentDate, setCurrentDate] = useState("")

  const [searchError, setSearchError] = useState("")
  const [submitError, setSubmitError] = useState("")
  const [loading, setLoading] = useState(false)

  const [modalOpen, setModalOpen] = useState(false)

  // Input states
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [location, setLocation] = useState("")
  const [recurring, setRecurring] = useState("")
  const [recurringSpacing, setRecurringSpacing] = useState(1)
  const [recurEndsOn, setRecurEndsOn] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [allDay, setAllDay] = useState(false)
  const [capacity, setCapacity] = useState(null)
  const [color, setColor] = useState("#000000")

  const [trainees, setTrainees] = useState([])

  // Trainee Search States
  const [availableTrainees, setAvailableTrainees] = useState([])
  const [conflictTrainees, setConflictTrainees] = useState([])
  const [searched, setSearched] = useState(false)

  const handleDateChange = (event, setter) => {
    // Get the input value
    const inputValue = event

    // Create a new Date object from the input value
    // Note: inputValue is in the format "YYYY-MM-DDTHH:MM"
    const date = new Date(inputValue)

    // Optionally: Set to the local timezone
    // If the input is invalid, we can set the state to the current date
    if (!isNaN(date)) {
      setter(date)
    } else {
      setter(new Date()) // Reset to current date if invalid
    }
  }
  // Get current date

  useEffect(() => {
    const d = new Date()
    let month = "" + (d.getMonth() + 1)
    let day = "" + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    setCurrentDate([year, month, day].join("-"))
  })

  const clearSearch = () => {
    setSearchError("")
    setSearched("")
    setTrainees([])
    setAvailableTrainees([])
    setConflictTrainees([])
    setSubmitError("")
  }

  const clearSearchWithError = (error) => {
    clearSearch()
    setSearchError(error)
    setLoading(false)
  }

  const handleSearch = (e) => {
    console.log("HERE")
    e.preventDefault()
    clearSearch()
    setLoading(true)

    // Validate form
    if (
      !startDate ||
      startDate.length <= 0 ||
      !endDate ||
      endDate.length <= 0
    ) {
      clearSearchWithError("Please enter a date")
      return
    }
    if (!allDay && startDate > endDate) {
      clearSearchWithError(
        "Please make sure the start time is before the end time"
      )
      return
    }

    if (recurring !== "") {
      if (recurringSpacing < 1) {
        clearSearchWithError(
          'Please enter a positive integer under "Repeat Every"'
        )
        return
      }
      if (recurEndsOn < startDate) {
        clearSearchWithError(
          "Please make sure the recurring events end after the start date"
        )
        return
      }
    }

    console.log(endDate)

    axios
      .get(
        `/search-trainees-conflict/?org_id=${user.currentFocusedTeam.id}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
      )
      .then((res) => {
        setSearched(true)
        setAvailableTrainees(res.data.available_trainees)
        setConflictTrainees(res.data.conflict_trainees)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        clearSearch()
        setSearchError(
          "An unexpected error has occured, please reload and try again"
        )
        setLoading(false)
      })
  }

  // Open review modal
  const handleReview = (e) => {
    e.preventDefault()
    setSubmitError("")

    // Validate form
    if (!title || title.length <= 0) {
      setSubmitError("Please enter a title")
      return
    }

    setModalOpen(true)
  }

  // Post create event
  const handleSubmit = () => {
    setLoading(true)
    let traineesList = []
    // Get trainee ids
    if (trainees && trainees.length > 0) {
      trainees.map((item) => {
        traineesList.push(item.id)
      })
    }

    const body = {
      title: title,

      description,
      start_datetime: startDate,
      end_datetime: endDate,
      all_day: allDay,
      org_id: user.currentFocusedTeam.id,
      capacity: capacity && capacity !== 0 ? capacity : 0,
      color,
      location,
      trainee_ids: trainees.map((t) => t.id),
    }

    axios
      .post("/create-event/", body)
      .then(() => {
        navigate("/events")
      })
      .catch((err) => {
        console.log(err.response)
        setSubmitError("An error has occured please try again")
        setLoading(false)
      })
  }

  const AddHourToTime = (time) => {
    if (time.substring(0, 2) !== "23") {
      return (parseInt(time.substring(0, 2)) + 1).toString() + time.substring(2)
    }
    return "00" + time.substring(2)
  }

  return (
    <div className="container-fluid">
      <h1 className="title">Schedule New Event</h1>
      <div>
        <div className="row">
          <div className="col-5">
            <form className="search-form" onSubmit={handleSearch}>
              <p>Title*</p>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="textinput textInput form-control"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </div>

              <p>Description</p>
              <div className="input-group mb-3">
                <textarea
                  rows={10}
                  type="text"
                  className="form-control form-control-user textarea"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <p>Location (Optional)</p>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="textinput textInput form-control"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>

              <div className="form-row row">
                <div className="form-group col-md-6">
                  <p>Start Date*</p>
                  <input
                    type="datetime-local"
                    className="textinput textInput form-control"
                    value={format(startDate, "yyyy-MM-dd'T'HH:mm")}
                    onChange={(e) => {
                      handleDateChange(e.target.value, setStartDate)
                    }}
                    min={currentDate}
                    required={!allDay}
                  />
                </div>
              </div>
              <br />

              <div className="form-row row">
                <div className="form-group col-md-6">
                  <p>End Date*</p>
                  <input
                    type="datetime-local"
                    className="textinput textInput form-control"
                    value={format(endDate, "yyyy-MM-dd'T'HH:mm")}
                    onChange={(e) => {
                      handleDateChange(e.target.value, setEndDate)
                    }}
                    min={currentDate}
                    required={!allDay}
                  />
                </div>
              </div>
              <br />

              <div className="form-row row">
                <div className="forg-group">
                  <label>
                    <input
                      type="checkbox"
                      value={allDay}
                      onChange={(e) => {
                        setAllDay(e.target.checked)
                      }}
                    />{" "}
                    All Day
                  </label>
                </div>
              </div>
              <br />

              <div className="form-row row">
                <div className="form-group col-md-6">
                  <p>Capacity</p>
                  <input
                    type="number"
                    className="textinput textInput form-control"
                    value={capacity}
                    onChange={(e) => {
                      setCapacity(e.target.value)
                    }}
                    min={0}
                  />
                </div>

                <div className="form-group col-md-6">
                  <p>Color</p>
                  <input
                    type="color"
                    className="textinput textInput form-control"
                    value={color}
                    onChange={(e) => {
                      setColor(e.target.value)
                    }}
                    required
                    list={[]}
                  />
                </div>
              </div>
              <br />

              {searchError && searchError.length > 0 && (
                <div className="h6 text-center text-danger">{searchError}</div>
              )}

              <button
                className="default-button"
                type="submit"
                style={{ marginRight: "0.25rem" }}
                disabled={loading}
                onClick={handleSearch}
              >
                Search Trainees
              </button>
              <Link
                type="button"
                className="default-button default-button-red"
                to="/events"
              >
                Cancel
              </Link>
              {searched && (
                <a
                  className="default-button default-button-red"
                  style={{ float: "right" }}
                  onClick={() => setTrainees([])}
                >
                  Clear Added Trainees
                </a>
              )}

              {searched && (
                <button
                  className="default-button"
                  style={{ marginTop: "1rem" }}
                  onClick={handleReview}
                >
                  Review and Submit
                </button>
              )}

              <br />
            </form>
          </div>
          <br />
          <div className="col-6">
            <div className="container-fluid">
              <h5>Search Result</h5>
              <ul className="list-group conflict-list">
                <li className="list-group-item list-group-item-danger">
                  <strong> Trainees with time conflict </strong>
                  {searched &&
                    conflictTrainees &&
                    conflictTrainees.length > 0 && (
                      <button
                        className="btn btn-outline-primary btn-sm float-right add-all"
                        onClick={() => setTrainees(conflictTrainees)}
                      >
                        Add All
                      </button>
                    )}
                </li>
                {searched &&
                  conflictTrainees &&
                  conflictTrainees.length > 0 &&
                  conflictTrainees.map((trainee) => {
                    return (
                      <SearchTraineeListItem
                        trainee={trainee}
                        setTrainees={setTrainees}
                        changeTrainees={trainees}
                        key={trainee.id}
                      />
                    )
                  })}
                {!searched && (
                  <li className="list-group-item"> Please search first </li>
                )}
              </ul>
              <br />
              <ul className="list-group available-list">
                <li className="list-group-item list-group-item-success">
                  <strong> Trainees without time conflict </strong>
                  {searched &&
                    availableTrainees &&
                    availableTrainees.length > 0 && (
                      <button
                        className="btn btn-outline-primary btn-sm float-right add-all"
                        onClick={() => setTrainees(availableTrainees)}
                      >
                        Add All
                      </button>
                    )}
                </li>
                {searched &&
                  availableTrainees &&
                  availableTrainees.length > 0 &&
                  availableTrainees.map((trainee) => {
                    return (
                      <SearchTraineeListItem
                        trainee={trainee}
                        setTrainees={setTrainees}
                        changeTrainees={trainees}
                        key={trainee.id}
                      />
                    )
                  })}
                {!searched && (
                  <li className="list-group-item"> Please search first </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        styles={modalStyles}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        center
      >
        <h5 className="modal-title">
          Are you sure you want to add this event?
        </h5>
        <hr />
        <p className="modal-subtitle">
          <b>Title: </b>
          {title}
        </p>
        <p className="modal-subtitle">
          <b>Description: </b>
          {description}
        </p>
        {startDate === endDate ? (
          <p className="modal-subtitle">
            <b>Date: </b>
            {startDate.toLocaleString()}
          </p>
        ) : (
          <p className="modal-subtitle">
            <b>Date: </b>
            {startDate.toLocaleString()} to {endDate.toLocaleString()}
          </p>
        )}
        {allDay && (
          <p className="modal-subtitle">
            <b>Time: </b>
            All day
          </p>
        )}

        {location && location.length > 0 && (
          <p className="modal-subtitle">
            <b>Location: </b>
            {location}
          </p>
        )}

        {capacity && capacity !== "0" ? (
          <p className="modal-subtitle">
            <b>Capacity: </b>
            {capacity}
          </p>
        ) : (
          <p className="modal-subtitle">
            <p>
              <b>Capacity: </b>
              Unlimited
            </p>
          </p>
        )}
        <p className="modal-subtitle">
          <b>Trainees: </b>
          {trainees.map((item) => {
            return <div key={item.id}>{item.name}</div>
          })}
          {trainees.length < 1 && "None"}
        </p>

        {submitError && submitError.length > 0 && (
          <div
            className="h6 text-center text-danger"
            style={{ margin: "1rem 0 0 0" }}
          >
            {submitError}
          </div>
        )}
        <br />
        <button
          className="btn btn-primary submit-event float-right"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => handleSubmit()}
          disabled={loading}
        >
          Confirm
        </button>
        <button
          className="btn btn-secondary float-right"
          onClick={() => setModalOpen(false)}
          disabled={loading}
        >
          Cancel
        </button>
      </Modal>
    </div>
  )
}

export default AddEvent

import React, { useState } from "react"
import PropTypes from "prop-types"

// Components
import Footer from "../components/footer"
import Header from "../components/header"
import Sidebar from "../components/sidebar"
import ProgressBar from "../components/progressBar"

import ScaleLoader from "react-spinners/ScaleLoader"

// Redux
import { useSelector } from "react-redux"
import { Link, Outlet } from "react-router-dom"

const DefaultLayout = ({
  children,
  disableTeams,
  currentPage,
  progressBar,
  allowNoTeamSelection,
}) => {
  // Adds layout to dashboard
  const user = useSelector((state) => state.user)

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header currentPage={currentPage} progressBar={progressBar} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          overflow: "auto",
        }}
      >
        {(user.status === "idle" || user.status === "success") && (
          <Sidebar disableTeams={!!disableTeams} />
        )}

        {/* Loading spinner if status is loading*/}

        {user.status === "loading" && (
          <main className="content">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "2rem",
              }}
            >
              <ScaleLoader color="black" loading={true} size={75} />
            </div>
          </main>
        )}
        <div className="d-flex flex-column w-100">
          <main className="content fade-in flex-grow-1">
            {/* Info message if no team is selected */}

            {(user.status === "idle" || user.status === "success") &&
              (!user.currentFocusedTeam ||
                user.currentFocusedTeam.length === 0) &&
              !allowNoTeamSelection && (
                <h4 style={{ textAlign: "center" }}>
                  No team is currently selected, please use the sidebar to
                  select a team or request access to a team in{" "}
                  <Link to="/shop-access" style={{ textDecoration: "none" }}>
                    shop access settings
                  </Link>
                  .
                </h4>
              )}
            {/* Main Content */}

            {(user.status === "idle" || user.status === "success") &&
              (user.currentFocusedTeam || allowNoTeamSelection) && (
                <>
                  <Outlet />
                </>
              )}

            {/* Error message */}
            {user.status === "failed" && (
              <h4 style={{ textAlign: "center" }}>
                An unexpected error has occured, please reload the page
              </h4>
            )}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout

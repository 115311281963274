import { useState } from "react"

const isSupervisor = (msg) => {
  if (!("finishedTask" in msg)) {
    return true
  } else {
    return false
  }
}

const hasTask = (msg) => {
  if ("finishedTask" in msg && msg.finishedTask !== "N/A") {
    return true
  } else {
    return false
  }
}

const SingleChat = ({ messaging, trainee }) => {
  const [newMessage, setNewMessage] = useState("")

  return (
    <div style={{ width: "100%" }}>
      <div className="messages">
        {messaging &&
          messaging.map((msg, i) => (
            <div style={{ display: "flex" }} key={i}>
              <span
                style={{
                  backgroundColor: !msg.was_received_msg
                    ? "#BEE3F8"
                    : "#B9F5D0",
                  marginLeft: !msg.was_received_msg ? "auto" : 33,
                  marginRight: !msg.was_received_msg ? 33 : "auto",
                  marginTop: !msg.was_received_msg ? 3 : 10,
                  borderRadius: "20px",
                  padding: "5px 15px",
                  whiteSpace: "pre-wrap",
                  maxWidth: "50%",
                }}
              >
                {msg.message}
              </span>

              <sub
                style={{
                  padding: "5px 15px",
                }}
              >
                {new Date(msg.datetime_sent).toLocaleString("en-US", {
                  hour12: false,
                })}
              </sub>
            </div>
          ))}
      </div>
    </div>
  )
}

export default SingleChat

export const modalStyles = {
  modal: {
    borderRadius: "0.25rem",
    width: "90%",
    maxWidth: "60rem",
  },
}

export const modalStylesDanger = {
  modal: {
    borderRadius: "0.25rem",
    width: "90%",
    maxWidth: "500px",
    border: "2px solid red",
  },
}

export const modalStylesWarning = {
  modal: {
    borderRadius: "0.25rem",
    width: "90%",
    maxWidth: "500px",
    backgroundColor: "var(--light-yellow)",
  },
}

import React, { useState } from "react"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEdit,
  faTrash,
  faInfoCircle,
  faBell,
} from "@fortawesome/free-solid-svg-icons"

import { Link } from "react-router-dom"

import Modal from "react-responsive-modal"
import { modalStylesDanger } from "../styles/constanStyles"

import axios from "axios"
import { eventTimeFormatted } from "../pages/supervisor/events"

const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const EventCard = (props) => {
  const { event } = props

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const [modalError, setModalError] = useState("")
  const [loading, setLoading] = useState(false)

  // Days of the week

  const handleDelete = () => {
    setLoading(true)
    setModalError(false)
    axios
      .delete(`/events/?event_id=${event.id}`)
      .then((res) => {
        props.getEvents()
        setDeleteModalOpen(false)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.response)
        setModalError("An error has occured, please try again")
        setLoading(false)
      })
  }

  const handleDeleteModalOpen = () => {
    setModalError("")
    setDeleteModalOpen(true)
    setLoading(false)
  }

  const eventTime = eventTimeFormatted(event)
  return (
    <li
      className="list-group-item text-center"
      style={{ color: "var(--gray-300)" }}
    >
      <h5 style={{ fontWeight: "bold", color: event.color }}>{event.title}</h5>

      <p>
        {eventTime}
        <br />
        {event.description === "None" ? "" : event.description}
      </p>

      <div>
        {props.editButton && (
          <>
            <Link to={`/event/${event.id}/edit`}>
              <span style={{ color: "var(--gray-300)", margin: "0 0.2rem" }}>
                <FontAwesomeIcon icon={faEdit} />
              </span>
            </Link>
            <Link to={`/event/${event.id}/notify`}>
              <span style={{ color: "var(--gray-300)", margin: "0 0.4rem" }}>
                <FontAwesomeIcon icon={faBell} />
              </span>
            </Link>
          </>
        )}

        <span
          onClick={handleDeleteModalOpen}
          style={{
            color: "var(--gray-300)",
            margin: "0 0.4rem",
            cursor: "pointer",
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </span>
        <Link to={`/event/${event.id}`}>
          <span style={{ color: "var(--gray-300)", margin: "0 0.4rem" }}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </Link>
      </div>

      {/* Delete Modal */}
      <Modal
        styles={modalStylesDanger}
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Delete event</h5>
        <hr />
        <p className="modal-subtitle">
          Are you sure you want to delete this event: <b>{event.title}</b>?
        </p>
        {modalError.length > 0 && (
          <p className="h6 text-center text-danger">{modalError}</p>
        )}
        <div className="modal-button-container">
          <button
            className="default-button"
            onClick={() => setDeleteModalOpen(false)}
          >
            Cancel
          </button>
          <button
            className="default-button default-button-red"
            onClick={() => handleDelete()}
            disabled={loading}
          >
            Delete
          </button>
        </div>
      </Modal>
    </li>
  )
}

export default EventCard

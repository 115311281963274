import React from "react"
import { createRoot } from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/global.css"
import "./styles/layout.css"

import axios from "axios"

// Redux
import { store } from "./redux/store"
import { Provider } from "react-redux"

// Set base url for http requests
axios.defaults.baseURL = process.env.REACT_APP_API_URL
// axios.defaults.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       window.location = "/login"
//     }
//   }
// )

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)

reportWebVitals()

import React, { useState } from "react"
import Select, { components } from "react-select"

const getTabStyle = (isActive) => ({
  padding: "3px 5px",
  cursor: "pointer",
  background: isActive && "lightblue",
})

const TabbedGroupedSelect = ({
  groupedOptions,
  onChange,
  currentValue,
  onGroupSelect,
}) => {
  const [selectedGroup, setSelectedGroup] = useState(groupedOptions[0].label)

  const selectGroup = (e, option, groupIndex) => {
    onGroupSelect(option)
    setSelectedGroup(groupIndex)
  }

  const Menu = ({ children, ...props }) => (
    <components.Menu {...props}>
      <div
        style={{
          display: "flex",
          padding: "0px 10px",
          borderBottom: "1px solid black",
        }}
      >
        {props.selectProps.options.map((option, index) => (
          <div
            key={index}
            onClick={(e) =>
              selectGroup(e, option, props.selectProps.getOptionLabel(option))
            }
            style={getTabStyle(
              selectedGroup === props.selectProps.getOptionLabel(option)
            )}
          >
            {props.selectProps.getOptionLabel(option)}
          </div>
        ))}
      </div>
      {children}
    </components.Menu>
  )

  const MenuList = ({ children, ...props }) => {
    const filteredChildren = React.Children.toArray(children).filter(
      (group) => {
        if (
          group.type.name === "NoOptionsMessage" ||
          (group.props.data && group.props.data.label === selectedGroup)
        ) {
          return true
        }
        return false
      }
    )

    return (
      <components.MenuList {...props}>{filteredChildren}</components.MenuList>
    )
  }

  return (
    <Select
      className="basic-single"
      classNamePrefix="select"
      onChange={onChange}
      value={currentValue}
      name="color"
      options={groupedOptions}
      components={{
        Menu,
        MenuList,
      }}
    />
  )
}

export default TabbedGroupedSelect

import React, { useEffect, useState } from "react"

import { Modal } from "react-responsive-modal"
import { modalStyles } from "../styles/constanStyles"

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faBell,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons"

import axios from "axios"
import { useSelector } from "react-redux"

const arrayEquals = (a, b) => {
  let aCopy = a.slice().sort()
  let bCopy = b.slice().sort()

  return (
    Array.isArray(aCopy) &&
    Array.isArray(bCopy) &&
    a.length === b.length &&
    aCopy.every((val, index) => val === bCopy[index])
  )
}

const NotifyTraineesCard = ({ notificationsList, getNotificationsLog }) => {
  const [loading, setLoading] = useState(false)

  const user = useSelector((state) => state.user)

  // Modal States
  const [verificationOpen, setVerificationOpen] = useState(false)
  const [actionOpen, setActionOpen] = useState(false)
  const [customOpen, setCustomOpen] = useState(false)

  const [modalError, setModalError] = useState("")

  // Checkbox states
  const [verificationList, setVerificationList] = useState([])
  const [actionList, setActionList] = useState([])
  const [customList, setCustomList] = useState([])

  // All possible trainees for each checkbox states
  const [originalVerificationList, setOriginalVerificationList] = useState([])
  const [originalActionList, setOriginalActionList] = useState([])
  const [originalCustomList, setOriginalCustomList] = useState([])

  const [customMessage, setCustomMessage] = useState("")

  useEffect(() => {
    if (notificationsList.length && notificationsList.length > 0) {
      setActionList([])
      setCustomList([])
      setVerificationList([])
      setOriginalVerificationList([])
      setOriginalActionList([])
      setOriginalCustomList([])
      notificationsList.map((item) => {
        // Check for trainees with numbers, but not verified
        if (
          item.phone_number &&
          item.phone_number.length > 0 &&
          !item.is_verified
        ) {
          if (!verificationList.includes(item)) {
            setVerificationList((list) => [...list, item])
            setOriginalVerificationList((list) => [...list, item])
          }
        }
        // Check for trainees with numbers, but not 100% complete tasks
        if (
          item.phone_number &&
          item.phone_number.length > 0 &&
          item.compliance_perc < 100 &&
          item.is_verified
        ) {
          if (!actionList.includes(item)) {
            setActionList((list) => [...list, item])
            setOriginalActionList((list) => [...list, item])
          }
        }
        // Check for all trainees with numbers
        if (
          item.phone_number &&
          item.phone_number.length > 0 &&
          item.is_verified
        ) {
          if (!customList.includes(item)) {
            setCustomList((list) => [...list, item])
            setOriginalCustomList((list) => [...list, item])
          }
        }
      })
    }
  }, [notificationsList])

  const sendVerifcation = (e) => {
    e.preventDefault()
    setLoading(true)
    const body = {
      trainees: [...new Set(verificationList.map((t) => t.id))],
      notify_type: "verification",
    }
    axios
      .post("/notify-trainees/", body)
      .then((res) => {
        setVerificationOpen(false)
        setLoading(false)
        console.log(res.data)
        getNotificationsLog()
      })
      .catch((err) => {
        setVerificationOpen(false)
        console.log(err)
        setLoading(false)
      })
  }

  const sendActionReminder = (e) => {
    e.preventDefault()
    setLoading(true)
    const body = {
      trainees: [...new Set(actionList.map((t) => t.id))],
      notify_type: "action",
    }
    axios
      .post("/notify-trainees/", body)
      .then((res) => {
        setActionOpen(false)
        setLoading(false)
        getNotificationsLog()
      })
      .catch((err) => {
        setActionOpen(false)
        console.log(err)
        setLoading(false)
      })
  }

  const sendCustomMessage = (e) => {
    e.preventDefault()

    if (customMessage.length === 0) {
      setModalError("Please enter a message")
      return
    }
    if (customMessage.length > 450) {
      setModalError(
        "Please enter a message containing less than 450 characters"
      )
      return
    }

    setModalError("")
    setLoading(true)
    const body = {
      trainees: [...new Set(customList.map((t) => t.id))],
      notify_type: "custom",
      message: customMessage,
    }
    axios
      .post("/notify-trainees/", body)
      .then((res) => {
        handleCustomClose()
        setLoading(false)
        getNotificationsLog()
      })
      .catch((err) => {
        handleCustomClose()
        console.log(err)
        setLoading(false)
      })
  }

  const handleCustomClose = () => {
    setCustomOpen(false)
    setCustomMessage("")
  }

  const handleCheckOrUncheckAllClick = (isCheck, listType) => {
    switch (listType) {
      case "verification":
        if (isCheck) {
          setVerificationList(originalVerificationList)
        } else {
          setVerificationList([])
        }
        break
      case "action":
        if (isCheck) {
          setActionList(originalActionList)
        } else {
          setActionList([])
        }
        break
      case "custom":
        if (isCheck) {
          setCustomList(originalCustomList)
        } else {
          setCustomList([])
        }
        break
    }
  }
  return (
    <div className="col-sm-6">
      <div className="card" style={{ padding: "1rem", height: "100%" }}>
        <h5>Notify Trainees via SMS</h5>
        <div
          className="d-flex flex-row align-items-center justify-content-between"
          style={{ fontSize: "1.1rem" }}
        >
          <button
            className={"default-button"}
            onClick={() => setVerificationOpen(true)}
          >
            <FontAwesomeIcon icon={faCheckCircle} /> Send Verification Text
          </button>
          <button
            className={"default-button"}
            onClick={() => setActionOpen(true)}
          >
            <FontAwesomeIcon icon={faBell} /> Send Action Reminder
          </button>
          <button
            className={"default-button"}
            onClick={() => {
              setModalError("")
              setCustomOpen(true)
            }}
          >
            <FontAwesomeIcon icon={faCommentDots} /> Send Custom Message
          </button>
        </div>
        <p style={{ fontSize: "1.1rem" }}>
          This will send a SMS text message to selected trainees in the current
          org. They will receive an automated message from +1 (567) 459-5369.
          For a detailed view of a specific trainee, navigate to said trainee's
          card view on the Dashboard. We recommend you send a verification text
          first in order to confirm that they can receive texts from us.
        </p>
      </div>
      {/* Modals */}
      {/* Verification Modal */}
      <Modal
        styles={modalStyles}
        open={verificationOpen}
        onClose={() => setVerificationOpen(false)}
        center
      >
        <h5 className="modal-title">Verification Text</h5>
        <hr />
        {!notificationsList ||
          (notificationsList.length === 0 && (
            <p className="modal-subtitle">
              There are currently no trainees in this shop
            </p>
          ))}
        {originalVerificationList &&
          (originalVerificationList.length == 0 ? (
            <p>All trainees with phone numbers are verified!</p>
          ) : (
            <>
              <p className="modal-subtitle">
                This will send a text message to unverified trainees in the
                current org. They will be asked to respond to the text in order
                to verify their phone number. The following trainees are
                unverified:
              </p>
              {arrayEquals(verificationList, originalVerificationList) ? (
                <button
                  className="default-button"
                  onClick={() =>
                    handleCheckOrUncheckAllClick(false, "verification")
                  }
                >
                  Uncheck All
                </button>
              ) : (
                <button
                  className="default-button"
                  onClick={() =>
                    handleCheckOrUncheckAllClick(true, "verification")
                  }
                >
                  Check All
                </button>
              )}
              {originalVerificationList.map((trainee, ti) => {
                return (
                  <div key={ti}>
                    <label style={{ cursor: "pointer" }} key={ti}>
                      <input
                        type="checkbox"
                        checked={verificationList.includes(trainee)}
                        style={{ cursor: "pointer" }}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setVerificationList((list) => [...list, trainee])
                          } else {
                            setVerificationList((list) =>
                              list.filter((item) => item !== trainee)
                            )
                          }
                        }}
                      />
                      <b>{trainee.name}</b>
                    </label>
                  </div>
                )
              })}
            </>
          ))}
        {verificationList && verificationList.length > 0 && (
          <div className="modal-button-container">
            <button
              className="default-button default-button-red"
              onClick={() => setVerificationOpen(false)}
            >
              Cancel
            </button>
            <button
              className="default-button"
              disabled={loading}
              onClick={sendVerifcation}
            >
              Send
            </button>
          </div>
        )}
      </Modal>

      {/* Action Modal */}
      <Modal
        styles={modalStyles}
        open={actionOpen}
        onClose={() => setActionOpen(false)}
        center
      >
        <h5 className="modal-title">Action Reminder Text</h5>
        <hr />
        {!notificationsList ||
          (notificationsList.length === 0 && (
            <p className="modal-subtitle">
              There are currently no trainees in this org
            </p>
          ))}
        {originalActionList &&
          (originalActionList.length === 0 ? (
            <p>All verified trainees with phone numbers are compliant!</p>
          ) : (
            <>
              <p className="modal-subtitle">
                WARNING: This will notify selected trainees in the current org
                of their incomplete tasks. They may then reply and automatically
                update the system database. Recorded in the Finished Task Log.
                Select which trainees to notify:
              </p>
              {arrayEquals(actionList, originalActionList) ? (
                <button
                  className="default-button"
                  onClick={() => handleCheckOrUncheckAllClick(false, "action")}
                >
                  Uncheck All
                </button>
              ) : (
                <button
                  className="default-button"
                  onClick={() => handleCheckOrUncheckAllClick(true, "action")}
                >
                  Check All
                </button>
              )}
              {originalActionList.map((trainee, ti) => {
                return (
                  <div key={ti}>
                    <label style={{ cursor: "pointer" }} key={trainee.id}>
                      <input
                        type="checkbox"
                        checked={actionList.includes(trainee)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setActionList((list) => [...list, trainee])
                          } else {
                            setActionList((list) =>
                              list.filter((item) => item !== trainee)
                            )
                          }
                        }}
                      />
                      <b>
                        {trainee.name}
                        {trainee.percentComplete === 100 && (
                          <>
                            {" "}
                            -{" "}
                            <span
                              style={{
                                color: "var(--green)",
                              }}
                            >
                              {trainee.percentComplete}%
                            </span>
                          </>
                        )}
                      </b>
                    </label>
                  </div>
                )
              })}
            </>
          ))}
        {actionList && actionList.length > 0 && (
          <div className="modal-button-container">
            <button
              className="default-button default-button-red"
              onClick={() => setActionOpen(false)}
            >
              Cancel
            </button>
            <button
              className="default-button"
              disabled={loading}
              onClick={sendActionReminder}
            >
              Send
            </button>
          </div>
        )}
      </Modal>

      {/* Custom Message Modal */}
      <Modal
        styles={modalStyles}
        open={customOpen}
        onClose={() => handleCustomClose()}
        center
      >
        <h5 className="modal-title">Custom Message</h5>
        <hr />
        {!notificationsList ||
          (notificationsList.length === 0 && (
            <p className="modal-subtitle">
              There are currently no trainees in this org
            </p>
          ))}
        {originalCustomList &&
          (originalCustomList.length == 0 ? (
            <p>There are no verified trainees with phone numbers!</p>
          ) : (
            <>
              <textarea
                rows={4}
                style={{ width: "100%", padding: "0.5rem" }}
                value={customMessage}
                onChange={(e) => setCustomMessage(e.target.value)}
                maxLength={450}
              />
              <p
                style={{
                  float: "right",
                  marginTop: "-5px",
                  fontSize: "0.70rem",
                  color: "gray",
                  marginBottom: 0,
                }}
              >
                {customMessage.length}/450
              </p>
              <p className="modal-subtitle" style={{ marginTop: "10px" }}>
                WARNING: This will notify selected trainees in the current org
                with your custom message. Select which trainees to notify with
                your message:
              </p>
              {arrayEquals(customList, originalCustomList) ? (
                <button
                  className="default-button"
                  onClick={() => handleCheckOrUncheckAllClick(false, "custom")}
                >
                  Uncheck All
                </button>
              ) : (
                <button
                  className="default-button"
                  onClick={() => handleCheckOrUncheckAllClick(true, "custom")}
                >
                  Check All
                </button>
              )}
              {originalCustomList.map((trainee, ti) => {
                return (
                  <div key={ti}>
                    <label style={{ cursor: "pointer" }} key={trainee.id}>
                      <input
                        type="checkbox"
                        checked={customList.includes(trainee)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCustomList((list) => [...list, trainee])
                          } else {
                            setCustomList((list) =>
                              list.filter((item) => item !== trainee)
                            )
                          }
                        }}
                      />
                      <b>{trainee.name}</b>
                    </label>
                  </div>
                )
              })}
            </>
          ))}
        {modalError && (
          <div className="h6 text-center text-danger">{modalError}</div>
        )}

        {customList &&
          customList.length > 0 &&
          customMessage &&
          customMessage.length > 0 && (
            <div className="modal-button-container">
              <button
                className="default-button default-button-red"
                onClick={() => handleCustomClose()}
              >
                Cancel
              </button>
              <button
                className="default-button"
                disabled={loading}
                onClick={sendCustomMessage}
              >
                Send
              </button>
            </div>
          )}
      </Modal>
    </div>
  )
}

export default NotifyTraineesCard

import TaskTypeViewEdit from "./taskTypeViewer"
import { NavLink } from "react-router-dom"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useSelector } from "react-redux"
import axios from "axios"
import { format } from "date-fns"
import Select from "react-select"

const Create = () => {
  const user = useSelector((state) => state.user)
  const navigate = useNavigate()

  const [assignTask, setAssignTask] = useState(null)

  const [assignOrgOrInd, setAssignOrgOrInd] = useState(true)

  const [traineesAdd, setTraineesAdd] = useState([])
  const [dueDate, setDueDate] = useState()

  const [trainees, setTrainees] = useState([])

  const [q, setQ] = useState("")
  const searchParam = [
    "id",
    "name",
    "id_number",
    "org",
    "phone_number",
    "is_verified",
  ]
  const [taskTypes, setTaskTypes] = useState([])
  const getTasks = useCallback(() => {
    axios
      .get(`/get-task-types/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setTaskTypes(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [user.currentFocusedTeam.id])

  useEffect(() => {
    getTasks()
  }, [getTasks])
  function search(items) {
    return items.filter((item) => {
      return searchParam.some((searchP) => {
        if (item[searchP]) {
          return item[searchP]
            .toString()
            .toLowerCase()
            .includes(q.toLowerCase())
        }
        return false
      })
    })
  }

  useEffect(() => {
    axios
      .get(`all_trainees/?org_id=${user.currentFocusedTeam.id}`)
      .then((res) => {
        setTrainees(res.data)
        if (assignOrgOrInd) {
          setTraineesAdd(res.data)
        }
      })

      .catch((err) => {
        console.log(err)
      })
  }, [user.currentFocusedTeam])

  const handleCheck = (id) => {
    if (traineesAdd.includes(id)) {
      setTraineesAdd((prev) => prev.filter((p) => p !== id))
    } else {
      setTraineesAdd((prev) => [...prev, id])
    }
  }

  const handleOrgClick = () => {
    if (!assignOrgOrInd) {
      setTraineesAdd(trainees)
    } else {
      setTraineesAdd([])
    }

    setAssignOrgOrInd(!assignOrgOrInd)
  }

  const submitTasks = () => {
    const body = traineesAdd.map((t) => {
      return {
        individual_id: t.id,
        task_type_id: assignTask.value,
        due_date: dueDate,
      }
    })

    axios
      .post(`create-tasks/?org_id=${user.currentFocusedTeam.id}`, body)
      .then((res) => {
        navigate("/home")
      })

      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div>
      <h2>Mission Requirements for: {user.currentFocusedTeam.name}</h2>
      <div className="d-flex flex-row flex-wrap gap-5">
        <div
          className="card shadow p-3 col-8"
          style={{ borderLeft: `4px solid black` }}
        >
          <h3>Organization Task Types:</h3>
          <TaskTypeViewEdit taskTypes={taskTypes} refresh={getTasks} />
        </div>

        <div
          className="card shadow p-3 col-3"
          style={{ borderLeft: `4px solid black` }}
        >
          <h3>Assign to Personnel:</h3>
          <div>
            <button
              className="default-button"
              onClick={() => navigate("/upload")}
            >
              Bulk Upload
            </button>
          </div>

          <h5>Assign manually:</h5>

          <div className="d-flex gap-3 flex-column">
            <Select
              options={taskTypes.reduce(
                (prev, current) => [...prev, ...current.options],
                []
              )}
              value={assignTask}
              onChange={setAssignTask}
            />

            <div className="d-flex flex-row align-items-center gap-2">
              <div>Due Date:</div>
              <input
                type="date"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: ".25rem",
                  padding: ".2rem",
                }}
                value={dueDate}
                onChange={(e) => {
                  setDueDate(e.target.value)
                }}
                // min={currentDate}
                // required={!allDay}
              />
            </div>

            <div className="d-flex flex-row align-items-center gap-2">
              <div>Assign to Everyone in Org</div>
              <input
                type="checkbox"
                checked={assignOrgOrInd}
                style={{ width: "1.2rem", height: "1.2rem" }}
                onChange={handleOrgClick}
              ></input>
            </div>


            {!assignOrgOrInd && (
              <div>
                <div className="search-wrapper">
                  <label htmlFor="search-form">
                    <input
                      style={{ marginBottom: "16px" }}
                      type="search"
                      name="search-form"
                      id="search-form"
                      className="search-input textInput form-control"
                      placeholder="Search for..."
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <span className="sr-only">Search names here</span>
                  </label>
                </div>
                {trainees.length > 0 && (
                  <div style={{ height: "40rem", overflow: "auto" }}>
                    <table className="table table-hover table-responsive text-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Select</th>
                          <th scope="col">EMP #</th>
                          <th scope="col">Full Name</th>
                          <th scope="col">email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {search(trainees).map((trainee, ti) => (
                          <tr key={ti}>
                            <td>
                              <input
                                type="checkbox"
                                checked={traineesAdd.includes(trainee.id)}
                                onChange={() => handleCheck(trainee.id)}
                              />
                            </td>
                            <td>{trainee.id_number}</td>
                            <td>{trainee.name}</td>
                            <td>{trainee.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            )}
            <div>
              <button className="default-button" onClick={submitTasks}>
                Submit
              </button>
            </div>
          </div>

          {/*<NavLink className="default-button" to="/upload"></NavLink>*/}
        </div>
      </div>
    </div>
  )
}
export default Create

import React from "react"

const ProgressBar = (props) => {
  return (
    <div className="row g-0 align-items-center gap-2">
      <div className="col-auto">
        <div
          className="h5 mb-0 mr-3 font-weight-bold text-gray-800"
          style={{ color: "black", fontWeight: 700 }}
        >
          {props.percent.toFixed()}%
        </div>
      </div>
      <div className="col">
        <div className="progress progress-sm mr-2" style={{ height: "0.5rem" }}>
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width: `${props.percent}%`,
              backgroundColor: `${props.color}`,
              transition: "width 0.5s",
            }}
          ></div>
        </div>
      </div>
    </div>
  )
}

export default ProgressBar

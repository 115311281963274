import React from "react"

import DefaultLayout from "../layouts/defaultLayout"

import { ScaleLoader } from "react-spinners"

const LoadingDashboard = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        marginTop: "2rem",
      }}
    >
      <ScaleLoader color="black" loading={true} size={75} />
    </div>
  )
}

export default LoadingDashboard

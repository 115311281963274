import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import DefaultLayout from "../../layouts/defaultLayout"
import LoadingDashboard from "../../components/loadingDashboard"
import SearchTraineeListItem from "../../components/searchTraineeListItem"
import { format } from "date-fns"
import axios from "axios"

import { useSelector } from "react-redux"
import { NONE } from "react-big-calendar/lib/utils/Resources"

const EditEvent = () => {
  const { eventID } = useParams()
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)

  const [loadingPage, setLoadingPage] = useState(false)
  const [loadingForm, setLoadingForm] = useState(false)
  const [loadingTrainees, setLoadingTrainees] = useState(true)
  const [error, setError] = useState("")

  const [currentDate, setCurrentDate] = useState("")

  const [event, setEvent] = useState({})

  const [originalTrainees, setOriginalTrainees] = useState([])
  const [originalTraineeIds, setOriginalTraineeIds] = useState([])

  // Input states
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [location, setLocation] = useState("")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const [allDay, setAllDay] = useState(false)
  const [capacity, setCapacity] = useState(0)
  const [color, setColor] = useState("#000000")

  const [addedTrainees, setAddedTrainees] = useState([])
  const [removedTrainees, setRemovedTrainees] = useState([])
  const [addedTraineesIds, setAddedTraineeIds] = useState([])
  const [availableTrainees, setAvailableTrainees] = useState([])
  const [conflictTrainees, setConflictTrainees] = useState([])

  const handleDateChange = (event, setter) => {
    // Get the input value
    const inputValue = event

    // Create a new Date object from the input value
    // Note: inputValue is in the format "YYYY-MM-DDTHH:MM"
    const date = new Date(inputValue)

    // Optionally: Set to the local timezone
    // If the input is invalid, we can set the state to the current date
    if (!isNaN(date)) {
      setter(date)
    } else {
      setter(new Date()) // Reset to current date if invalid
    }
  }

  useEffect(() => {
    setLoadingPage(true)
    if (eventID && eventID.length > 0) {
      axios
        .get(`/get-event/?event_id=${eventID}`)
        .then((res) => {
          setEvent(res.data)
          setTitle(res.data.title)
          setDescription(res.data.description)
          setStartDate(new Date(res.data.start_datetime))
          setEndDate(new Date(res.data.end_datetime))
          setCapacity(res.data.capacity)
          setColor(res.data.color)
          setAllDay(res.data.all_day)
          setLocation(res.data.location)
          setOriginalTrainees(res.data.trainees)
          setLoadingPage(false)
        })
        .catch((err) => {
          setError("Error has occured")
          if (err.response.status === 404) {
            setError("An event with this id does not exist")
          } else if (err.response.status === 403) {
            setError("You are not authorized to view this event")
          } else {
            setError(
              "An unexpected error has occured, please return to the previous page and try again"
            )
          }
          setLoadingPage(false)
        })
    }
  }, [eventID])

  // Query for trainees
  useEffect(() => {
    if (startDate && endDate) {
      setAvailableTrainees([])
      setConflictTrainees([])
      axios
        .get(
          `/search-trainees-conflict/?org_id=${user.currentFocusedTeam.id}&start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`
        )
        .then((res) => {
          const ogIds = originalTrainees.map((t) => t.id)
          setAvailableTrainees(
            res.data.available_trainees.filter((t) => !ogIds.includes(t.id))
          )
          setConflictTrainees(
            res.data.conflict_trainees.filter((t) => !ogIds.includes(t.id))
          )

          setLoadingTrainees(false)
        })
        .catch((err) => {
          setLoadingTrainees(false)
          console.log(err)
          setError(
            "An unexpected error has occured, please return to the previous page and try again"
          )
        })
    }
  }, [
    startDate,
    endDate,
    addedTraineesIds,
    user.currentFocusedTeam.id,
    originalTrainees,
  ])

  // Get current date

  useEffect(() => {
    const d = new Date()
    let month = "" + (d.getMonth() + 1)
    let day = "" + d.getDate()
    let year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    setCurrentDate([year, month, day].join("-"))
  })

  const handleSubmit = (e) => {
    e.preventDefault()

    setLoadingForm(true)
    let traineesList = []
    // Get trainee ids
    if (addedTrainees && addedTrainees.length > 0) {
      addedTrainees.map((item) => {
        traineesList.push(item.id)
      })
    }

    const body = {
      title: title,
      id: eventID,
      description,
      start_datetime: startDate,
      end_datetime: endDate,
      all_day: allDay,
      owner_id: event.owner_id,
      org_id: event.org_id,
      capacity: capacity && capacity !== 0 ? capacity : 0,
      color,
      location,
      remove_trainee_ids: removedTrainees.map((i) => {
        return i.id
      }),
      add_trainee_ids: addedTrainees.map((i) => {
        return i.id
      }),
    }

    axios
      .post("/edit-event/", body)
      .then(() => {
        navigate("/events")
      })
      .catch((err) => {
        console.log(err.response)
        setError("An error has occured please try again")
        setLoadingForm(false)
      })
  }

  if (loadingPage || loadingTrainees) {
    return <LoadingDashboard />
  }

  return (
    <div>
      <h1>Edit Event</h1>
      <div className="row">
        <div className="col-sm-6">
          <form style={{ maxWidth: "600px" }} onSubmit={handleSubmit}>
            <p>Title*</p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="textinput textInput form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>

            <p>Description*</p>
            <div className="input-group mb-3">
              <textarea
                rows={10}
                type="text"
                className="form-control form-control-user textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>

            <p>Location (Optional)</p>
            <div className="input-group mb-3">
              <input
                type="text"
                className="textinput textInput form-control"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            <div className="form-row row">
              <div className="form-group ">
                <p>Start Date</p>
                <input
                  type="datetime-local"
                  className="textinput textInput form-control"
                  value={format(startDate, "yyyy-MM-dd'T'HH:mm")}
                  onChange={(e) => {
                    handleDateChange(e.target.value, setStartDate)
                  }}
                  min={currentDate}
                  required
                />
              </div>
            </div>
            <br />

            <div className="form-row row">
              <div className="form-group ">
                <p>End Date</p>
                <input
                  type="datetime-local"
                  className="textinput textInput form-control"
                  value={format(endDate, "yyyy-MM-dd'T'HH:mm")}
                  onChange={(e) => {
                    handleDateChange(e.target.value, setEndDate)
                  }}
                  min={currentDate}
                  required={!allDay}
                />
              </div>
            </div>
            <br />

            <div className="form-row row">
              <div className="forg-group">
                <label>
                  <input
                    type="checkbox"
                    value={allDay}
                    onChange={(e) => {
                      setAllDay(e.target.checked)
                    }}
                  />{" "}
                  All Day
                </label>
              </div>
            </div>
            <br />

            <div className="form-row row">
              <div className="form-group col-md-6">
                <p>Capacity</p>
                <input
                  type="number"
                  className="textinput textInput form-control"
                  value={capacity}
                  onChange={(e) => {
                    setCapacity(e.target.value)
                  }}
                  min={0}
                />
              </div>

              <div className="form-group col-md-6">
                <p>Color</p>
                <input
                  type="color"
                  className="textinput textInput form-control"
                  value={color}
                  onChange={(e) => {
                    setColor(e.target.value)
                  }}
                  required
                  list={[]}
                />
              </div>
            </div>
            <br />
            <Link className="default-button default-button-red" to="/events">
              Cancel
            </Link>
            <button
              className="default-button"
              disabled={loadingForm || loadingPage}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
        <div className="col-sm-6">
          <div className="container-fluid">
            <h5>Search Result</h5>
            <ul className="list-group available-list">
              <li className="list-group-item list-group-item-info">
                <strong> Trainees already added </strong>
              </li>
              {originalTrainees &&
                originalTrainees.length > 0 &&
                originalTrainees.map((trainee) => {
                  return (
                    <SearchTraineeListItem
                      trainee={trainee}
                      setTrainees={setRemovedTrainees}
                      changeTrainees={removedTrainees}
                      key={trainee.id}
                      startAdded={true}
                    />
                  )
                })}
            </ul>
            <br />
            <ul className="list-group conflict-list">
              <li className="list-group-item list-group-item-danger">
                <strong> Trainees with time conflict </strong>
                {conflictTrainees && conflictTrainees.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-sm float-right add-all"
                    onClick={() =>
                      setAddedTrainees([
                        ...originalTrainees,
                        ...conflictTrainees,
                      ])
                    }
                  >
                    Add All
                  </button>
                )}
              </li>
              {conflictTrainees &&
                conflictTrainees.length > 0 &&
                conflictTrainees.map((trainee) => {
                  return (
                    <SearchTraineeListItem
                      trainee={trainee}
                      setTrainees={setAddedTrainees}
                      changeTrainees={addedTrainees}
                      key={trainee.id}
                      startAdded={false}
                    />
                  )
                })}
            </ul>
            <br />
            <ul className="list-group available-list">
              <li className="list-group-item list-group-item-success">
                <strong> Trainees without time conflict </strong>
                {availableTrainees && availableTrainees.length > 0 && (
                  <button
                    className="btn btn-outline-primary btn-sm float-right add-all"
                    onClick={() =>
                      setAddedTrainees([
                        ...originalTrainees,
                        ...availableTrainees,
                      ])
                    }
                  >
                    Add All
                  </button>
                )}
              </li>
              {availableTrainees &&
                availableTrainees.length > 0 &&
                availableTrainees.map((trainee) => {
                  return (
                    <SearchTraineeListItem
                      trainee={trainee}
                      setTrainees={setAddedTrainees}
                      changeTrainees={addedTrainees}
                      key={trainee.id}
                      startAdded={false}
                    />
                  )
                })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditEvent

import React, { useState, useEffect } from "react"
import AuthLayout from "../layouts/authLayout"
import { Link, useSearchParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { register, clearErrors, setErrors } from "../redux/userSlice"

import "../styles/register.css"
import axios from "axios"
import StatusEnum from "../util/StatusEnum"
import { ScaleLoader } from "react-spinners"
import Modal from "react-responsive-modal"
import { modalStylesDanger } from "../styles/constanStyles"

const Register = () => {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatPassword, setRepeatPassword] = useState("")
  const [teamCode, setTeamCode] = useState("")

  const dispatch = useDispatch()
  const [localError, setLocalError] = useState("")

  const { errors, status } = useSelector((state) => state.user)

  const [searchParams, setSearchParams] = useSearchParams()

  const [linkValidity, setLinkValidity] = useState(StatusEnum.loading)
  const [errorModal, setErrorModal] = useState(false)

  // Check valid link.
  useEffect(() => {
    if (searchParams && searchParams.has("email") && searchParams.has("code")) {
      setEmail(searchParams.get("email"))
      setTeamCode(searchParams.get("code"))

      const body = {
        email: searchParams.get("email"),
        code: searchParams.get("code"),
      }
      axios
        .post("/check-invitation-code/", body)
        .then((res) => {
          setLinkValidity(StatusEnum.success)
        })
        .catch((err) => {
          console.log(err)
          setLinkValidity(StatusEnum.error)
        })
    } else {
      setLinkValidity(StatusEnum.error)
    }
  }, [searchParams])

  useEffect(() => {
    if (status === "failed") {
      setErrorModal(true)
    }
  }, [status])

  // Clear redux errors on page load
  useEffect(() => {
    dispatch(clearErrors())
  }, [dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(clearErrors())
    setLocalError("")

    if (password !== repeatPassword) {
      setLocalError("The given passwords do not match")
      return
    }

    const data = {
      email: email,
      password,
      isSupervisor: true,
      first_name: firstName,
      last_name: lastName,
      code: teamCode,
      isInstitutionAdmin: false,
    }
    dispatch(register(data))
  }

  return (
    <AuthLayout>
      <div className="auth-bg">
        {linkValidity === StatusEnum.loading && <ScaleLoader />}
        {linkValidity === StatusEnum.error && (
          <div className="auth-card">
            <p>
              This registration link is invalid or expired. Please check to make
              sure you have the right link.
            </p>
          </div>
        )}
        {linkValidity === StatusEnum.success && (
          <div className="auth-card">
            <h1 className="auth-title">Create an Account</h1>
            <form
              className="user"
              autoComplete="off"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="auth-form">
                <div
                  type="text"
                  className="auth-input prevent-select"
                  value={email}
                  onChange={(e) => {}}
                  readonly
                  style={{ backgroundColor: "#e2e2e2" }}
                >
                  {email}
                </div>

                <input
                  type="text"
                  className="auth-input"
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />

                <input
                  type="text"
                  className="auth-input"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />

                <input
                  type="password"
                  className="auth-input"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />

                <input
                  type="password"
                  className="auth-input"
                  name="repeatPassword"
                  id="repeatPassword"
                  placeholder="Repeat Password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  required
                />
              </div>
              <button className="auth-btn">Register</button>
            </form>
          </div>
        )}
      </div>
      <Modal
        styles={modalStylesDanger}
        open={errorModal}
        onClose={() => setErrorModal(false)}
        center
        focusTrapped={false}
      >
        <h5 className="modal-title">Error</h5>
        <hr />
        <p className="modal-subtitle">
          There was an error creating your account, please try again.
        </p>
        <div className="modal-button-container">
          <button
            className="default-button"
            onClick={() => setErrorModal(false)}
          >
            Ok
          </button>
        </div>
      </Modal>
    </AuthLayout>
  )
}

export default Register

import React from "react"

// Calendar
import { Calendar, momentLocalizer } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"

// Initialize localizer for calendar
const localizer = momentLocalizer(moment)

// Generic resuable components for both supervisor and trainee calendar
const CalendarComponent = ({ events, handleEventSelect }) => {
  console.log(events)
  return (
    <div>
      <div>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start_datetime"
          endAccessor="end_datetime"
          resourceAccessor="id"
          style={{ height: 500 }}
          onSelectEvent={(e) => handleEventSelect(e)}
          eventPropGetter={(event) => ({
            style: {
              backgroundColor: event.color,
              color: event.color === "#000000" ? "white" : "black",
            },
          })}
        />
      </div>
    </div>
  )
}

export default CalendarComponent
